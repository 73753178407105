import React, { ReactNode } from "react";
import { Layout, Menu, Icon } from "antd";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import { LenguageSelector } from "../containers";
import { routes } from "../App";
import Sider from "antd/lib/layout/Sider";
import { isMobile } from 'react-device-detect';

const { Header, Content } = Layout;

interface IProps {
    onSignOutClick: () => void;
    children: ReactNode;
    route: string;
    isAdmin: boolean;
    isBackOffice: boolean;
    isCallCenterAdmin: boolean;
    name: string;
}

const clearUrl = (url: string) => url.replace(/\/([0-9]+|:id\?)/g, "");

const selectedKey = (actualPath: string) => [
    routes
        .map(r => clearUrl(r.path))
        .indexOf(clearUrl(actualPath))
        .toString()
];

const menuItems = (isAdmin: boolean, isBackOffice: boolean,isCallCenterAdmin:boolean) => {
    if (isAdmin) {
        return routes
            .filter(
                r =>
                    r.shouldBeAdmin !== undefined &&
                    (r.shouldBeAdmin === isAdmin || r.shouldBeAdmin === false) && //TODO
                    !r.hide
                    && r.isMobile==false
            )
            .map((r, i) => (
                <Menu.Item key={i + 1}>
                    <Link to={clearUrl(r.path)}>
                        <Icon type={r.icon} />
                        <FormattedMessage id={r.id} defaultMessage={r.defaultMessage} />
                    </Link>
                </Menu.Item>
            ));
    }
    else {
        console.log(routes);
        if (isBackOffice || isCallCenterAdmin) {
            return routes.filter(r => r.id == "contracts" || r.id == "excel")
                .map((r, i) => (
                    <Menu.Item key={i + 1}>
                        <Link to={clearUrl(r.path)}>
                            <Icon type={r.icon} />
                            <FormattedMessage id={r.id} defaultMessage={r.defaultMessage} />
                        </Link>
                    </Menu.Item>
                ));
        }
        else
            if (isCallCenterAdmin) {
                return routes.filter(r => r.id == "contracts" || r.id == "incident" )
                    .map((r, i) => (
                        <Menu.Item key={i + 1}>
                            <Link to={clearUrl(r.path)}>
                                <Icon type={r.icon} />
                                <FormattedMessage id={r.id} defaultMessage={r.defaultMessage} />
                            </Link>
                        </Menu.Item>
                    ));
            }
            else {
                return routes.filter(r => r.id == "contracts" || r.id == "incident")
                    .map((r, i) => (
                        <Menu.Item key={i + 1}>
                            <Link to={clearUrl(r.path)}>
                                <Icon type={r.icon} />
                                <FormattedMessage id={r.id} defaultMessage={r.defaultMessage} />
                            </Link>
                        </Menu.Item>
                    ));
            }
            
    }
}
    

const Layout2 = (props: IProps) => {
    return (
        isMobile ? (<div className="mainMobile">
            <div>
                <button>
                    <Link to="contracts">
                        Contracts</Link></button>
                <button><Link to="stats">
                    Stats</Link></button>
                <button><Link to="incident">
                    Incidencias</Link></button>
                <button><Link to="excel">
                    Excel</Link></button>
            </div>
            <Content>{props.children}</Content>
            
        </div>) : (
        <Layout>
            <div className="mainHeader">
                <a href="/"><img style={{ width: "175px" }} src={require("../bg.png")} /></a>
            <div className="userMenu">
                <div className="lenguageSelector">
                    {props.name}
                    <div
                        onClick={props.onSignOutClick}
                        style={{ cursor: "pointer", marginLeft: "10px" }}
                    >
                        <Icon type="logout" />
                    </div>
                </div>
                <LenguageSelector />
            </div>
            </div>

            <div className="mainContainer">
                <div className="mainMenu">
                    <Menu
                        theme="dark"
                        mode="vertical"
                        selectedKeys={selectedKey(props.route)}
                        style={{ lineHeight: "64px" }}
                            >
                                {menuItems(props.isAdmin, props.isBackOffice, props.isCallCenterAdmin)}
                    </Menu>
                </div>
                <div className="mainContent">
                    <Content>{props.children}</Content>
                </div>
            </div>
        </Layout>
    ));
};

export default Layout2;
