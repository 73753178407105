import React from "react";
import { Input, Select, Switch } from "antd";
import FormItem from "antd/lib/form/FormItem";

import * as enums from "../models";

const { Option } = Select;

interface IProps {
  label: string;
  defaultValue: string | number | boolean;
  onBlur: (e: {
    target: { name: string; value: string | number | boolean };
  }) => void;
  onKeyDown: (e: {
    target: { name: string; value: string | number | boolean };
  }) => void;
    name: string;

  validateStatus?: "" | "error" | "success" | "warning" | "validating";
}

const inputType = (props: IProps) => {
  switch (typeof props.defaultValue) {
    case "number": {
      // @ts-ignore
      const findEnum = enums[props.name];
      return (
          <Select
          defaultValue={props.defaultValue}
          style={{ width: 120 }}
          onChange={(value: any) =>
            props.onBlur({
              target: { name: props.name, value },
            })
          }
        >
          {findEnum &&
            Object.keys(findEnum).map(
              (key) =>
                typeof findEnum[key] === "string" && (
                  <Option key={key} value={parseInt(key)}>
                    {findEnum[key]}
                  </Option>
                )
            )}
        </Select>
      );
    }
    case "boolean":
      return (
        <Switch
          defaultChecked={props.defaultValue}
          onChange={(value) =>
            props.onBlur({
              target: { name: props.name, value },
            })
          }
        />
      );
    case "string":
      return (
          <Input
              name={props.name + Math.random()}
              id={props.name}
              defaultValue={props.defaultValue}
              onKeyDown={(e) =>
                  props.onKeyDown({
                      target: { name: props.name, value: e.key },
                  })
              }
              onBlur={(e) =>
                  props.onBlur({
                      target: { name: props.name, value: e.target.value },
                  })
              }
              autoComplete="new-password"
              onFocus={(event) => {
                      event.target.setAttribute('autoComplete', 'new-password');
              }}
        />
      );
    default:
      break;
  }
};

const WizardItem = (props: IProps) => (
  <div>
    <FormItem label={props.label} validateStatus={props.validateStatus}>
      {inputType(props)}
    </FormItem>
  </div>
);

export default WizardItem;
