import React, { ReactNode } from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";

import * as AuthStore from "../store/Auth";
import { ApplicationState } from "../store";
import { Layout } from "../components";

type IProps = typeof AuthStore.actionCreators & RouteComponentProps & {
    children: ReactNode;
    isBackOffice: boolean;
    isCallCenterAdmin: boolean;
    isAdmin: boolean;
    name: string;
};
//TOO: remove this
const PageLayout = (props: IProps) => (
    <Layout
        route={props.location.pathname}
        isAdmin={props.isAdmin}
        isBackOffice={props.isBackOffice}
        isCallCenterAdmin={props.isCallCenterAdmin}
        name={props.name}
        onSignOutClick={props.signOut}
    >
        {props.children}
    </Layout>
);

export default connect((state: ApplicationState) => ({
    isAdmin: state.auth?.isAdmin,
    isCallCenterAdmin: state.auth?.isCallCenterAdmin,
    isBackOffice: state.auth?.isBackOffice,
    name: state.auth?.name
}), AuthStore.actionCreators)(withRouter(PageLayout as any));
