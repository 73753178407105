import { Reducer, Action } from "redux";

import { AppThunkAction } from "./";
import { RatesService, ExcelService, TemplateService } from "../services";
import { IRate, ITemplate } from "../models";
import { valida_cups} from "../helpers";

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface ExcelState {
    isLoading: boolean;
    cups: string;
    list: IRate[];
    templates: ITemplate[];
    opened: boolean;
    template?: ITemplate;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen

interface RequestExcelAction {
    type: "EXCEL_REQUEST_CUPS";
}
interface ShowPopup {
    type: "SHOW_POPUP";
    opened: boolean;
}
interface SetTemplateForPopup {
    type: "SET_TEMPLATE_POPUP";
    template: ITemplate;
}
interface RequestTemplatesAction {
    type: "EXCEL_REQUEST_TEMPLATES";
}
interface ExcelLoadedAction {
  type: "RATES_LOADED_FINISED";
  rates?: IRate[];
}
interface TemplatesLoadedAction {
    type: "TEMPLATES_LOADED_FINISED";
    templates: ITemplate[];
}
// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = RequestExcelAction | ExcelLoadedAction | RequestTemplatesAction | TemplatesLoadedAction | ShowPopup | SetTemplateForPopup;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    setTemplate: (template: ITemplate): AppThunkAction<KnownAction | Action> => (
        dispatch,
        getState
    ) => {
        dispatch({ type: "SET_TEMPLATE_POPUP", template });
    },
    showPopUp: (): AppThunkAction<KnownAction | Action> => (
        dispatch,
        getState
    ) => {
        var opened = !getState().excel?.opened;
        dispatch({ type: "SHOW_POPUP", opened });
    },
    deleteTemplate: (template: ITemplate): AppThunkAction<KnownAction | Action> => (
        dispatch,
        getState
    ) => {
        TemplateService.Delete(template)
            .then(() => {
            })
            .catch((e) => {
                dispatch({ type: "TEMPLATES_LOADED_FINISED" });
            });
    },
    requestTemplates: (): AppThunkAction<KnownAction | Action> => (
        dispatch,
        getState
    ) => {
        TemplateService.GetAll()
            .then((templates) => {
                dispatch({ type: "TEMPLATES_LOADED_FINISED", templates });
            })
            .catch((e) => {
                dispatch({ type: "TEMPLATES_LOADED_FINISED" });
            });

        dispatch({ type: "EXCEL_REQUEST_TEMPLATES" });
    },
    getInfoCups: (cups: string): AppThunkAction<KnownAction | Action> => (
        dispatch,
        getState
    ) => {
        if (valida_cups(cups)) {
            dispatch({ type: "EXCEL_REQUEST_CUPS" });
            ExcelService.DownloadEstimation("", cups).catch((p: any) => {
            })
        }
    }
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: ExcelState = { isLoading: false, list: [], cups: "", templates: [], opened:false };

export const reducer: Reducer<ExcelState> = (
  state: ExcelState | undefined,
  action: KnownAction
): ExcelState => {
  if (state === undefined) {
    return unloadedState;
  }

  switch (action.type) {
     case "EXCEL_REQUEST_CUPS":
      return {
        ...state,
        isLoading: true
      };
    case "RATES_LOADED_FINISED":
      return {
        ...state,
        isLoading: false,
        list: action.rates || []
          };
      case "TEMPLATES_LOADED_FINISED":
          return {
              ...state,
              isLoading: false,
              templates: action.templates || []
             
          };
      case "EXCEL_REQUEST_TEMPLATES":
          return {
              ...state,
              isLoading: true,
          };
      case "SHOW_POPUP":
          return {
              ...state,
              opened: action.opened
          };
      case "SET_TEMPLATE_POPUP": return {
          ...state,
          template: action.template
      };
      default:
      return state;
  }
};
