import React, { useEffect, PureComponent } from "react";
import { connect } from "react-redux";
import { Table, Button } from "antd";
import { FormattedMessage } from "react-intl";
import { RouteComponentProps } from "react-router";
import { UserModel, } from "../components";

import { ApplicationState } from "../store";
import { PageLayout } from "../containers";
import * as UserStore from "../store/Users";
import { IUser } from "../models";

type IProps = RouteComponentProps &
    UserStore.UsersState &
    typeof UserStore.actionCreators;

interface IState {
    showModal: boolean;
    user?: IUser;
}

class Users extends PureComponent<IProps, IState> {

    constructor(props: IProps) {
        super(props);

        this.state = {
            showModal: false
        };
    }
    public componentDidMount() {
        this.props.requestUsers();
    };
    handleOk = (user: IUser) => {
        this.props.createUser(user);
        if (user.password != user.password2) {

        } else {
            this.setState({
                showModal: false
            });
            this.props.requestUsers();
        }
    };
    changeModalState = (user?: IUser) => {
        this.setState({
            showModal: !this.state.showModal,
            user
        });
    };
    render() {
        const { list, isLoading, disableUser,enableUser } = this.props;
        const { user, showModal } = this.state;

        return (
            <PageLayout>
                <UserModel
                    visible={showModal}
                    onOk={this.handleOk}
                    onCancel={this.changeModalState}
                    user={user}
                />
                <Button
                    type="primary"
                    onClick={() => this.changeModalState()}
                    style={{ marginBottom: "10px" }}
                >
                    <FormattedMessage id="user.add" defaultMessage="Add User" />
                </Button>
                <Table<IUser>
                    rowKey={(record: IUser) => record.id}
                    dataSource={list}
                    loading={isLoading}
                    pagination={false}
                >
                    <Table.Column<IUser>
                        key="userName"
                        title={<FormattedMessage id="userName" defaultMessage="Username" />}
                        dataIndex="userName"
                    />
                    <Table.Column<IUser>
                        key="role"
                        title={<FormattedMessage id="role" defaultMessage="Rol" />}
                        dataIndex="role"
                    />
                    <Table.Column<IUser>
                        key="disabled"
                        title={<FormattedMessage id="state" defaultMessage="Estado" />}
                        dataIndex="disabled"
                    />
                    <Table.Column<IUser>
                        key="actions"
                        title={<FormattedMessage id="disavbleuser" defaultMessage="Actions" />}
                        render={(_, record) => (
                            <span>
                                <a onClick={() => disableUser(record)}>
                                    <FormattedMessage id="disable" defaultMessage="Deshabilitar" />
                                </a>
                            </span>
                        )}
                    />
                    <Table.Column<IUser>
                        key="actions2"
                        title={<FormattedMessage id="enableUser" defaultMessage="Actions" />}
                        render={(_, record) => (
                            <span>
                                <a onClick={() => enableUser(record)}>
                                    <FormattedMessage id="enable" defaultMessage="Habilitar" />
                                </a>
                            </span>
                        )}
                    />
                </Table>
            </PageLayout>);
    }
    //  return (
    //  <PageLayout>
    //    <Table<IUser>
    //      rowKey={(record: IUser) => record.id}
    //      dataSource={list}
    //      loading={isLoading}
    //      pagination={false}
    //    >
    //      <Table.Column<IUser>
    //        key="userName"
    //        title={<FormattedMessage id="userName" defaultMessage="Username" />}
    //        dataIndex="userName"
    //      />
    //      <Table.Column<IUser>
    //        key="actions"
    //        title={<FormattedMessage id="actions" defaultMessage="Actions" />}
    //        render={(_, record) => (
    //          <span>
    //            <a onClick={() => deleteUser(record)}>
    //              <FormattedMessage id="delete" defaultMessage="Delete" />
    //            </a>
    //          </span>
    //        )}
    //      />
    //    </Table>
    //  </PageLayout>
    //);
};

export default connect(
    (state: ApplicationState) => state.users,
    UserStore.actionCreators
)(Users as any);
