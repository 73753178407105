import { Reducer, Action } from "redux";

import { AppThunkAction } from "./";
import { WizardService, IncidentService } from "../services";
import { IContract, ICallCenter, ICallCenterUser, ICommonInterface, IGroupedContract, IIncident, IRate } from "../models";
import { notification } from "antd";
import { push } from "connected-react-router";
import { Contracts } from "../pages";
import { IconComponent } from "antd/lib/icon";
import { RatesService } from "../services";


// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface IDownloadContractModel {
    id: number;
    contractIdentifier: string;
    callCenterIdentifier: string;
    cups: string;
}
export interface ContractsState {
    isLoading: boolean;
    list: IContract[];
    grouped: IGroupedContract[];
    groupedClone: IGroupedContract[];
    groupedRateClone: IGroupedContract[];
    defaultGrouped: any[];
    defaultGroupedRate: any[];
    defaultGroupedAll: any[];
    multi: any[];
    multiRate: any[];
    multiAll: IGroupedContract[];
    selectedContracts: IDownloadContractModel[];
    showComparison: boolean;
    agent: string;
    callcenter: string;
    originalList: IContract[];
    callcenters: ICallCenter[];
    agents: ICallCenterUser[];
    rates: IRate[];
    aggregatedRate: any[];

}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface RequestRatesAction {
    type: "REQUEST_RATES";
}

interface RatesLoadedAction {
    type: "RATES_LOADED_FINISED";
    rates?: IRate[];
}
interface RequestHomeAction {
    type: "REQUEST_HOME";
    agents: ICallCenterUser[];
    callcenters: ICallCenter[];
}
interface RequestContractsAction {
    type: "REQUEST_CONTRACTS";
}
interface RequestGroupedContractsAction {
    type: "REQUEST_GROUPED_CONTRACTS";
}
interface MarkContractAsSelected {
    type: "MARK_CONTRACT_AS_SELECTED";
    selecteds: IDownloadContractModel[];
}
interface UnMarkContractAsSelected {
    type: "UNMARK_CONTRACT_AS_SELECTED";
    selecteds: number[];
}
interface ContractsLoadedAction {
    type: "CONTRACTS_LOADED";
    contracts?: IContract[];
}
interface GroupedLoadedAction {
    type: "GROUPED_LOADED";
    multiRate?: any[];
}
interface FilterGroupedAction {
    type: "FILTER_GROUPED";
    contracts?: IGroupedContract[];
    arr?: any[];
    multi?: any[];
    multiRate?: any[];
    aggregatedRate?: any[];
}
interface FilterContractsAction {
    type: "FILTER_CONTRACTS";
    contracts: IContract[];
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction =
    | RequestContractsAction
    | RequestGroupedContractsAction
    | RequestHomeAction
    | ContractsLoadedAction
    | GroupedLoadedAction
    | FilterContractsAction
    | MarkContractAsSelected
    | UnMarkContractAsSelected
    | FilterGroupedAction
    | RequestRatesAction
    | RatesLoadedAction
    ;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    requestRates: (): AppThunkAction<KnownAction | Action> => (
        dispatch,
        getState
    ) => {
        RatesService.GetAll()
            .then(rates => {
                dispatch({ type: "RATES_LOADED_FINISED", rates });
            })
            .catch(e => {
                dispatch({ type: "RATES_LOADED_FINISED" });
            });

        dispatch({ type: "REQUEST_RATES" });
    },
    requestHome: (): AppThunkAction<KnownAction | Action> => (
        dispatch,
        getState
    ) => {
        WizardService.GetHome().then((response) => {
            dispatch({ type: "REQUEST_HOME", agents: response.users, callcenters: response.centers });
        })
    },
    downloadContract: (id: any, contractIdentifier: any, other: string): AppThunkAction<KnownAction | Action> => (dispatch, getState) => {
        WizardService.GetPdf(id, contractIdentifier, other).then(() => { });
    },
    markContractAsIncident: (id: any, contractIdentifier: any, other: string, agent: string): AppThunkAction<KnownAction | Action> => (dispatch, getState) => {
        if (contractIdentifier == null)
            IncidentService.Add(id, other, agent).then
                ((a: IIncident) => setTimeout(() => dispatch(push(`/incident-detail/${a.id}`)), 100));
        else
            dispatch(push(`/incident-detail/${contractIdentifier}`))
    },
    deleteContract: (id: any): AppThunkAction<KnownAction | Action> => (dispatch, getState) => {
        console.log("Mi id:" + id.toString());
        WizardService.Delete(id)
            .then(() => {
                //dispatch({ type: "CONTRACTS_LOADED", contracts });

                setTimeout(() => {
                    actionCreators.requestContracts();
                }, 300);
            })
            .catch(e => {
                notification.success({
                    message: "",
                    description: "Contrato Eliminado."
                });
                setTimeout(() => {
                    actionCreators.requestContracts();

                }, 300);
            });

    }
    ,
    markContractAsSelected: (contract: IDownloadContractModel): AppThunkAction<KnownAction | Action> => (

        dispatch,
        getState
    ) => {


        const selecteds = getState().contracts?.selectedContracts;
        var index = selecteds?.find(p => p.id == contract.id);
        if (index != undefined) {
            var found = selecteds?.indexOf(index);
            if (found != undefined)
                if (found > -1)
                    selecteds?.splice(found, 1);
        }
        else {

            selecteds?.push(contract);
        }
        dispatch({
            type: "MARK_CONTRACT_AS_SELECTED",
            selecteds
        });


    },
    requestGroupedData: (start:string,end:string): AppThunkAction<KnownAction | Action> => (dispatch,
        getState
    ) => {
        WizardService.GetGrouped(start,end)
            .then(data => {
               //TODO
                //dispatch({ type: "GROUPED_LOADED", contracts,byRate, arr, multi, arrRate, multiRate });
                dispatch({ type: "GROUPED_LOADED", multiRate: data });

            })
            .catch(e => {
                dispatch({ type: "GROUPED_LOADED", multiRate:[] });
            });

        dispatch({ type: "REQUEST_CONTRACTS" });
    },
    requestContracts: (): AppThunkAction<KnownAction | Action> => (
        dispatch,
        getState
    ) => {
        WizardService.Gets()
            .then(contracts => {
                dispatch({ type: "CONTRACTS_LOADED", contracts });
            })
            .catch(e => {
                dispatch({ type: "CONTRACTS_LOADED" });
            });

        dispatch({ type: "REQUEST_CONTRACTS" });
    },

    filterContracts: (cup: string, name: string, address: string, nif: string, callcenter: string, start: string, end: string, agente: string, status: Number, rate: string,phone:string): AppThunkAction<KnownAction | Action> => (
        dispatch,
        getState
    ) => {
        const getdate = (str: string) => {
            var date = str.split('/');
            return new Date(Number(date[2]), Number(date[1]) - 1, Number(date[0]));
        }
        var originalList = getState().contracts?.originalList;
        if (originalList) {
            if (status != 0) {
                originalList = originalList.filter(p => p.status == status);

            }
            if (phone?.trim() != "") {
                originalList = originalList.filter(p => p.customer.phone?.includes(phone.toLowerCase()) || p.customer.mobilePhone?.includes(phone.toLowerCase()));
            }
            if (cup?.trim() != "") {
                originalList = originalList.filter(p => p.cups.toLowerCase().includes(cup.toLowerCase()));
            }
            if (rate?.trim() != "" && rate?.trim() != "Tarifa") {
                originalList = originalList.filter(p => p.rate?.name.toLowerCase().includes(rate.toLowerCase()));
            }
            if (name?.trim() != "") {
                originalList = originalList.filter(c => c.customer != null && (c.customer.name + ' ' + c.customer.lastName + ' ' + c.customer.lastName2).toLowerCase().includes(name.toLowerCase()));

            }
            if (address?.trim() != "") {
                originalList = originalList.filter(c => c.customer != null && c.customer.personal != null && (c.customer.personal.city + ' ' + c.customer.personal.complement + ' ' + c.customer.personal.postalCode + ' ' + c.customer.personal.province + ' ' + c.customer.personal.street).toLowerCase().includes(address.toLowerCase()));
            }
            if (nif?.trim() != "") {
                originalList = originalList.filter(c => c.customer != null && (c.customer.nif)?.toLowerCase().includes(nif.toLowerCase()));

            }
            if (callcenter?.trim() != "") {
                originalList = originalList.filter(c => c.callCenterName != null && c.callCenterName.toLowerCase().includes(callcenter.toLowerCase()));

            }
            if (agente?.trim() != "") {
                originalList = originalList.filter(c => c.agentName != null && c.agentName.toLowerCase().includes(agente.toLowerCase()));

            }
            if (start != "") {
                originalList = originalList.filter(c => getdate(c.date) >= getdate(start));

            }
            if (end != "") {
                originalList = originalList.filter(c => getdate(c.date) <= getdate(end));

            }
            dispatch({
                type: "FILTER_CONTRACTS",
                contracts: originalList
            });
        }
    },

    filterGrouped: (year: Number, month: Number, status: Number, callCenter: string, start: string, end: string): AppThunkAction<KnownAction | Action> => (
        dispatch,
        getState
    ) => {
        

    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: ContractsState = {
    isLoading: false,
    list: [],
    showComparison: false,
    groupedRateClone:[],
    originalList: [],
    agent: '',
    callcenter: '',
    selectedContracts: [],
    agents: [],
    callcenters: [],
    grouped: [],
    groupedClone: [],
    defaultGrouped: [],
    multi: [],
    multiRate: [],
    defaultGroupedRate: [],
    defaultGroupedAll: [],
    multiAll: [],
    rates: [],
    aggregatedRate:[]
};

export const reducer: Reducer<ContractsState> = (
    state: ContractsState | undefined,
    action: KnownAction
): ContractsState => {
    if (state === undefined) {
        return unloadedState;
    }

    switch (action.type) {
        case "REQUEST_RATES":
            return {
                ...state,
                isLoading: true
            };
        case "RATES_LOADED_FINISED":
            return {
                ...state,
                isLoading: false,
                rates: action.rates || []
            };
        case "REQUEST_CONTRACTS":
            return {
                ...state,
                isLoading: true
            };
        case "REQUEST_GROUPED_CONTRACTS":
            return {
                ...state,
                isLoading: true
            };
        case "REQUEST_HOME":
            return {
                ...state,
                agents: action.agents,
                callcenters: action.callcenters
            };
        case "GROUPED_LOADED":
            return {
                ...state,
                isLoading: false,
                multiRate: action.multiRate || []

            };
        case "FILTER_GROUPED": return {
            ...state,
            isLoading: false,
            grouped: action.contracts || [],
            multi: action.multi || [],
            multiRate: action.multiRate || [],
            aggregatedRate: action.aggregatedRate || [],

        };
        case "CONTRACTS_LOADED":
            return {
                ...state,
                isLoading: false,
                originalList: action.contracts || [],
                list: action.contracts || []
            };
        case "FILTER_CONTRACTS":
            return {
                ...state,
                isLoading: false,
                list: action.contracts || []
            };
        case "MARK_CONTRACT_AS_SELECTED":
            return {
                ...state,
                isLoading: false,
                selectedContracts: action.selecteds || []
            };
        default:
            return state;
    }
};
