import { Reducer, Action } from "redux";

import { AppThunkAction } from "./";
import { ICallCenter } from "../models";
import { CallCentersService } from "../services";

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface CallCentersState {
    isLoading: boolean;
    list: ICallCenter[];
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface RequestCallCenterAction {
    type: "REQUEST_CALLCENTERS";
}

interface CallCentersLoadedAction {
    type: "CALLCENTERS_LOADED";
    callCenters?: ICallCenter[];
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = RequestCallCenterAction | CallCentersLoadedAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    requestCallCenters: (): AppThunkAction<KnownAction | Action> => (
        dispatch,
        getState
    ) => {
        CallCentersService.GetAll()
            .then((callCenters) => {
                dispatch({ type: "CALLCENTERS_LOADED", callCenters });
            })
            .catch((e) => {
                dispatch({ type: "CALLCENTERS_LOADED" });
            });

        dispatch({ type: "REQUEST_CALLCENTERS" });
    },
    addCallCenter: (
        callCenter: ICallCenter
    ): AppThunkAction<KnownAction | Action> => (dispatch, getState) => {
        CallCentersService.Add(callCenter)
            .catch((e) => { })
            .finally(() => {
                CallCentersService.GetAll()
                    .then((callCenters) => {
                        dispatch({ type: "CALLCENTERS_LOADED", callCenters });
                    })
                    .catch((e) => {
                        dispatch({ type: "CALLCENTERS_LOADED" });
                    });
            });

        dispatch({ type: "REQUEST_RATES" });
    },
    removeUser: (user: string, callCenter: string): AppThunkAction<KnownAction | Action> => async (dispatch, getState) => {
        await CallCentersService.RemoveUserFromCallCenter(user, callCenter);
    },
    editCallCenter: (
        callCenter: ICallCenter
    ): AppThunkAction<KnownAction | Action> => async (dispatch, getState) => {
        let ary = [];
        let ary2 = [];
        for (var counter = 0; counter < callCenter.users.length; counter++) {
            let user = callCenter.users[counter];
            if (user.callCenterId == "") {
                //Add new user
                ary.push(user);
            }
            else if (user.password !== null) {
                //update user
                ary2.push(user);

            }


        }
        await Promise.all([CallCentersService.AddUserToCallCenter(ary, callCenter.id), CallCentersService.UpdateUsers(ary2, callCenter.id), CallCentersService.Edit(callCenter)]);
        //await CallCentersService.AddUserToCallCenter(ary, callCenter.id);
        //await CallCentersService.UpdateUsers(ary2, callCenter.id);
        CallCentersService.GetAll()
            .then((callCenters) => {
                dispatch({ type: "CALLCENTERS_LOADED", callCenters });
            })
        //CallCentersService.Edit(callCenter)
        //    .catch((e) => { })
        //    .finally(() => {
        //        CallCentersService.GetAll()
        //            .then((callCenters) => {
        //                dispatch({ type: "CALLCENTERS_LOADED", callCenters });
        //            })
        //            .catch((e) => {
        //                dispatch({ type: "CALLCENTERS_LOADED" });
        //            });
        //    });

        dispatch({ type: "REQUEST_RATES" });
    },
    deleteCallCenter: (
        callCenter: ICallCenter
    ): AppThunkAction<KnownAction | Action> => (dispatch, getState) => {
        CallCentersService.Delete(callCenter)
            .catch((e) => { })
            .finally(() => {
                CallCentersService.GetAll()
                    .then((callCenters) => {
                        dispatch({ type: "CALLCENTERS_LOADED", callCenters });
                    })
                    .catch((e) => {
                        dispatch({ type: "CALLCENTERS_LOADED" });
                    });
            });

        dispatch({ type: "REQUEST_RATES" });
    },
    changeStateCallCenter: (
        callCenter: ICallCenter
    ): AppThunkAction<KnownAction | Action> => (dispatch, getState) => {
        let service;
        if (callCenter.active) {
            service = CallCentersService.Disable(callCenter)
                .catch((e) => {
                    alert(e);
                })
                .finally(() => {
                    CallCentersService.GetAll()
                        .then((callCenters) => {
                            dispatch({ type: "CALLCENTERS_LOADED", callCenters });
                        })
                        .catch((e) => {
                            dispatch({ type: "CALLCENTERS_LOADED" });
                        });
                });;
        }
        else {
            service = CallCentersService.Enable(callCenter)
                .catch((e) => {
                    alert(e);
                })
                .finally(() => {
                    CallCentersService.GetAll()
                        .then((callCenters) => {
                            dispatch({ type: "CALLCENTERS_LOADED", callCenters });
                        })
                        .catch((e) => {
                            dispatch({ type: "CALLCENTERS_LOADED" });
                        });
                });;
        }


        dispatch({ type: "REQUEST_RATES" });
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: CallCentersState = { isLoading: false, list: [] };

export const reducer: Reducer<CallCentersState> = (
    state: CallCentersState | undefined,
    action: KnownAction
): CallCentersState => {
    if (state === undefined) {
        return unloadedState;
    }

    switch (action.type) {
        case "REQUEST_CALLCENTERS":
            return {
                ...state,
                isLoading: true,
            };
        case "CALLCENTERS_LOADED":
            return {
                ...state,
                isLoading: false,
                list: (action.callCenters || []).map((c) => {
                    c.users = (c.users || []).map((u) => ({ ...u, idRow: Math.random() }));
                    return c;
                }),
            };
        default:
            return state;
    }
};
