import BaseService from "./BaseService";
import { IIncident } from "../models";

export class IncidentService extends BaseService {
    public async GetAll(): Promise<IIncident[]> {
        return await this.get(`incidents/all`);
    }
    public async GetById(id: string): Promise<IIncident> {
        return await this.get(`incidents/${id}`);
    }
    public async Add(id:number,callcenter:string,agent:string): Promise<IIncident> {
        return await this.post(`incidents/${id}/${callcenter}/${agent}/contract`, {});
    }

    public async Edit(rate: any): Promise<IIncident> {
        return await this.put(`incidents`, rate);
    }

    public async Delete(rate: string    ): Promise<boolean> {
        return await this.delete(`incidents/${rate}`);
    }

    public async Excel(): Promise<{}> {
        return await this.get("incident/excel");
    }
}

export default new IncidentService();
