import { Reducer, Action } from "redux";

import { AppThunkAction } from "./";
import { AuthService } from "../services";
import { IUser } from "../models";

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface UsersState {
  isLoading: boolean;
  list: IUser[];
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface RequestUsersAction {
  type: "REQUEST_CUSTOMERS";
}

interface UsersLoadedAction {
  type: "CUSTOMERS_LOADED";
  users?: IUser[];
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = RequestUsersAction | UsersLoadedAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
  requestUsers: (): AppThunkAction<KnownAction | Action> => (
    dispatch,
    getState
  ) => {
    AuthService.GetAll()
        .then(users => {
        dispatch({ type: "CUSTOMERS_LOADED", users });
      })
      .catch(e => {
        dispatch({ type: "CUSTOMERS_LOADED" });
      });

    dispatch({ type: "REQUEST_CUSTOMERS" });
    },
    createUser: (user: IUser): AppThunkAction<KnownAction | Action> => (
        dispatch,
        getState
    ) => {
        AuthService.AddUser(user)
            .then(rateEdited => {
                dispatch({
                    type: "CUSTOMERS_LOADED",
                    users: getState().users?.list || []
                });
            })
            .catch(e => {
                dispatch({ type: "CUSTOMERS_LOADED" });
            });

       
    },
    enableUser: (user: IUser): AppThunkAction<KnownAction | Action> => (
        dispatch,
        getState
    ) => {
        AuthService.Enable(user)
            .then(() => {
                dispatch({
                    type: "CUSTOMERS_LOADED",
                    users: getState().users?.list || []
                });
            })
            .catch(e => {
                dispatch({ type: "CUSTOMERS_LOADED" });
            });

        dispatch({ type: "REQUEST_CUSTOMERS" });
    },
  disableUser: (user: IUser): AppThunkAction<KnownAction | Action> => (
    dispatch,
    getState
  ) => {
    AuthService.Disable(user)
      .then(() => {
        dispatch({
          type: "CUSTOMERS_LOADED",
          users: getState().users?.list || []
        });
      })
      .catch(e => {
        dispatch({ type: "CUSTOMERS_LOADED" });
      });

    dispatch({ type: "REQUEST_CUSTOMERS" });
  }
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: UsersState = { isLoading: false, list: [] };

export const reducer: Reducer<UsersState> = (
  state: UsersState | undefined,
  action: KnownAction
): UsersState => {
  if (state === undefined) {
    return unloadedState;
  }

  switch (action.type) {
    case "REQUEST_CUSTOMERS":
      return {
        ...state,
        isLoading: true
      };
    case "CUSTOMERS_LOADED":
      return {
        ...state,
        isLoading: false,
        list: action.users || []
      };
    default:
      return state;
  }
};
