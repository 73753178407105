import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { Select, Table, Button, Checkbox, Input, DatePicker, ConfigProvider, Drawer } from "antd";
import { FormattedMessage } from "react-intl";
import { RouteComponentProps } from "react-router";
import { Link } from "react-router-dom";
import Search from "antd/lib/input/Search";

import { ApplicationState } from "../store";
import { PageLayout } from "../containers";
import * as ContractsStore from "../store/Contracts";
import * as RatesStore from "../store/Rates";

import { IContract, IContractFilter, IEstimation } from "../models";
import { ExcelButton } from "../components";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import locale from 'antd/es/locale/es_ES';
import { WizardService } from "../services/WizardService";
import { EditOutlined, DownloadOutlined, DeleteOutlined, WarningOutlined  } from '@ant-design/icons';
import CollapsePanel from "antd/lib/collapse/CollapsePanel";
import { Collapse } from 'antd';
import { isMobile } from 'react-device-detect';
const { Option } = Select;

const { Panel } = Collapse;
interface IStateProps extends RatesStore.RatesState {
    idRate: string;
    estimation: IEstimation;
}
type IProps = RouteComponentProps &
    ContractsStore.ContractsState &

    typeof ContractsStore.actionCreators;

const dotColor = ["red", "orange", "lightblue","green","red"];//TODO add color for send and then pass to green

const Contracts = (props: IProps) => {
    const {
        list,
        selectedContracts,
        isLoading,
        requestContracts,
        requestHome,
        filterContracts,
        deleteContract,
        requestRates,
        downloadContract, markContractAsSelected, markContractAsIncident
    } = props;
    const isAdmin = useSelector(
        (appState: ApplicationState) => appState.auth?.isAdmin
    );
    const isCallCenterAdmin = useSelector(
        (appState: ApplicationState) => appState.auth?.isCallCenterAdmin
    );

    //const deleteContract = (id: any) => console.log(id);
    const agents = useSelector((appsState: ApplicationState) => appsState.contracts?.agents);
    const callcenters = useSelector((appsState: ApplicationState) => appsState.contracts?.callcenters);
    const rates = useSelector((appsState: ApplicationState) => appsState.rates?.list);
    const [showFilter, setShowFilter] = useState(true);
    useEffect(() => {
        requestContracts();
        requestHome();
        requestRates();
        setShowFilter(false);
    }, []);

    return (
        <ConfigProvider locale={locale}>
            {isMobile ? (<PageLayout>
                <Drawer
                    title="Filtros"
                    placement={"right"}
                    closable={true}
                    onClose={() => setShowFilter(false)}
                    visible={showFilter}
                >
                    <Input placeholder="Cups" id="cupText" allowClear />
                    <Select defaultValue="Tarifa" id="rateSelect" style={{ width: 125 }}>
                        <Option key="select" value="Tarifa">Tarifa</Option>
                        {rates?.map(rate => <Option key={rate.name} value={rate.name}>{rate.description}</Option>)}
                    </Select>
                    <Input id="nameText" placeholder="Nombre" allowClear />
                    <Input id="addressText" placeholder="Direccion" allowClear />
                    <Input id="nifText" placeholder="Nif" allowClear />
                    <Input id="phoneText" placeholder="Telefono" allowClear />

                    <DatePicker locale={locale} id="startDate" placeholder="Fecha Inicio" format="DD/MM/YYYY" />
                    <DatePicker locale={locale} id="endDate" placeholder="Fecha Fin" format="DD/MM/YYYY" />
                    <Select id="statusselect" defaultValue="Estado" style={{ width: 250 }}>
                        <Option key="select" value="Estado">Estado</Option>
                        <Option key="1" value="1">Pendiente</Option>
                        <Option key="4" value="2">Pendiente de Firma</Option>
                        <Option key="2" value="3">Enviado</Option>

                    </Select>
                    {isAdmin && (
                        <div>
                            <Select defaultValue="Callcenter" id="callcenterselect" style={{ width: 250 }}>
                                <Option key="select" value="Callcenter">Callcenter</Option>
                                {callcenters?.map(center => <Option key={center.id} value={center.name}>{center.name}</Option>)}
                            </Select>
                            <Select defaultValue="Agente" id="agentselect" style={{ width: 250 }}>
                                <Option key="select" value="Agente">Agente</Option>

                                {agents?.map(agent => <Option key={agent.name} value={agent.name}>{agent.name}</Option>)}

                            </Select>



                        </div>
                    )}
                    <Button
                        onClick={() => {
                            var cups = (document.getElementById("cupText") as any).value;

                            var name = (document.getElementById("nameText") as any).value;
                            var address = (document.getElementById("addressText") as any).value;
                            var nif = (document.getElementById("nifText") as any).value;
                            var phone = (document.getElementById("phoneText") as any).value;
                            var start = (document.querySelector("span#startDate input") as any).value;
                            var end = (document.querySelector("span#endDate input") as any).value;
                            var rateS = (document.querySelector("#rateSelect .ant-select-selection-selected-value") as any)?.innerText;

                            var callcenter = "";
                            var agent = "";
                            var status = (document.querySelector("#statusselect .ant-select-selection-selected-value") as any)?.innerText;

                            if (isAdmin) {
                                callcenter = (document.querySelector("#callcenterselect .ant-select-selection-selected-value") as any)?.innerText;
                                agent = (document.querySelector("#agentselect .ant-select-selection-selected-value") as any)?.innerText;

                            }
                            filterContracts(cups, name, address, nif, (callcenter == "Callcenter" || callcenter == "" ? "" : callcenter), start, end, (agent == "Agente" || agent == "" ? "" : agent), (status == "Estado" || status == "" ? 0 : (status == "Pendiente" ? 1 : status == "Pendiente de Firma" ? 2 : 3)), rateS, phone);
                            setShowFilter(false);
                        }}>
                        Buscar</Button>
                </Drawer>
                <Button onClick={() => setShowFilter(true)} >Filtrar</Button>
                <Table<IContract>
                    rowKey={(record: IContract) => record.id.toString()}
                    dataSource={list}
                    loading={isLoading}
                >
                    {isAdmin && (
                        <Table.Column<IContract>
                            key="callcenter"
                            title="CallCenter"
                            dataIndex="id"
                            render={(_, s) => (
                                <div>

                                    {s.callCenterName}
                                </div>
                            )}
                        />
                    )}
                    
                    <Table.Column<IContract>
                        key="cups"
                        title={<FormattedMessage id="cups" defaultMessage="Cups" />}
                        dataIndex="cups"
                    />
                    
                    <Table.Column<IContract>
                        key="status"
                        title={<FormattedMessage id="status" defaultMessage="Status" />}
                        dataIndex="status"
                        render={(_, s) => (
                            <div
                                className="dot"
                                style={{ backgroundColor: dotColor[s.status] }}
                            ></div>
                        )}
                    />
                   

                </Table>
            </PageLayout>) : (
            
            <PageLayout>

                <Drawer
                    title="Filtros"
                    placement={"right"}
                    closable={true}
                    onClose={() => setShowFilter(false)}
                    visible={showFilter}
                >
                            <Input placeholder="Cups" id="cupText" allowClear />
                            <Select defaultValue="Tarifa" id="rateSelect" style={{ width: 125 }}>
                                <Option key="select" value="Tarifa">Tarifa</Option>
                                {rates?.map(rate => <Option key={rate.name} value={rate.name}>{rate.description}</Option>)}
                            </Select>
                    <Input id="nameText" placeholder="Nombre" allowClear />
                    <Input id="addressText" placeholder="Direccion" allowClear />
                    <Input id="nifText" placeholder="Nif" allowClear />
                            <Input id="phoneText" placeholder="Telefono" allowClear />
                    <DatePicker locale={locale} id="startDate" placeholder="Fecha Inicio" format="DD/MM/YYYY" />
                    <DatePicker locale={locale} id="endDate" placeholder="Fecha Fin" format="DD/MM/YYYY" />
                    <Select id="statusselect" defaultValue="Estado" style={{ width: 250 }}>
                        <Option key="select" value="Estado">Estado</Option>
                                <Option key="1" value="1">Pendiente</Option>
                                <Option key="4" value="2">Pendiente de Firma</Option>

                                <Option key="2" value="3">Enviado</Option>

                    </Select>
                    {isAdmin && (
                        <div>
                            <Select defaultValue="Callcenter" id="callcenterselect" style={{ width: 250 }}>
                                <Option key="select" value="Callcenter">Callcenter</Option>
                                {callcenters?.map(center => <Option key={center.id} value={center.name}>{center.name}</Option>)}
                            </Select>
                            <Select defaultValue="Agente" id="agentselect" style={{ width: 250 }}>
                                <Option key="select" value="Agente">Agente</Option>

                                {agents?.map(agent => <Option key={agent.name} value={agent.name}>{agent.name}</Option>)}

                            </Select>



                        </div>
                    )}
                    <Button
                        onClick={() => {
                            var cups = (document.getElementById("cupText") as any).value;
                            var name = (document.getElementById("nameText") as any).value;
                            var address = (document.getElementById("addressText") as any).value;
                            var nif = (document.getElementById("nifText") as any).value;
                            var end = (document.querySelector("span#endDate input") as any).value;
                                    var start = (document.querySelector("span#startDate input") as any).value;
                                    var phone = (document.getElementById("phoneText") as any).value;

                                    var end = (document.querySelector("span#endDate input") as any).value;
                            var callcenter = "";
                            var agent = "";
                            var status = (document.querySelector("#statusselect .ant-select-selection-selected-value") as any)?.innerText;
                                    var rateS = (document.querySelector("#rateSelect .ant-select-selection-selected-value") as any)?.innerText;
                            if (isAdmin) {
                                callcenter = (document.querySelector("#callcenterselect .ant-select-selection-selected-value") as any)?.innerText;
                                agent = (document.querySelector("#agentselect .ant-select-selection-selected-value") as any)?.innerText;

                                    }
                                    
                                    filterContracts(cups, name, address, nif, (callcenter == "Callcenter" || callcenter == "" ? "" : callcenter), start, end, (agent == "Agente" || agent == "" ? "" : agent), (status == "Estado" || status == "" ? 0 : (status == "Pendiente" ? 1 : status == "Pendiente de Firma" ? 2 : 3)),rateS,phone);
                            setShowFilter(false);
                        }}>
                        Buscar</Button>
                </Drawer>
                
                <div className="actions">
                            <div>

                    <Button type="primary">
                        <Link to={`/wizards`}>
                            <FormattedMessage id="create" defaultMessage="Create" />
                        </Link>
                    </Button>
                    <Button
                            onClick={() => {
                                new WizardService().downloadwithParameters("wizard/excel", "contracts.xlsx", {
                                    cups: (document.getElementById("cupText") as any).value,
                                    startDate: (document.querySelector("span#startDate input") as any).value,
                                    endDate: (document.querySelector("span#endDate input") as any).value,
                                    callcenter: (document.querySelector("#callcenterselect .ant-select-selection-selected-value") as any)?.innerText,
                                    agent: (document.querySelector("#agentselect .ant-select-selection-selected-value") as any)?.innerText,
                                    name: (document.getElementById("nameText") as any).value,
                                    address: (document.getElementById("addressText") as any).value,
                                    nif: (document.getElementById("nifText") as any).value,
                                    status: '1' //todo
                                })
                            }}
                        type="primary"
                    >
                        <FormattedMessage id="downloadExcel" defaultMessage="Descargar Excel" />
                    </Button>
                    <Button onClick={() => {
                        if (selectedContracts.length > 0) {
                            for (var counter = 0; counter < selectedContracts.length; counter++) {
                                let s = selectedContracts[counter];
                                downloadContract(s.id, s.contractIdentifier, s.cups);
                            }
                        }
                                }}>Descarga Multiple</Button>

                                
                    <Button onClick={() => {
                        if (selectedContracts.length > 0) {
                            for (var counter = 0; counter < selectedContracts.length; counter++) {
                                let s = selectedContracts[counter];
                                deleteContract(s.id);
                            }
                        }
                    }}>Eliminado Multiple</Button>
                </div>
                    <div>
                    <Button onClick={() => setShowFilter(true)} >Filtrar</Button>

                </div>
                </div>
                <br />
                <Table<IContract>
                            rowKey={(record: IContract) => record.id.toString()}
                            dataSource={list}
                            loading={isLoading}
                >
                    {isAdmin && (
                        <Table.Column<IContract>
                            key="callcenter"
                            title="CallCenter"
                            dataIndex="id"
                            render={(_, s) => (
                                <div>

                                    {s.callCenterName}
                                </div>
                            )}
                        />
                    )}
                    {(isAdmin || isCallCenterAdmin) && (
                        <Table.Column<IContract>
                            key="callcenterAgent"
                            title="Agente"
                            dataIndex="id"
                            render={(_, s) => (
                                <div>

                                    {s.agentName}
                                </div>
                            )}
                        />
                    )}
                    <Table.Column<IContract>
                        key="date"
                        title={<FormattedMessage id="date" defaultMessage="Fecha" />}
                        dataIndex="date"
                    />
                    <Table.Column<IContract>
                        key="cups"
                        title={<FormattedMessage id="cups" defaultMessage="Cups" />}
                        dataIndex="cups"
                    />
                    <Table.Column<IContract>
                        key="customer"
                        title={<FormattedMessage id="customer" defaultMessage="Cliente" />}
                        dataIndex="customer.name"
                        render={(_, s) => (
                            <div>
                                {(s.customer.name == null ? "" : s.customer.name) +
                                    " " +
                                    (s.customer.lastName == null ? "" : s.customer.lastName) +
                                    " " +
                                    (s.customer.lastName2 == null ? "" : s.customer.lastName2)}
                            </div>
                        )}
                    />
                    <Table.Column<IContract>
                        key="rate"
                        title={<FormattedMessage id="rate" defaultMessage="Rate" />}
                        dataIndex="rate.name"
                    />

                    <Table.Column<IContract>
                        key="status"
                        title={<FormattedMessage id="status" defaultMessage="Status" />}
                        dataIndex="status"
                        render={(_, s) => (
                            <div
                                className="dot"
                                style={{ backgroundColor: dotColor[s.status] }}
                            ></div>
                        )}
                    />
                    <Table.Column<IContract>
                        key="id"
                        title="Edit"
                        dataIndex="id"
                        render={(_, s) => (
                            <Button>
                                <Link to={`/wizards/${s.id}`}>
                                    <EditOutlined />
                                </Link>
                            </Button>
                        )}
                    />

                    {(isAdmin || isCallCenterAdmin) && (
                        <Table.Column<IContract>
                            key="deleteid"
                            title="Delete"
                            dataIndex="id"
                            render={(_, s) => (
                                <Button onClick={() => deleteContract(s.id)}>
                                    <DeleteOutlined />
                                </Button>
                            )}
                        />
                    )}
                    {(isAdmin || isCallCenterAdmin) && (
                        <Table.Column<IContract>
                            key="printPdf"
                            title="Print"
                            dataIndex="id"
                            render={(_, s) => (
                                <Button onClick={() => downloadContract(s.id, s.contractIdentifier, s.callCenterIdentifier.toString())}>
                                    <DownloadOutlined />
                                </Button>
                            )}
                        />
                    )}

                    {(isAdmin || isCallCenterAdmin) && (
                        <Table.Column<IContract>
                            key="selected"
                            title=" "
                            dataIndex="id"
                            render={(_, s) => (
                                <Checkbox onChange={(a: CheckboxChangeEvent) => {
                                    markContractAsSelected({ id: s.id, contractIdentifier: s.contractIdentifier, callCenterIdentifier: s.callCenterIdentifier.toString(), cups: s.cups });
                                }} />
                            )}
                        />
                    )}

                </Table>
            </PageLayout >)}
        </ConfigProvider>
    );
};

export default connect(
    (state: ApplicationState) => state.contracts,
    ContractsStore.actionCreators
)(Contracts as any);
