import React from "react";
import { Input } from "antd";
import { connect } from "react-redux";
import { injectIntl, IntlShape } from "react-intl";
import { debounce } from "ts-debounce";
import FormItem from "antd/lib/form/FormItem";

import { ApplicationState } from "../../store";
import * as WizardStore from "../../store/Wizard";
import { valida_cups } from "../../helpers";

const { Search } = Input;

type IProps = typeof WizardStore.actionCreators & {
  cups: string;
  intl: IntlShape;
  isLoading: boolean;
};

const First = (props: IProps) => {
  const { cups, getInfoCups, intl, isLoading } = props;

    const onChange = debounce((newCupValue: string) => {
        

        if (newCupValue.length > 6) {

            var ary = ['ES0217', 'ES0230', 'ES0217', 'ES0218', 'ES0220', 'ES0221', 'ES0227', 'ES0226', 'ES0224', 'ES0222', 'ES0236', 'ES0234', 'ES0205', 'ES0208', 'ES0225', 'ES0204', 'ES0229', 'ES0213', 'ES0214', 'ES0210', 'ES0203', 'ES0201', 'ES0219', 'ES0211', 'ES0207'];

            if (ary.indexOf(newCupValue.substr(0, 6).toUpperCase()) >= 0) {
                    alert("El cup introducido pertenece al sector de GAS.")
                }
            else {
            getInfoCups(newCupValue.trim());

}

        }
  }, 500);

  return (
    <>
      <FormItem
        validateStatus={
          cups === "" ? "validating" : valida_cups(cups) ? "success" : "error"
        }
        label={intl.messages["cups"]}
      >
        <Search
          defaultValue={props.cups}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onChange(e.target.value)
          }
          loading={isLoading}
        />
      </FormItem>
    </>
  );
};

export default connect(
  (state: ApplicationState) => ({
    cups: state.wizard?.cups,
    isLoading: state.wizard?.isLoadingCups
  }),
  WizardStore.actionCreators
)(injectIntl(First) as any);
