import { IntlState } from "react-intl-redux";

import * as Auth from "./Auth";
import * as Rates from "./Rates";
import * as Incidents from "./Incidents";
import * as Users from "./Users";
import * as Wizard from "./Wizard";
import * as Contracts from "./Contracts";
import * as CallCenters from "./CallCenter";
import * as Excel from "./Excel";

// The top-level state object
export interface ApplicationState {
    intl: IntlState | undefined;
    auth: Auth.AuthState | undefined;
    rates: Rates.RatesState | undefined;
    incidents: Incidents.IncidentState | undefined;
    excel: Excel.ExcelState | undefined
    users: Users.UsersState | undefined;
    wizard: Wizard.WizardState | undefined;
    contracts: Contracts.ContractsState | undefined;
    callcenters: CallCenters.CallCentersState | undefined;
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
    auth: Auth.reducer,
    rates: Rates.reducer,
    users: Users.reducer,
    incidents: Incidents.reducer,
    wizard: Wizard.reducer,
    contracts: Contracts.reducer,
    callcenters: CallCenters.reducer,
    excel: Excel.reducer
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}
