import React from "react";
import { Alert } from "antd";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { isValid as IsIbanValid } from "iban";

import { ApplicationState } from "../store";
import { ICustomer } from "../models";

type IProps = {
    customer: ICustomer;
    isValid: boolean;
    stepId: number;
};

const Fields = (customer: ICustomer) => {
    const { iban, personal, billing } = customer;
    const isValidNif = (dni: string, isPerson: number) => {
        if (isPerson == 1)
            return true;
        var DNI_REGEX = /^(\d{8})([A-Z])$/;
        var NIE_REGEX = /^[XYZ]\d{7,8}[A-Z]$/;
        var str = dni.trim();
        str = str.toUpperCase().replace(/\s/, '');
        if (str.match(DNI_REGEX)) {
            var dni_letters = "TRWAGMYFPDXBNJZSQVHLCKE";
            var letter = dni_letters.charAt(parseInt(dni, 10) % 23);

            return letter == dni.charAt(8);
        }
        else if (str.match(NIE_REGEX)) {
            var nie_prefix = dni.charAt(0);
            var prefix = 0;
            switch (nie_prefix) {
                case 'X': prefix = 0; break;
                case 'Y': prefix = 1; break;
                case 'Z': prefix = 2; break;
            }
            var dni_letters = "TRWAGMYFPDXBNJZSQVHLCKE";
            var letter = dni_letters.charAt(parseInt(prefix.toString() + dni.substr(1), 10) % 23);

            return letter == dni.charAt(8);
        }
    };
    var entries = Object.entries(customer);
    var ibanValidated = IsIbanValid(iban);
    var isPostalCodeValid = personal.postalCode.toString().length == 5;
    var isBillingPostalCodeValid = billing.postalCode.toString().length == 5;

    if (customer.isPromotional == false) {
        entries = entries.filter(([key, val]) => typeof key !== "object" && key !== "promotiona")

    }
    if (customer.invoiceKind != 0) {
        entries = entries.filter(([key, val]) => typeof key !== "object" && key !== "email")
    }
    if (customer.billingKind != 0) {
        entries = entries.filter(([key, val]) => typeof key !== "object" && key !== "iban");
        ibanValidated = true;
    }
    if (!customer.isPerson) {
        entries = entries.filter(([key, val]) => typeof key !== "object" && key !== "socialReason" && key !== "representName")
    } else {
        entries = entries.filter(([key, val]) => typeof key !== "object" && key !== "name" && key !== "lastName" && key !== "lastName2")
    }

  return (
    <div>
      <FormattedMessage
        id="solveErrors"
        defaultMessage="Please solve the following empty inputs:"
      />
      <ul>
        {[
          entries
                .filter(p => p[0] != "phone" && typeof p[1] !== "object" && p[1]==="")
            .map(([key, _]) => (
              <li key={key}>
                <FormattedMessage id={key} />
              </li>
            )),
          Object.entries(personal)
            .filter(([key, val]) => typeof key !== "object" && val === "")
            .map(([key, _]) => (
              <li key={key}>
                <FormattedMessage id={key} />
              </li>
            )),
          Object.entries(billing)
            .filter(([key, val]) => typeof key !== "object" && val === "")
            .map(([key, _]) => (
              <li key={key}>
                <FormattedMessage id={key} />
              </li>
            )),
                  !isPostalCodeValid && (
                      <li key={"postalCodeN"}>
                          El codigo postal no es valido.
                      </li>),
                  !isBillingPostalCodeValid && (
                      <li key={"postalCodeN"}>
                          El codigo postal de facturacion no es valido.
                      </li>),
                  !ibanValidated && (
            <li key={"iban"}>
              <FormattedMessage id="invalidIban" />
              </li>),
            !isValidNif(customer.nif,customer.isPerson) && (
                      <li key={"nif-format"}>
                    <FormattedMessage id="invalidNIF" />
                      </li>
          )
        ]}
      </ul>
    </div>
  );
};

const CustomerFieldsMessage = (props: IProps) => {
    const { isValid, customer, stepId } = props;

    return (
        stepId === 2 &&
        !isValid && (
            <Alert
                message={Fields(customer)}
                type="error"
                style={{ marginTop: "10px" }}
            />
        )
    );
};

export default connect((state: ApplicationState) => ({
    stepId: state.wizard?.stepId,
    isValid: state.wizard?.isValid[2],
    customer: state.wizard?.customer
}))(CustomerFieldsMessage as any);
