import React, { useEffect,useState } from "react";
import { Select } from "antd";
import { connect } from "react-redux";
import { ApplicationState } from "../../store";
import * as RatesStore from "../../store/Rates";
import * as WizardStore from "../../store/Wizard";
import { IEstimation } from "../../models";
import { notification } from "antd";
import { filterObjects, filterObjectsOtra, filterEditableObjectsDHA, filterEditableObjectsA } from "../../helpers";
import WizardItem from "../../components/WizardItem";
const { Option } = Select;
interface IStateProps extends RatesStore.RatesState {
    idRate: string;
    estimation: IEstimation;
    potencia1: string;
    potencia2: string;
    promotiona: string;
}

type IProps = typeof RatesStore.actionCreators &
    typeof WizardStore.actionCreators &
    IStateProps;

const Second = (props: IProps) => {
    const {
        requestRates,
        requestPromotions,
        isLoading,
        list,
        promotions,
        selectRate,
        estimation,
        potencia1,
        potencia2,
        idRate,
        updateWizardRate,
        getShowComparison,
        updateWizard,
        promotiona
    } = props;
    const [p1, setP1] = useState('0');
    const [p2, setP2] = useState('0');
    useEffect(() => {
        requestPromotions();

        requestRates();
        console.log(estimation);

        //alert(JSON.stingify(estimation));
    }, []);
    useEffect(() => {
        console.log("potencia1: " + potencia1)
        setP1(potencia1);
    }, [potencia1])
    useEffect(() => {
        console.log("potencia1: " + potencia1)
        setP2(potencia2);
    }, [potencia2])
    useEffect(() => {
        if (list) {
            setShowGift(false);
            let item = list.find(a => a.id == idRate);
            if (item) {
                if (item.name.toUpperCase().indexOf('GIFT') > 0) {

                    setShowGift(true);
                }
            }
		}
    }, [list])
    //useEffect(() => {
    //    if (estimation.existsError)
    //        notification.warn({
    //            message: "Aviso",
    //            description: "No se han podido obtener informacion del CUPS."
    //        });
    //}, [estimation.existsError]);
    const onBlur = (e: {
        target: { name: string; value: string | number | boolean };
    }) => {
        const { name, value } = e.target;
        updateWizardRate(name, (potencia1 as string).replace(",", "."));


    };
    const onKeyDown = (e: {
        target: { name: string; value: any };
    }) => {
        const { name, value } = e.target;
        return /^[0-9,]+$/.test(value);

    };
    const [showGift, setShowGift] = useState(false);
    const showComparisonTable = (value: boolean) => {
        return (<div>
            <h1>Escandinava</h1>
            <table className="comparativa">
                <thead>
                    <tr>
                        <th>Propiedad</th>
                        <th>Valor</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            Potencia P1
                        </td>
                        <td>
                            
                            <input
                                onChange={(e: any) => {
                                    debugger;
                                    updateWizardRate("potencia1", (e.target.value as string).replace(",", "."));
                                    setP1((e.target.value as string).replace(",", "."));
                                }}
                                key={"potencia1"}
                                value={p1?.toString().replace(".", ",")}
                                name={"potencia1"}
                            />
                            </td>
                    </tr>
                    <tr>
                        <td>
                            Potencia P2
                        </td>
                        <td>
                            <input
                                onChange={(e: any) => {
                                    debugger;
                                    updateWizardRate("potencia2", (e.target.value as string).replace(",", "."));
                                    setP2((e.target.value as string).replace(",", "."));
                                }}
                                key={"potencia2"}
                                value={p2?.toString().replace(".", ",")}
                                name={"potencia2"}
                            />
                        </td>
                    </tr>

                </tbody>
            </table>
        </div>);
    }
    return (
        <div className="steps-second">
            <div>
                Tarifa:<Select
                    loading={isLoading}
                    style={{ width: 220 }}
                    onChange={(id: string) => {
                        let item = list.find(a => a.id == id);
                        setShowGift(false);
                        if (item) {
                            if (item.name.toUpperCase().indexOf('GIFT')>0) {
                                setShowGift(true);
                                setTimeout(() => {
                                    updateWizard("promotiona", "Patinete");
                                        
                                }, 100);

							}
                        }

                        selectRate(id);
                    }
                    }
                    defaultValue={idRate}
                >
                    {list.map(r => (
                        <Option key={r.id} value={r.id}>
                            
                            {r.name}
                        </Option>
                    ))}
                </Select></div>
            <br/>
            <div>
                {
                    showGift ? (<div>Regalo:<Select

                        loading={isLoading}
                        style={{ width: 220 }}
                        defaultValue={"Patinete"}
                        value={promotiona}
                        onChange={(a: string) => {
                            alert(a)
                            updateWizard("promotiona",a)
                        }}
                    >
                        {promotions.map(r => (
                            <Option key={r.id} value={r.name}>
                                {r.name}
                            </Option>
                        ))}
                    </Select></div>) : null
				}
                
            </div>
            {getShowComparison() ? showComparisonTable(true) : showComparisonTable(false)}

        </div>
    );
};

export default connect(
    (state: ApplicationState) => ({
        ...state.rates,
        idRate: state.wizard?.rateId,
        promotiona: state.wizard?.customer.promotiona,
        //estimation: state.wizard?.estimation,
        potencia1: state.wizard?.potencia1,
        potencia2: state.wizard?.potencia2,
    }),
    {
        ...RatesStore.actionCreators,
        ...WizardStore.actionCreators
    }
)(Second as any);
