import React, { useEffect, useRef, useState } from "react";

import { Checkbox, Alert, Button } from "antd";
import { connect } from "react-redux";
import { injectIntl, IntlShape, FormattedMessage } from "react-intl";
import FormItem from "antd/lib/form/FormItem";

import { ApplicationState } from "../../store";
import * as WizardStore from "../../store/Wizard";
import { ICustomer, IResult } from "../../models";
import WizardItem from "../../components/WizardItem";
import { filterObjects } from "../../helpers";

type IProps = typeof WizardStore.actionCreators & {
    id: number;
    showLogalty: boolean;
    logaltyId: string;
    logaltyStatus: IResult;
};


const Fifth = (props: IProps) => {
    const [message, setMessage] = useState('');
    const [loader, setLoader] = useState(1);
    const {
        getLogaltyStatus,
        clean
    } = props;
    let checkStatus =  () => {
        console.log('hola');

            setLoader(1);
        getLogaltyStatus(props.id);
            //console.log(result);

    }
    let id;
    useEffect(() => {
        checkStatus();

    }, []);
    useEffect(() => {
        const f = (str: string)=> {
            if (str == 'USER_MOBILE_CHANGED') {
                return 'El usuario ha cambiado su numero de telefono.'
            }
            if (str == 'SMS_NOT_RECEIVED') {
                return 'El sms no se ha recibido.'
            }
            if (str == 'SENDER_UNKNOWN') {
                return 'Remitente erroneo.'

            }
            if (str == "OPTION_ZERO") {
                return "Operacion Cancelada sin indicar el motivo."
			}
            if (str == 'USER_DATA_INVALID') {
                return 'Datos del formulario incorrectos.'
            }
            if (str == 'RCPT_INVALID') {
                return 'Persona incorrecta.'
            }
            return str;
        }
        if (props.logaltyStatus.status != "" && props.logaltyStatus.status != "0") {
            setLoader(0);
            setMessage(props.logaltyStatus.reason ? f(props.logaltyStatus.reason) : 'Operacion realizada correctamente.');

        } else {
            setLoader(2);
        }
    }, [props.logaltyStatus]);
    const stopInterval = () => {
        clearInterval(id);
    }

    return (
        <div className="fifth-step">
            <Button onClick={() => checkStatus()}>Actualizar</Button>
            <Button disabled={props.logaltyStatus?.status=="7"} onClick={() => clean(props.id)} title="Si hay error usar esta opcion, para poder enviar de nuevo a logalty.">Limpiar</Button>
            <br />
            {loader == 1 ? (<div>
                Estamos comprobando el estado de la firma
                <div className="loader">Loading...</div>
            </div>) : (loader==0 ? (props.logaltyStatus.status == "7" ? (
                <svg className="svg" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                    <circle className="path circle" fill="none" stroke="#73AF55" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1" />
                    <polyline className="path check" fill="none" stroke="#73AF55" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 " />
                </svg>
            ) : (
                    <svg className="svg" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                        <circle className="path circle" fill="none" stroke="#D06079" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1" />
                        <line className="path line" fill="none" stroke="#D06079" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" x1="34.4" y1="37.9" x2="95.8" y2="92.3" />
                        <line className="path line" fill="none" stroke="#D06079" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" x1="95.8" y1="38" x2="34.4" y2="92.2" />
                    </svg>
                    )) : <div>Aun no se dispone de la informaci�n de la firma, presiona actualizar pasados unos segundos.</div>)
            }
          Resultado: {message}

        </div>
    );
};
export default connect((state: ApplicationState) => (
    { id: state.wizard?.id, logaltyId: state.wizard?.logaltyId, logaltyStatus: state.wizard?.logaltyStatus }), {
    ...WizardStore.actionCreators
})(
    Fifth as any
);

