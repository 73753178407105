import { Reducer, Action } from "redux";

import { AppThunkAction } from "./";
import { IncidentService } from "../services";
import { IIncident } from "../models";
import { debug } from "console";
import { notification } from "antd";

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface IncidentState {
    isLoading: boolean;
    list: IIncident[];
    selected: IIncident;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface RequestIncidentsAction {
    type: "REQUEST_INCIDENTS";
}
interface StartRequestAction {
    type: "START_REQUEST";
}
interface IncidentsLoadedAction {
    type: "INCIDENTS_LOADED_FINISHED";
    incidents?: IIncident[];
}
interface IncidentLoadedAction {
    incident: any;
    type: "INCIDENT_LOADED_FINISHED";
    selected?: IIncident;
}
// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = RequestIncidentsAction | IncidentsLoadedAction | IncidentLoadedAction | StartRequestAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    requestIncidents: (): AppThunkAction<KnownAction | Action> => (
        dispatch,
        getState
    ) => {
        IncidentService.GetAll()
            .then(incidents => {
                dispatch({ type: "INCIDENTS_LOADED_FINISHED", incidents });
            })
            .catch(e => {
                dispatch({ type: "INCIDENTS_LOADED_FINISHED" });
            });

        dispatch({ type: "REQUEST_INCIDENTS" });
    },
    requestIncident: (id: string): AppThunkAction<KnownAction | Action> => async (
        dispatch,
        getState
    ) => {
        dispatch({
            type: "START_REQUEST"
        });
        let incident = await IncidentService.GetById(id);
        dispatch({ type: "INCIDENT_LOADED_FINISHED", incident });
    },
    deletetIncident: (id: string): AppThunkAction<KnownAction | Action> => async (
        dispatch,
        getState
    ) => {
        //dispatch({
        //    type: "START_REQUEST"
        //});
        await IncidentService.Delete(id);
    },
    addIncident: (incident: IIncident): AppThunkAction<KnownAction | Action> => (
        dispatch,
        getState
    ) => {
        IncidentService.Add(1, "", "")
            .then(incident => {
                dispatch({
                    type: "INCIDENTS_LOADED_FINISHED",
                    incidents: (getState().incidents?.list || []).concat(incident)
                });
            })
            .catch(e => {
                dispatch({ type: "INCIDENTS_LOADED_FINISHED" });
            });

        dispatch({ type: "REQUEST_INCIDENTS" });
    },
    edit: (incident: any): AppThunkAction<KnownAction | Action> => async(
        dispatch,
        getState
    ) => {
        notification.success({
            message: "",
            description: "Incidencia Guardada."
        });
        await
        IncidentService.Edit(incident)
            //.then(rateEdited => {
                notification.success({
                    message: "",
                    description: "Incidencia Guardada."
                });
                //dispatch({
                //    type: "INCIDENTS_LOADED_FINISHED",
                //    rates
                //});
            //})
            //.catch(e => {
                //dispatch({ type: "INCIDENTS_LOADED_FINISHED" });
            //});

        //dispatch({ type: "REQUEST_INCIDENTS" });
    },
    delete: (rate: string): AppThunkAction<KnownAction | Action> => (
        dispatch,
        getState
    ) => {
        IncidentService.Delete(rate)
            .then(() => {
                //const rates = getState().incidents?.list || [];
                //const ind = rates.findIndex(obj => obj.id === rate);
                //rates.splice(ind, 1);

                //dispatch({
                //    type: "INCIDENTS_LOADED_FINISHED",
                //    incidents
                //});
            })
            .catch(e => {
                dispatch({ type: "INCIDENTS_LOADED_FINISHED" });
            });

        dispatch({ type: "REQUEST_INCIDENTS" });
    }
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: IncidentState = {
    isLoading: false, list: [], selected: null
};

export const reducer: Reducer<IncidentState> = (
    state: IncidentState | undefined,
    action: KnownAction
): IncidentState => {
    if (state === undefined) {
        return unloadedState;
    }

    switch (action.type) {
        case "START_REQUEST":
            return {
                ...state,
                isLoading: false,
                selected: null
            };
        case "REQUEST_INCIDENTS":
            return {
                ...state,
                isLoading: true
            };
        case "INCIDENTS_LOADED_FINISHED":
            return {
                ...state,
                isLoading: false,
                list: action.incidents || []
            };
        case "INCIDENT_LOADED_FINISHED":
            return {
                ...state,
                isLoading: false,
                selected: action.incident,
                list: []
            };
        default:
            return state;
    }
};
