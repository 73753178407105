import BaseService from "./BaseService";
import { ITemplate } from "../models";

export class TemplateService extends BaseService {
    public async GetAll(): Promise<ITemplate[]> {
    return await this.get(`template/all`);
  }

    public async Add(template: ITemplate): Promise<ITemplate> {
        return await this.post(`template`, template);
  }

    public async Edit(template: ITemplate): Promise<ITemplate> {
        return await this.put(`template`, template);
  }

    public async Delete(template: ITemplate): Promise<boolean> {
        return await this.delete(`template/${template.id}`);
  }

}

export default new TemplateService();
