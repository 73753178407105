import queryString from "query-string";

import BaseService from "./BaseService";
import { IUser } from "../models";

export class AuthService extends BaseService {
  public async SignIn(
    username: string,
    password: string
  ): Promise<{ token: string }> {
    return await this.post(
      `api/auth/login?${queryString.stringify({ username, password })}`,
      null
    );
  }

  public async GetAll(): Promise<any[]> {
    return await this.get("api/auth/all");
  }

  public async Disable(user: IUser): Promise<boolean> {
      return await this.delete(`api/auth/${user.id}`);
    }
    public async Enable(user: IUser): Promise<boolean> {
        return await this.delete(`api/auth/${user.id}/enable`);
    }
    public async EditUser(user: IUser): Promise<boolean> {
        return await this.delete(`api/auth/{user.id}`);
    }
    public async AddUser(user: IUser): Promise<boolean> {
        return await this.post('api/auth/register', { username: user.name, password: user.password });
    }
}

export default new AuthService();
