import BaseService from "./BaseService";
import { IEstimation } from "../models";

export class CupsService extends BaseService {
  public async Get(id: string, estimationId: number): Promise<IEstimation> {
    return await this.get(`cups/${id}/${estimationId}/full`);
  }
}

export default new CupsService();
