import React, { PureComponent, useEffect } from "react";
import { connect } from "react-redux";
import { Table, Button, Divider, Switch, Checkbox, Input, Tabs, Select } from "antd";
import { FormattedMessage } from "react-intl";
import { RouteComponentProps } from "react-router";

import { ApplicationState } from "../store";
import { RateModal, ExcelButton } from "../components";
import * as ExcelStore from "../store/Excel";
import { PageLayout } from "../containers";
import FormItem from "antd/lib/form/FormItem";
import Search from "antd/lib/input/Search";
import { valida_cups } from "../helpers";
import { debounce } from "ts-debounce";
import TextArea from "antd/lib/input/TextArea";
import { ExcelService, TemplateService } from "../services";
import { ITemplate } from "../models";
const { TabPane } = Tabs;
const { Option } = Select;

type IProps = RouteComponentProps &
    ExcelStore.ExcelState &
    typeof ExcelStore.actionCreators;

interface IState {
    text: string,
    cups: string,
    url: string,
    cupsAry: string[],
    addressToSearch: string[],
    infoAry: string[],
    addressAry: any[],
    loading: boolean;
    isCup: boolean;
    address: string;
    postalCode: string;
    templates: ITemplate[];
    cup: string;
    fileName: string;
    selectedTemplate: string;
    individual: boolean;
    cupsAryTemplate: string[];
}

class Excel extends PureComponent<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            text: "Por Cups",
            cups: "",
            url: "",
            cupsAry: [],
            addressAry: [],
            addressToSearch: [],
            infoAry: [],
            loading: false,
            isCup: true,
            address: '',
            postalCode: '',
            templates: [],
            cup: '',
            fileName: '',
            selectedTemplate: '',
            individual: true,
            cupsAryTemplate: []
        };
    }

    public componentDidMount() {
        TemplateService.GetAll()
            .then((templates) => {
                this.setState({ templates: templates });
            })
            .catch((e) => {
            });
    }



    render() {

        const TabCallback = (key: number) => {
            this.setState({
                isCup: key == 1
            });
        }
        const { cups, url } = this.state;
        const { getInfoCups, isLoading } = this.props;
        const onchangeV2 = debounce((ary: string[]) => {
            this.setState({ cupsAry: ary })
        });
        const onchangeCupsAryTemplate = debounce((ary: string[]) => {
            this.setState({ cupsAryTemplate: ary })
        });

        const onchangeV5 = debounce((ary: string) => {
            this.setState({ cup: ary })
        });
        const onchangeV6 = debounce((ary: string) => {
            this.setState({ fileName: ary })
        });
        const onchangeV3 = debounce((ary: string) => {
            this.setState({ address: ary })
        });
        const onchangeV4 = debounce((ary: string) => {
            this.setState({ postalCode: ary })
        });
        const onChange = debounce((newCupValue: string) => {

            this.setState({
                cups: newCupValue,
                url: `excelGenerator/${newCupValue}/full`,
            });
        }, 500);
        const onChangeSw = debounce((newCupValue: boolean) => {

            this.setState({
                text: newCupValue ? "Cup Simple" : "Multiples Cups",
                individual: newCupValue
            });
        }, 100);
        const onCheckCups = (cups: any) => {
            var ary = this.state.addressToSearch;
            let index = ary.indexOf(cups);
            if (index >= 0)
                ary.splice(index, 1);
            else
                ary.push(cups);
            this.setState({ addressToSearch: ary });
            console.log(this.state.addressToSearch);
        }
        const renderForTemplate = () => {
            return (
                <div>
                    <Switch defaultChecked onChange={onChangeSw} /> {this.state.text}
                    {this.state.individual == true ? (<div>
                        <Select defaultValue="default" style={{ width: 500 }} onChange={(value: string) => this.setState({ selectedTemplate: value })}>
                            <Option value="default">Elige una Plantilla</Option>
                            {this.state.templates.map(p => {
                                return (<Option value={p.id}>{p.name}</Option>);
                            })}
                        </Select>
                        <Input placeholder="CUPS" onChange={(e: React.ChangeEvent<any>) => {
                            onchangeV5(e.currentTarget.value);
                        }}></Input>
                        <Input placeholder="Nombre de Fichero" onChange={(e: React.ChangeEvent<any>) => {
                            onchangeV6(e.currentTarget.value);
                        }}></Input>
                        <Button onClick={async () => {
                            var endpoint = `excelGenerator/${this.state.cup}/${this.state.selectedTemplate}/full`;
                            console.log(endpoint);
                            var cups = endpoint.substr(endpoint.indexOf('/') + 1, endpoint.lastIndexOf('/') - (endpoint.indexOf('/') + 1));
                            var result = await ExcelService.DownloadEstimationWithError(endpoint, this.state.fileName);
                        }}>Descargar</Button>
                    </div>) : (<div>
                        <TextArea onChange={(e: React.ChangeEvent<any>) => {

                            onchangeCupsAryTemplate(e.currentTarget.value.split('\n'));
                        }}>
                        </TextArea>
                        <Button onClick={async () => {
                            for (var counter = 0; counter < this.state.cupsAryTemplate.length; counter++) {
                                let rowr = this.state.cupsAryTemplate[counter];
                                if (rowr == undefined || rowr.trim() == null)
                                    continue;
                                let row = rowr.split(';');
                                let cup = row[0];
                                let templatePartial = row[2];
                                let name = row[1];
                                var template = this.state.templates.find(p => p.name == templatePartial);
                                if (template == null) {
                                    continue;
                                }
                                var endpoint = `excelGenerator/${cup}/${template.id}/full`;
                                console.log(endpoint);
                                var cups = endpoint.substr(endpoint.indexOf('/') + 1, endpoint.lastIndexOf('/') - (endpoint.indexOf('/') + 1));
                                var result = await ExcelService.DownloadEstimationWithError(endpoint, cup + " " + name + " " + templatePartial + template.path.substr(template.path.lastIndexOf('.')));
                            }
                        }}>Descargar</Button>
                    </div>)}

                </div>
            )
        }
        const renderCup = () => {
            return (<FormItem

                validateStatus={
                    cups === "" ? "validating" : valida_cups(cups) ? "success" : "error"
                }
                label="CUPS"
            >
                <TextArea onChange={(e: React.ChangeEvent<any>) => {

                    onchangeV2(e.currentTarget.value.split('\n'));
                }}>
                </TextArea>

                <Button disabled={this.state.loading} onClick={async (e: any) => {
                    if (this.state.isCup) {

                        this.setState({ loading: true, infoAry: [] })
                        var data = this.state.infoAry;
                        for (var cupCounter = 0; cupCounter < this.state.cupsAry.length; cupCounter++) {

                            var newCupValue = this.state.cupsAry[cupCounter];
                            if (newCupValue.trim() != "") {


                                var endpoint = `excelGenerator/${newCupValue}/full`;
                                data.unshift('Obteniendo CUPS: ' + newCupValue);
                                this.setState({ infoAry: data });
                                var cups = endpoint.substr(endpoint.indexOf('/') + 1, endpoint.lastIndexOf('/') - (endpoint.indexOf('/') + 1));
                                var result = await ExcelService.DownloadEstimationWithError(endpoint, cups);
                                if (result) { data.unshift('Obtenido Satisfactoriamente ' + newCupValue); }
                                else
                                    data.unshift('No se ha podido Obtener el cups:' + newCupValue);
                                this.setState({ infoAry: data });
                            }
                        }
                        this.setState({ loading: false })
                    }
                    else {

                        var newCupValue = this.state.cupsAry[0];
                        if (newCupValue.trim() != '') {
                            var response = await ExcelService.GetByAddress("28047", newCupValue);
                            this.setState({ addressAry: response });
                        }
                    }


                }}> Buscar</Button>

                <div>
                    Resultados de la busqueda:
                    <hr />
                    {this.state.infoAry.map(p => {
                        return (<div>{p}</div>);
                    })}
                </div>
            </FormItem>);
        }



        const renderCupNemon = () => {
            return (<FormItem

                validateStatus={
                    cups === "" ? "validating" : valida_cups(cups) ? "success" : "error"
                }
                label="CUPS"
            >
                <h1>Con Nemon</h1>
                <TextArea onChange={(e: React.ChangeEvent<any>) => {

                    onchangeV2(e.currentTarget.value.split('\n'));
                }}>
                </TextArea>

                <Button disabled={this.state.loading} onClick={async (e: any) => {
                    debugger;

                        this.setState({ loading: true, infoAry: [] })
                        var data = this.state.infoAry;
                        for (var cupCounter = 0; cupCounter < this.state.cupsAry.length; cupCounter++) {

                            var newCupValue = this.state.cupsAry[cupCounter];
                            if (newCupValue.trim() != "") {


                                var endpoint = `excelGenerator/${newCupValue}/full-nemon`;
                                data.unshift('Obteniendo CUPS: ' + newCupValue);
                                this.setState({ infoAry: data });
                                var cups = endpoint.substr(endpoint.indexOf('/') + 1, endpoint.lastIndexOf('/') - (endpoint.indexOf('/') + 1));
                                var result = await ExcelService.DownloadEstimationWithError(endpoint, cups);
                                if (result) { data.unshift('Obtenido Satisfactoriamente ' + newCupValue); }
                                else
                                    data.unshift('No se ha podido Obtener el cups:' + newCupValue);
                                this.setState({ infoAry: data });
                            }
                        }
                        this.setState({ loading: false })


                }}> Buscar</Button>

                <div>
                    Resultados de la busqueda:
                    <hr />
                    {this.state.infoAry.map(p => {
                        return (<div>{p}</div>);
                    })}
                </div>
            </FormItem>);
        }

        const renderForTemplateNemon = () => {
            return (
                <div>
                    <h1>Con Nemon</h1>
                    <Switch defaultChecked onChange={onChangeSw} /> {this.state.text}
                    {this.state.individual == true ? (<div>
                        <Select defaultValue="default" style={{ width: 500 }} onChange={(value: string) => this.setState({ selectedTemplate: value })}>
                            <Option value="default">Elige una Plantilla</Option>
                            {this.state.templates.map(p => {
                                return (<Option value={p.id}>{p.name}</Option>);
                            })}
                        </Select>
                        <Input placeholder="CUPS" onChange={(e: React.ChangeEvent<any>) => {
                            onchangeV5(e.currentTarget.value);
                        }}></Input>
                        <Input placeholder="Nombre de Fichero" onChange={(e: React.ChangeEvent<any>) => {
                            onchangeV6(e.currentTarget.value);
                        }}></Input>
                        <Button onClick={async () => {
                            var endpoint = `excelGenerator/${this.state.cup}/${this.state.selectedTemplate}/full-nemon`;
                            console.log(endpoint);
                            var cups = endpoint.substr(endpoint.indexOf('/') + 1, endpoint.lastIndexOf('/') - (endpoint.indexOf('/') + 1));
                            var result = await ExcelService.DownloadEstimationWithError(endpoint, this.state.fileName);
                        }}>Descargar</Button>
                    </div>) : (<div>
                        <TextArea onChange={(e: React.ChangeEvent<any>) => {

                            onchangeCupsAryTemplate(e.currentTarget.value.split('\n'));
                        }}>
                        </TextArea>
                        <Button onClick={async () => {
                            for (var counter = 0; counter < this.state.cupsAryTemplate.length; counter++) {
                                let rowr = this.state.cupsAryTemplate[counter];
                                if (rowr == undefined || rowr.trim() == null)
                                    continue;
                                let row = rowr.split(';');
                                let cup = row[0];
                                let templatePartial = row[2];
                                let name = row[1];
                                var template = this.state.templates.find(p => p.name == templatePartial);
                                if (template == null) {
                                    continue;
                                }
                                var endpoint = `excelGenerator/${cup}/${template.id}/full`;
                                console.log(endpoint);
                                var cups = endpoint.substr(endpoint.indexOf('/') + 1, endpoint.lastIndexOf('/') - (endpoint.indexOf('/') + 1));
                                var result = await ExcelService.DownloadEstimationWithError(endpoint, cup + " " + name + " " + templatePartial + template.path.substr(template.path.lastIndexOf('.')));
                            }
                        }}>Descargar</Button>
                    </div>)}

                </div>
            )
        }


        const renderAddress = () => {
            return (<div>
                <Input placeholder="Codigo Postal" onChange={(e: React.ChangeEvent<any>) => {

                    onchangeV4(e.currentTarget.value);
                }}>
                </Input>
                <Input placeholder="Calle" onChange={(e: React.ChangeEvent<any>) => {

                    onchangeV3(e.currentTarget.value);
                }}>
                </Input>
                <Button disabled={this.state.loading} onClick={async (e: any) => {

                    var newCupValue = this.state.address;
                    if (newCupValue.trim() != '') {
                        var response = await ExcelService.GetByAddress(this.state.postalCode, newCupValue);
                        this.setState({ addressAry: response });
                    }



                }}> Buscar Calle</Button>
                <Button disabled={this.state.loading} onClick={async (e: any) => {


                    this.setState({ loading: true, infoAry: [] })
                    var data = this.state.infoAry;
                    for (var cupCounter = 0; cupCounter < this.state.addressToSearch.length; cupCounter++) {
                        var newCupValue = this.state.addressToSearch[cupCounter];
                        if (newCupValue.trim() != "") {


                            var endpoint = `excelGenerator/${newCupValue}/full`;
                            data.unshift('Obteniendo CUPS: ' + newCupValue);
                            this.setState({ infoAry: data });
                            var cups = endpoint.substr(endpoint.indexOf('/') + 1, endpoint.lastIndexOf('/') - (endpoint.indexOf('/') + 1));
                            var result = await ExcelService.DownloadEstimationWithError(endpoint, cups);
                            if (result) { data.unshift('Obtenido Satisfactoriamente ' + newCupValue); }
                            else
                                data.unshift('No se ha podido Obtener el cups:' + newCupValue);
                            this.setState({ infoAry: data });
                        }
                    }
                    this.setState({ loading: false })




                }}> Buscar</Button>
                <div>
                    Selecciona los cups a Buscar
                    <hr />
                    {
                        this.state.addressAry.length == 0 ? 'No hay resultados' : ''}

                    <ul>
                        {this.state.addressAry.map(p => {
                            return <div key={p.cups}><Checkbox value={p.cups} onChange={(o: any) => onCheckCups(o.target.value)} />{p.cups} ({p.address})</div>
                        }
                        )}
                    </ul>
                </div>

            </div>);
        }

        return (
            <PageLayout>


                <Tabs defaultActiveKey="1" onChange={(_: any) => TabCallback(_)}>
                    {/*<TabPane tab="Por Cup" key="1">*/}
                    {/*    {renderCup()}*/}
                    {/*</TabPane>*/}
                    <TabPane tab="Por Direccion" key="2">
                        {renderAddress()}
                    </TabPane>
                    {/*<TabPane tab="Por Template" key="3">*/}
                    {/*    {renderForTemplate()}*/}
                    {/*</TabPane>*/}
                    <TabPane tab="Por Cup - NEMON" key="4">
                        {renderCupNemon()}
                    </TabPane>
                    <TabPane tab="Por Template - Nemon" key="5">
                        {renderForTemplateNemon()}
                    </TabPane>
                </Tabs>

            </PageLayout>
        );
    }
}

export default connect(
    (state: ApplicationState) => state.rates,
    ExcelStore.actionCreators
)(Excel as any);
