import { IContract } from "./models";

const fmod = (a: any, b: number): any =>
  Number((a - Math.floor(a / b) * b).toPrecision(8));

const valida_cups = (CUPS: string): boolean => {
  var RegExPattern = /^ES[0-9]{16}[a-zA-Z]{2}[0-9]{0,1}[a-zA-Z]{0,1}$/;

  if (CUPS != "" && CUPS.match(RegExPattern)) {
    var CUPS_16 = CUPS.substr(2, 16);
    var control = CUPS.substr(18, 2);
    var letters = Array(
      "T",
      "R",
      "W",
      "A",
      "G",
      "M",
      "Y",
      "F",
      "P",
      "D",
      "X",
      "B",
      "N",
      "J",
      "Z",
      "S",
      "Q",
      "V",
      "H",
      "L",
      "C",
      "K",
      "E"
    );

    var fmodv = fmod(CUPS_16, 529);
    var imod = parseInt(fmodv);
    var quotient = Math.floor(imod / 23);
    var remainder = imod % 23;

    var dc1 = letters[quotient];
    var dc2 = letters[remainder];

    return control == dc1 + dc2;
  }

  return false;
};

const remove_empty = (obj: any) => {
  const newObj: any = {};

  Object.entries(obj).forEach(([key, val]) => {
    if (val && typeof val === "object") newObj[key] = remove_empty(val);
    else if (val != null) newObj[key] = val;
  });

  return newObj;
};

const contract_clear = (entity: IContract): IContract => ({
  ...entity,
  customer: {
    ...entity.customer,
    id: entity.customerId,
    billing: {
      ...entity.customer.billing,
      id: entity.customer.billingId || entity.customer.billing.id
    },
    personal: {
      ...entity.customer.personal,
      id: entity.customer.personalId || entity.customer.personal.id
    }
  }
});

const filterObjects = (object: Object, value: boolean) =>
    
    value == false ?
        Object.entries(object).filter(
            ([key, val]) => typeof val !== "object" && !/id$/i.test(key)
                && !/existsError/i.test(key)
                && !/Total Compa�ia Actual/i.test(key)
                && !/Ahorro Factura/i.test(key)
                && !/Ahorro Anual/i.test(key)
                && !/Potencia Facturada/i.test(key)
                && !/Impuesto Electrico/i.test(key)
                && !/IVA/i.test(key)
                && !/SubTotal/i.test(key)
                && !/average1/i.test(key)
                && !/average2/i.test(key)
                && !/average3/i.test(key)
                && !/Otros Servicios/i.test(key)
                && !/Total/i.test(key)
                && !/promotiona/i.test(key)

                && !/Alquiler de Equipos/i.test(key)
                && !/rate/i.test(key) && (!/Otros/g.test(key) || /Otros Servicios/g.test(key))
                && !/cost/i.test(key) && !/monthly/i.test(key) && !/totalLastYear/i.test(key)
                && !/Potencia P1/i.test(key) && !/Potencia P2/i.test(key) && !/Potencia P3/i.test(key))
        : Object.entries(object).filter(
      ([key, val]) => typeof val !== "object" && !/id$/i.test(key) && !/existsError/i.test(key) && !/rate/i.test(key) && (!/Otros/g.test(key) || /Otros Servicios/g.test(key))
          && !/cost/i.test(key) && !/monthly/i.test(key)   && !/totalLastYear/i.test(key) && !/promotiona/i.test(key)
          && !/Potencia P1/i.test(key) && !/Potencia P2/i.test(key)
          //&& !/average1/i.test(key) && !/average2/i.test(key) && !/average3/i.test(key)
    );
const filterEditableObjectsDHA = (object: Object) =>
    Object.entries(object).filter(
        ([key, val]) => (/Potencia P1/i.test(key) || /Potencia P2/i.test(key) && !/Potencia P2_/i.test(key))
    );
const filterEditableObjectsA = (object: Object) =>
    Object.entries(object).filter(
        ([key, val]) => /Potencia P2/i.test(key) 
    );
const filterObjectsOtra = (object: Object) =>
    Object.entries(object).filter(
        ([key, val]) => typeof val !== "object" && !/id$/i.test(key) && !/existsError/i.test(key) && !/rate/i.test(key) && (/Otros/g.test(key) && !(/Otros Servicios/g.test(key)))
    );
export { valida_cups, remove_empty, contract_clear, filterObjects, filterObjectsOtra, filterEditableObjectsDHA, filterEditableObjectsA};
