import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { WizardService } from "../../services";
import { ApplicationState } from "../../store";

interface IProps {
    id: number;
    showLogalty: boolean;
}

const Fourd = (props: IProps) => {
  const [html, setHmtl] = useState("");

  useEffect(() => {
    const fetchHtml = async () => {
      let response = "";
      try {
        response = await WizardService.GetHtml(props.id);
      } catch (error) {
        console.log(error);
      }

      setHmtl(response);
    };

    fetchHtml();
  }, []);

  return (
      <>
      <div
        style={{ backgroundColor: "white", padding: "20px" }}
        dangerouslySetInnerHTML={{ __html: html }}
      />
    </>
  );
};

export default connect((state: ApplicationState) => ({ id: state.wizard?.id }))(
  Fourd as any
);
