import React from "react";
import { connect } from "react-redux";
import { Button, Layout, Form, Input, Icon } from "antd";
import { FormComponentProps } from "antd/lib/form";
import { RouteComponentProps, Redirect } from "react-router";

import { ApplicationState } from "../store";
import * as AuthStore from "../store/Auth";
import { injectIntl, IntlShape } from "react-intl";
const { Content } = Layout;

type IProps = AuthStore.AuthState &
  typeof AuthStore.actionCreators &
  FormComponentProps &
  RouteComponentProps & {
    intl: IntlShape;
  };

const SignIn = (props: IProps) => {
  const { form, isLoading, isLogged, requestSignIn, intl } = props;
  const { getFieldDecorator, getFieldValue } = form;
  const pathname = props.location?.state?.from?.pathname;

  return pathname && isLogged && !isLoading ? (
    <Redirect to={pathname} />
  ) : (
    <Layout style={{ minHeight: "100vh" }}>
      <Content style={{ display: "flex", flexDirection: "column" }}>
        <img
          style={{
            margin: "20px auto"
          }}
          src={require("../bg.png")}
        />
        <Form
          onSubmit={e => {
            e.preventDefault();
            requestSignIn(getFieldValue("email"), getFieldValue("password"));
          }}
          style={{
            margin: "20px auto"
          }}
        >
          <Form.Item>
            {getFieldDecorator("email", {
              rules: [{ required: true, message: "Please input your email!" }]
            })(
              <Input
                prefix={
                  <Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />
                }
                placeholder={intl.messages["email"] as string}
              />
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator("password", {
              rules: [
                { required: true, message: "Please input your Password!" }
              ]
            })(
              <Input
                prefix={
                  <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                }
                type="password"
                placeholder={intl.messages["password"] as string}
              />
            )}
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              loading={isLoading}
            >
              Log in
            </Button>
          </Form.Item>
                  </Form>

 
      </Content>
    </Layout>
  );
};

export default connect(
  (state: ApplicationState) => state.auth,
  AuthStore.actionCreators
)(
  Form.create<IProps>({ name: "normal_login" })(injectIntl(SignIn)) as any
);
