import React, { useEffect } from "react";
import { Redirect, RouteComponentProps } from "react-router";
import { connect } from "react-redux";

import { ApplicationState } from "../store";
import { AuthState } from "../store/Auth";
import * as AuthStore from "../store/Auth";

type IProps = AuthState & typeof AuthStore.actionCreators & RouteComponentProps;

const IsLogged = (WrappedComponent: any) => {
  const Logged = (props: IProps) => {
    const { checkAuth, isLogged, isLoading, location } = props;

    useEffect(() => {
      checkAuth();
    })

    return isLogged && !isLoading ? (
      <WrappedComponent {...props} />
    ) : (
      <Redirect
        to={{
          pathname: "/signin",
          state: { from: location }
        }}
      />
    );
  };

  return connect(
    (state: ApplicationState) => state.auth,
    AuthStore.actionCreators
  )(Logged as any);
};

export default IsLogged;
