import { Reducer, Action } from "redux";
import { updateIntl } from "react-intl-redux";
import { push } from "connected-react-router";
import jwtDecode from "jwt-decode";

import { AppThunkAction } from "./";
import { AuthService } from "../services";
import localization from "../localization";
import { IToken } from "../models";

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface AuthState {
    isLoading: boolean;
    isLogged: boolean;
    isAdmin: boolean;
    isCallCenterAdmin: boolean;
    isBackOffice: boolean;
    name: string;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface RequestSignInAction {
    type: "REQUEST_SIGNIN";
}

interface SignInFinishedAction {
    type: "SIGNIN_FINISED";
    isLogged: boolean;
    isAdmin: boolean;
    isBackOffice: boolean;
    isCallCenterAdmin: boolean;
    name: string;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = RequestSignInAction | SignInFinishedAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    checkAuth: (): AppThunkAction<KnownAction | Action> => (
        dispatch,
        getState
    ) => {
        const token = localStorage.getItem("jwt");
        let isAdmin = false;
        let isBackOffice = false;
        let isCallCenterAdmin = false;

        let name = "";
        let expired = true;

        if (token) {
            const tokenDecoded = jwtDecode<IToken>(token);
            isAdmin =
                tokenDecoded[
                    "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
                ]?.toLocaleUpperCase() === "ADMIN";
            isBackOffice =
                tokenDecoded[
                    "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
                ]?.toLocaleUpperCase() === "BACKOFFICE";
            isCallCenterAdmin = tokenDecoded[
                "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
            ]?.toLocaleUpperCase() === "CALLCENTERADMIN";
            name =
                tokenDecoded[
                "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"
                ];
            expired = tokenDecoded.exp < Date.now() / 1000;
        }

        dispatch({
            type: "SIGNIN_FINISED",
            isLogged: !expired,
            isAdmin,
            isCallCenterAdmin,
            name, isBackOffice
        });
    },
    requestSignIn: (
        email: string,
        password: string
    ): AppThunkAction<KnownAction | Action> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
        if (!appState?.auth?.isLogged) {
            AuthService.SignIn(email, password)
                .then(({ token }) => {
                    localStorage.setItem("jwt", token);
                    let isBackOffice = false;
                    isBackOffice =
                        jwtDecode<IToken>(token)[
                            "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
                        ]?.toLocaleUpperCase() === "BACKOFFICE";
                    const isAdmin =
                        jwtDecode<IToken>(token)[
                            "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
                        ].toLocaleUpperCase() === "ADMIN";
                    const isCallCenterAdmin =
                        jwtDecode<IToken>(token)[
                            "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
                        ].toLocaleUpperCase() === "CALLCENTERADMIN";
                    const name = jwtDecode<IToken>(token)[
                        "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"
                    ];
                    dispatch({
                        type: "SIGNIN_FINISED",
                        isLogged: !!token,
                        isAdmin,
                        isCallCenterAdmin,
                        name,
                        isBackOffice
                    });
                    dispatch(push("/contracts"));
                })
                .catch((e) => {
                    dispatch({ type: "SIGNIN_FINISED", isLogged: false, isAdmin: false, isBackOffice:false });
                });

            dispatch({ type: "REQUEST_SIGNIN" });
        }
    },
    changeLenguage: (locale: string): AppThunkAction<KnownAction | Action> => (
        dispatch,
        getState
    ) => {
        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
        if (appState?.intl?.locale !== locale) {
            dispatch(
                updateIntl({
                    locale,
                    messages: localization[locale],
                })
            );
            localStorage.setItem("locale", locale);
        }
    },
    signOut: (): AppThunkAction<KnownAction | Action> => (dispatch, getState) => {
        localStorage.removeItem("jwt");
        dispatch({
            type: "SIGNIN_FINISED",
            isLogged: false,
            isAdmin: false,
            isCallCenterAdmin: false,
            name: "",
        });
        dispatch(push("/signin"));
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: AuthState = {
    isLogged: false,
    isLoading: false,
    isAdmin: false,
    isBackOffice:false,
    isCallCenterAdmin: false,
    name: "",
};

export const reducer: Reducer<AuthState> = (
    state: AuthState | undefined,
    action: KnownAction
): AuthState => {
    if (state === undefined) {
        return unloadedState;
    }

    switch (action.type) {
        case "REQUEST_SIGNIN":
            return {
                ...state,
                isLoading: true,
            };
        case "SIGNIN_FINISED":
            return {
                ...state,
                isLoading: false,
                isLogged: action.isLogged,
                isAdmin: action.isAdmin,
                isCallCenterAdmin: action.isCallCenterAdmin,
                isBackOffice: action.isBackOffice,
                name: action.name || "",
            };
        default:
            return state;
    }
};
