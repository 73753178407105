import React, { useEffect, PureComponent, useState } from "react";
import { connect } from "react-redux";
import { Table, Button } from "antd";
import { FormattedMessage } from "react-intl";
import { RouteComponentProps } from "react-router";
import { UserModel, TemplateModal, } from "../components";

import { ApplicationState } from "../store";
import { PageLayout } from "../containers";
import * as ExcelStore from "../store/Excel";
import { IUser, ITemplate } from "../models";

type IProps = RouteComponentProps &
    ExcelStore.ExcelState &
    typeof ExcelStore.actionCreators;

interface IState {
    showModal: boolean;
    template?: ITemplate;
}

const Templates = (props: IProps) => {
    const { templates, isLoading, requestTemplates, showPopUp, opened,template,setTemplate,deleteTemplate } = props;
    useEffect(() => {
        requestTemplates();
    }, []);
    const handleOk = () => {
        
        return showPopUp()
    }
    const changeModalState = () => {
        return showPopUp()
    }
    return (
        <PageLayout>
            <TemplateModal
                visible={opened}
                onOk={handleOk}
                onCancel={changeModalState}
                template={template}
            />
            <div>
                <form>
                    <input type="file" id="ffile" />
                    <input type="text" id="fname" />
                    <input type="button" value="Enviar" onClick={() => {
                        var formData = new FormData();
                        formData.append("file", (document.getElementById("ffile") as any).files[0]);
                        formData.append("name", (document.getElementById("fname") as any).value);
                        fetch('/template', {
                            method: 'POST',
                            body: formData,

                        }).then((d) => {
                            requestTemplates();
                        })
                        return false;
                    }} />
                </form>
                </div>
          
            <Table<ITemplate>
                rowKey={(record: ITemplate) => record.id}
                dataSource={templates}
                loading={isLoading}
                pagination={false}
            >
                <Table.Column<ITemplate>
                    key="name"
                    title={"Nombre"}
                    dataIndex="name"
                />
                <Table.Column<ITemplate>
                    key="path"
                    title={"Ruta"}
                    dataIndex="path"
                />
                <Table.Column<ITemplate>
                    key="printPdf"
                    title="Acciones"
                    dataIndex="id"
                    render={(_, s) => (
                        <div>

                            <Button onClick={() => { deleteTemplate(s); setTimeout(()=>requestTemplates(),1500) }}>
                                Eliminar
                        </Button>
                            </div>
                    )}
                />
            </Table>
        </PageLayout>);
}

//class Templates extends PureComponent<IProps, IState> {

//    constructor(props: IProps) {
//        super(props);

//        this.state = {
//            showModal: false
//        };
//    }

//    handleOk = (user: IUser) => {
//        //this.props.createUser(user);
//        //if (user.password != user.password2) {

//        //} else {
//        //    this.setState({
//        //        showModal: false
//        //    });
//        //    this.props.requestTemplates();
//        //}
//    };
//    changeModalState = (user?: IUser) => {
//        this.setState({
//            showModal: !this.state.showModal,
//            user
//        });
//    };
//    render() {

//        const { templates, isLoading, requestTemplates } = this.props;
//        const { user, showModal } = this.state;
//        useEffect(() => {
//            requestTemplates();
//        }, []);
//        return (
//            <PageLayout>
//                <UserModel
//                    visible={showModal}
//                    onOk={this.handleOk}
//                    onCancel={this.changeModalState}
//                    user={user}
//                />
//                <Button
//                    type="primary"
//                    onClick={() => this.changeModalState()}
//                    style={{ marginBottom: "10px" }}
//                >
//                    Agregar Plantilla
//                </Button>
//                <Table<ITemplate>
//                    rowKey={(record: ITemplate) => record.id}
//                    dataSource={templates}
//                    loading={isLoading}
//                    pagination={false}
//                >
//                    <Table.Column<ITemplate>
//                        key="name"
//                        title={<FormattedMessage id="userName" defaultMessage="Name" />}
//                        dataIndex="name"
//                    />
                   
//                </Table>
//            </PageLayout>);
//    }
   
//};

export default connect(
    (state: ApplicationState) => state.excel,
    ExcelStore.actionCreators
)(Templates as any);
