import { Reducer, Action } from "redux";
import { replace, push } from "connected-react-router";
import merge from "deepmerge";
import { isValid as IsIbanValid } from "iban";
import { notification } from "antd";

import { AppThunkAction } from "./";
import { WizardService, CupsService } from "../services";
import { IContract, IRate, IEstimation, ICustomer, IResult } from "../models";
import { remove_empty, valida_cups, contract_clear } from "../helpers";

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface WizardState extends IContract {
    isLoading: boolean;
    sendCertified: boolean;
    logaltyStatus: IResult;
    isLoadingCups: boolean;
    isLoadingFinish: boolean;
    isSameBilling: boolean;
    checkedRate: boolean;
    isValid: boolean[];
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface RequestWizardAction {
    type: "REQUEST_WIZARD";
    direction?: number;
}
interface RequestStatusAction {
    type: "REQUEST_STATUS";
    id?: number;
}
interface ChangeWizardStepAction {
    type: "CHANGE_WIZARD_STEP";
    direction: number;
}

interface WizardLoadedAction {
    type: "WIZARD_LOADED";
    data: IContract;
    clean: boolean;
}

interface WizardLoadingErrorAction {
    type: "WIZARD_LOADING_ERROR";
}

interface WizardSetValidAction {
    type: "WIZARD_SET_VALID";
    valid: boolean;
}
interface WizardSetCertified {
    type: "WIZARD_SET_CERTIFIED";
    value: boolean;
}
interface WizardSetStatus {
    type: "WIZARD_SET_STATUS";
    value: IResult;
}
interface WizardSetPower {
    type: "WIZARD_SET_POWER";
    p1: number;
    p2: number;
}
interface WizardRequestCupsAction {
    type: "WIZARD_REQUEST_CUPS";
}

interface WizardSetCupsAction {
    type: "WIZARD_SET_CUPS";
    cups: string;
}

interface WizardSetEstimationAction {
    type: "WIZARD_SET_ESTIMATION";
    estimation: IEstimation;
}

interface WizardSetRateAction {
    type: "WIZARD_SET_RATE";
    rate: IRate;
}

interface WizardSetisSameBillingAction {
    type: "WIZARD_SET_ISSAMEADDRESSBILLING";
}

interface FinishWizardAction {
    type: "FINISH_WIZARD";
}

interface WizardFinishedAction {
    type: "WIZARD_FINISHED";
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction =
    | ChangeWizardStepAction
    | RequestWizardAction
    | RequestStatusAction
    | WizardLoadedAction
    | WizardLoadingErrorAction
    | WizardSetValidAction
    | WizardRequestCupsAction
    | WizardSetCupsAction
    | WizardSetPower
    | WizardSetRateAction
    | WizardSetEstimationAction
    | WizardSetisSameBillingAction
    | FinishWizardAction
    | WizardSetCertified
    | WizardSetStatus
    | WizardFinishedAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).
var validDNI = function (dni: string, isPerson: number) {
    if (isPerson == 1)
        return true;
    var DNI_REGEX = /^(\d{8})([A-Z])$/;
    var NIE_REGEX = /^[XYZ]\d{7,8}[A-Z]$/;
    var str = dni.trim();
    str = str.toUpperCase().replace(/\s/, '');
    if (str.match(DNI_REGEX)) {
        var dni_letters = "TRWAGMYFPDXBNJZSQVHLCKE";
        var letter = dni_letters.charAt(parseInt(dni, 10) % 23);

        return letter == dni.charAt(8);
    }
    else if (str.match(NIE_REGEX)) {
        var nie_prefix = dni.charAt(0);
        var prefix = 0;
        switch (nie_prefix) {
            case 'X': prefix = 0; break;
            case 'Y': prefix = 1; break;
            case 'Z': prefix = 2; break;
        }
        var dni_letters = "TRWAGMYFPDXBNJZSQVHLCKE";
        var letter = dni_letters.charAt(parseInt(prefix.toString() + dni.substr(1), 10) % 23);

        return letter == dni.charAt(8);
    };
    return false;
}
const validateWizard = (customer: ICustomer): boolean => {
    const { iban, personal, billing } = customer;
    var entries = Object.entries(customer);

    var ibanValidated = IsIbanValid(iban);
    if (customer.invoiceKind != 0) {
        entries = entries.filter(([key, val]) => typeof key !== "object" && key !== "email")
    }
    if (customer.billingKind != 0) {
        ibanValidated = true;
        entries = entries.filter(([key, val]) => typeof key !== "object" && key !== "iban")
    }
    if (!customer.isPerson) {
        entries = entries.filter(([key, val]) => typeof key !== "object" && key !== "socialReason" && key !== "representName")
    } else {
        entries = entries.filter(([key, val]) => typeof key !== "object" && key !== "name" && key !== "lastName" && key !== "lastName2")
    }

    return (
        entries.some(p => p[1] !== null) &&
        ibanValidated &&
        validDNI(customer.nif, customer.isPerson) &&
        !Object.values(personal).some(x => !x) &&
        !Object.values(billing).some(x => !x)
    );
};

export const actionCreators = {
    setLogaltyStatus: (value: string): AppThunkAction<KnownAction | Action> => (
        dispatch
    ) => {
        WizardService.Confirm(value).then(d => {  });
    },
    getLogaltyStatus: (value: number): AppThunkAction<KnownAction | Action> => (
        dispatch
    ) => {
        WizardService.GetStatus(value).then(d => { dispatch({ type: "WIZARD_SET_STATUS", value:d }); });
    },
    clean: (value: number): AppThunkAction<KnownAction | Action> => (
        dispatch
    ) => {
        WizardService.Clean(value).then(d => { });
    },
    setIsCertified: (value:boolean): AppThunkAction<KnownAction | Action> => (
        dispatch
    ) => {

        dispatch({ type: "WIZARD_SET_CERTIFIED", value });

    },
    checkRate: (): AppThunkAction<KnownAction | Action> => (
        dispatch,
        getState
    ) => {
        const state = getState().wizard;
        return false;
    },
    downloadContract: (): AppThunkAction<KnownAction | Action> => (
        dispatch,
        getState
    ) => {
        const state = getState().wizard;
        if (state != undefined)
            WizardService.GetPdf(state?.id, state?.contractIdentifier, state?.callCenterIdentifier).then(() => { });
    },
    getShowComparison: (): AppThunkAction<KnownAction | Action> => (
        dispatch,
        getState
    ) => {
        const state = getState().wizard;
        return state?.showComparison;
    },
    isAdmin: (): AppThunkAction<KnownAction | Action> => (
        dispatch,
        getState
    ) => {
        const state = getState().auth;
        if (state == undefined || state == null) return false;
        return state.isAdmin;
    },
    requestWizardInfo: (id?: number): AppThunkAction<KnownAction | Action> => (
        dispatch,
        getState
    ) => {
        if (!id)
            WizardService.Start()
                .then(data => {
                    dispatch({ type: "WIZARD_LOADED", data, clean: true });
                    dispatch(replace(`/wizards/${data.id}`));
                })
                .catch(e => {
                    dispatch({ type: "WIZARD_LOADING_ERROR" });
                });
        else
            WizardService.Get(id)
                .then(data => {
                    dispatch({ type: "WIZARD_LOADED", data, clean: true });
                    dispatch(replace(`/wizards/${data.id}`));
                })
                .catch(e => {
                    if (e.message == 404) dispatch(replace(`/contracts`));
                    else dispatch({ type: "WIZARD_LOADING_ERROR" });
                });

        dispatch({ type: "REQUEST_WIZARD" });
    },
    getInfoCups: (cups: string): AppThunkAction<KnownAction | Action> => (
        dispatch,
        getState
    ) => {
        if (valida_cups(cups)) {
            const state = getState().wizard;

            const estimationId = getState().wizard?.estimationId!;
            const id = getState().wizard?.id;
            dispatch({ type: "WIZARD_REQUEST_CUPS" });

            CupsService.Get(cups, id)
                .then(estimation => {
                    dispatch({ type: "WIZARD_SET_CUPS", cups });
                    dispatch({ type: "WIZARD_SET_ESTIMATION", estimation });
                    dispatch({ type: "WIZARD_SET_VALID", valid: true });
                    dispatch({ type: "WIZARD_SET_POWER", p1: estimation["Potencia P1"], p2: estimation["Potencia P2"] });
                    WizardService.EditField(state.id, "potencia1", estimation["Potencia P1"]).then(a =>
                        console.log('a')
                    )
                    WizardService.EditField(state.id, "potencia2", estimation["Potencia P2"]).then(a =>
                        console.log('a')
                    )
                    if (estimation.rate && estimation.rateInfo)
                        dispatch({ type: "WIZARD_SET_RATE", rate: estimation.rateInfo });
                })
                .catch(() => {
                    dispatch({ type: "WIZARD_SET_CUPS" });
                    dispatch({ type: "WIZARD_SET_VALID", valid: false });
                });
        } else dispatch({ type: "WIZARD_SET_VALID", valid: false });
    },
    selectRate: (idRate: string): AppThunkAction<KnownAction | Action> => (
        dispatch,
        getState
    ) => {
        //TODO
        const rate = getState().rates?.list.find(c => c.id === idRate);
        dispatch({ type: "WIZARD_SET_RATE", rate });
        dispatch({ type: "WIZARD_SET_VALID", valid: !!idRate });
        const state = getState().wizard;
        if (!state) return;
        //let estimation;
        //estimation = state.estimation;
        //estimation.rateInfo = rate!;
        var list = document.querySelectorAll('.comparativa tbody tr td')
        var arr = Array.from(list);
        var found =  arr.filter(a => (a as any).innerText.indexOf('DHA') > 0)
        //if (found.length == 0 && estimation.rateInfo.description.indexOf('eMove') > 0) {

        //    estimation["Potencia P1"] = estimation["Potencia P2"]
        //    estimation["Potencia P2"] = estimation["Potencia P2"]
        //}
        //TODO: Change to EditField
        WizardService.Edit(contract_clear({ ...state, excelGenerated: found.length==0  }))
            .then(data => {
                dispatch({ type: "WIZARD_LOADED", data });
            })
            .catch(e => { })
            .finally(() => { });
    },

    setSameAddressBilling: (): AppThunkAction<KnownAction | Action> => (
        dispatch,
        getState
    ) => {
        dispatch({ type: "WIZARD_SET_ISSAMEADDRESSBILLING" });
        const state = getState().wizard;

        if (!state) return;

        let customer = { ...state.customer };
        const { personal, billing } = customer;
        if (state.isSameBilling)
            customer = {
                ...customer,
                billing: { ...personal, id: billing.id }
            };

        dispatch({
            type: "WIZARD_SET_VALID",
            valid: validateWizard(state?.customer)
        });

        WizardService.Edit(contract_clear({ ...state, customer }))
            .then(data => {
                dispatch({ type: "WIZARD_LOADED", data });
            })
            .catch(e => { })
            .finally(() => { });
    },
    updateWizardEstimation: (
        propName: string,
        propValue: string | number | boolean
    ): AppThunkAction<KnownAction | Action> => (dispatch, getState) => {
        const state = getState().wizard;
        if (!state) return;
        console.log(propName);
        alert(propName);
        WizardService.Edit(contract_clear({ ...state, [propName]: parseFloat(propValue as string) }))
            .then(data => {
                dispatch({ type: "WIZARD_LOADED", data });
            })
            .catch(e => { })
            .finally(() => { });

        },
    updateWizardRate: (
        propName: string,
        propValue: string | number | boolean
    ): AppThunkAction<KnownAction | Action> => (dispatch, getState) => {
        debugger;
        const state = getState().wizard;
        if (!state) return;
        WizardService.EditField(state.id, propName, propValue).then(a => { }

            )
        },
    
    updateWizard: (
        propName: string,
        propValue: string | number | boolean
    ): AppThunkAction<KnownAction | Action> => (dispatch, getState) => {
        const state = getState().wizard;
        if (!state) return;

        let customer = state.customer;
        var potencia1 = state.potencia1;
        var potencia2 = state.potencia2;
        if (propName.includes(".")) {
            var nameSplit = propName.split(".");
            const prop = (state.customer as any)[nameSplit[0]];
            // @ts-ignore
            customer = {
                ...state.customer,
                [nameSplit[0]]: { ...prop, [nameSplit[1]]: propValue }
            };
        } else if (propName == "potencia1") {
            potencia1 = propValue as number;
        } else if (propName == "potencia2") {
            potencia2 = propValue as number;
            } 
        else customer = { ...state.customer, [propName]: propValue };

        const { personal, billing } = customer;

        if (state.isSameBilling)
            customer = { ...customer, billing: { ...personal, id: billing.id } };

        dispatch({ type: "WIZARD_SET_VALID", valid: validateWizard(customer) });
        //WizardService.EditField(state.id, propName, propValue).then(a =>
        //    alert('Hola')
        //    )
        WizardService.Edit(contract_clear({ ...state, customer }))
            .then(data => {
                dispatch({ type: "WIZARD_LOADED", data });
            })
            .catch(e => { })
            .finally(() => { });
    },
    changePage: (direction: 1 | -1): AppThunkAction<KnownAction | Action> => (
        dispatch,
        getState
    ) => {
        dispatch({ type: "CHANGE_WIZARD_STEP", direction });
        const state = getState().wizard;

        if (state)
            WizardService.Edit(contract_clear(state))
                .then(data => {
                    dispatch({ type: "WIZARD_LOADED", data });
                })
                .catch(e => {
                    dispatch({ type: "WIZARD_LOADING_ERROR" });
                });

        dispatch({ type: "REQUEST_WIZARD" });
    },
    finishWizard: (): AppThunkAction<KnownAction | Action> => (
        dispatch,
        getState
    ) => {
        const state = getState().wizard;
        dispatch({ type: "WIZARD_SET_VALID", valid: false });

        if (state && validateWizard(state.customer))
            //TODO change to editfield
            WizardService.Edit(state)
                .then(data => {
                    data.sendCertified = state.sendCertified;
                    if ((state.stepId != 4)) {
                        WizardService.Complete(data)
                            .then(() => {
                                //notification.success({
                                //    message: "",
                                //    description: "Contrato Enviado."
                                //});
                                //setTimeout(() => {
                                //    dispatch(push(`/contracts`));
                                //}, 3000);
                                dispatch({ type: "CHANGE_WIZARD_STEP", direction: 1 });
                            })
                            .catch(() => {
                                notification.error({
                                    message: "Error",
                                    description: "Ha ocurrido un problema en el servidor."
                                });
                            })
                            .finally(() => {
                                dispatch({ type: "WIZARD_SET_VALID", valid: true });

                            });}
                    else {
                                                        notification.success({
                                    message: "",
                                    description: "Contrato finalizado."
                                });
                         setTimeout(() => {
                                    dispatch(push(`/contracts`));
                                }, 3000);
                    }
                   
                })
                .catch(e => { });

        dispatch({ type: "FINISH_WIZARD" });
    }
};

// TODO: Split into wizard and contract state for better mant
// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: WizardState = {
    logaltyId:"",
    isLoading: false,
    selected: false,
    date: "",
    agentName: "",
    sendCertified: true,
    isLoadingCups: false,
    isLoadingFinish: false,
    callCenterIdentifier: 0,
    callCenterName: '',
    showComparison: true,
    logaltyStatus: { finished: false, status: '', result:0,reason:'' , cancelCode:''},
    isSameBilling: true,
    isValid: [false, false, false, true],
    checkedRate:false,
    id: 0,
    stepId: 0,
    contractIdentifier: "",
    language: 1,
    cups: "",
    status: 0,
    excelGenerated: false,
    rateId: "",
    estimationId: 0,
    incidentId: null,
    potencia1: 0,
    potencia2: 0,
       customerId: "",
    customer: {
        isPromotional: false,
        promotiona: '',
        isPerson: 0,
        socialReason: "",
        representName: "",
        id: "",
        name: "",
        lastName: "",
        lastName2: "",
        mobilePhone: "",
        titularChange: 0,
        phone: "",
        loyaltyId: 0,
        billingKind: 0,
        invoiceKind: 0,
        email: "",
        iban: "",

        nif: "",
        language: 0,
        residenceKind: 0,
        newKind: 0,
        personal: {
            id: "",
            postalCode: "",
            province: "",
            city: "",
            street: "",
            number: "",
            complement: ""
        },
        billing: {
            id: "",
            postalCode: "",
            province: "",
            city: "",
            number: "",
            street: "",
            complement: ""
        }
    }
};

export const reducer: Reducer<WizardState> = (
    state: WizardState | undefined,
    action: KnownAction
): WizardState => {
    if (state === undefined) {
        return unloadedState;
    }

    switch (action.type) {
        case "CHANGE_WIZARD_STEP":
            return {
                ...state,
                stepId: action.direction
                    ? state.stepId + action.direction
                    : state.stepId
            };
        case "REQUEST_WIZARD":
            return {
                ...state,
                isLoading: true
            };
        case "WIZARD_LOADED":
            debugger;
            return merge(
                {
                    ...(action.clean ? unloadedState : state),
                    isLoading: false,
                    isValid: [
                        !!action.data.cups,
                        !!action.data.rateId,
                        validateWizard(action.data.customer),
                        true
                    ],
                    potencia1: action.data.potencia1,
                    potencia2: action.data.potencia2,
                },
                remove_empty(action.data)
            );
        case "WIZARD_LOADING_ERROR":
            return {
                ...state,
                isLoading: false
            };
        case "WIZARD_SET_VALID":
            return {
                ...state,
                isValid: state.isValid.map((c, i) =>
                    i === state.stepId ? action.valid : c
                )
            };
        case "WIZARD_SET_CERTIFIED":
            return {
                ...state,
                sendCertified: action.value
            };
        case "WIZARD_SET_STATUS":
            return {
                ...state,
                logaltyStatus: action.value
            };
        case "WIZARD_SET_POWER":
            return {
                ...state,
                potencia1: action.p1,
                potencia2: action.p2
            };
        case "WIZARD_REQUEST_CUPS":
            return {
                ...state,
                isLoadingCups: true
            };
        case "WIZARD_SET_CUPS":
            return {
                ...state,
                cups: action.cups || state.cups,
                isLoadingCups: false
            };
        case "WIZARD_SET_ESTIMATION":
            return {
                ...state,
               
            };
        case "WIZARD_SET_RATE":
            return {
                ...state,
                rateId: action.rate.id,
                rate: { ...action.rate }
            };
        case "WIZARD_SET_ISSAMEADDRESSBILLING":
            return {
                ...state,
                isSameBilling: !state.isSameBilling
            };
        case "FINISH_WIZARD":
            return {
                ...state,
                isLoadingFinish: false,
                isLoading:true
            };
        case "WIZARD_FINISHED":
            return {
                ...state,
                isLoadingFinish: false,
                isLoading:false

            };
        default:
            return state;
    }
};