import React, { useEffect, useState } from "react";
import { Steps, Button, notification, Checkbox  } from "antd";
import { RouteComponentProps } from "react-router";
import { connect, DispatchProp } from "react-redux";
import { FormattedMessage } from "react-intl";

import {
  PageLayout,
  First,
  Second,
  Third,
    Fourd,
    Fifth,
    CusomterEmptyFields

} from "../containers";
import * as WizardStore from "../store/Wizard";
import { ApplicationState } from "../store";

const { Step } = Steps;
const steps = [
  {
    title: <FormattedMessage id="first5" defaultMessage="CUPS" />,
    content: First
  },
  {
    title: <FormattedMessage id="second4" defaultMessage="Tarifa" />,
    content: Second
  },
  {
    title: (
      <FormattedMessage id="sendContract2" defaultMessage="Datos Personales" />
    ),
    content: Third
  },
  {
    title: <FormattedMessage id="last11" defaultMessage="Previsualizacion" />,
    content: Fourd
    }
    ,
    {
        title: <FormattedMessage id="last33" defaultMessage="Estado" />,
        content: Fifth
    }
];

type IProps = typeof WizardStore.actionCreators &
  RouteComponentProps &
  DispatchProp & {
    stepId: number;
    isValid: boolean[];
    isLoadingFinish: boolean;
    checkedRate: boolean ;
    
  };

const Wizard = (props: IProps) => {
    const {
        checkedRate,
    stepId,
    changePage,
    requestWizardInfo,
    isValid,
    finishWizard,
      isLoadingFinish,
      downloadContract,
      isAdmin,
      setIsCertified
  } = props;

  useEffect(() => {
    const { id } = props.match.params as any;

    requestWizardInfo(id);
  }, []);
    const [disabled, setDisabled] = useState(false);
  return (
    <PageLayout>
      <Steps current={stepId}>
        {steps.map(item => (
          <Step key={item.title.props.id} title={item.title} />
        ))}
      </Steps>
      <div className="steps-content">
        {React.createElement(steps[stepId].content)}
      </div>
          <div className="steps-action">
              {stepId > 0 && (
                  <Button
                      style={{ marginLeft: 8 }}
                      onClick={() => changePage(-1)}
                      disabled={isLoadingFinish}
                  >
                      <FormattedMessage id="previous" defaultMessage="Previous" />
                  </Button>
              )}
              {stepId == 1 && stepId < steps.length - 2 && (
                  <Button
                      type="primary"
                      onClick={() => changePage(1)}
                      disabled={checkedRate}
                  >
   
                      <FormattedMessage id="next" defaultMessage="Next" />
                  </Button>
              )}
              {stepId == 3 && (
                  <Button
                      type="primary"
                      onClick={() => { setDisabled(true); finishWizard(); }}
                      disabled={disabled}

                  >
                      <FormattedMessage id="next" defaultMessage="Next" />
                  </Button>
              )}
        {stepId!=1 && stepId < steps.length - 2 && (
          <Button
            type="primary"
            onClick={() => changePage(1)}
            disabled={!isValid[stepId]}
          >
            <FormattedMessage id="next" defaultMessage="Next" />
          </Button>
              )}
              {stepId ==3 && (
                  <Button
                      type="primary"
                      onClick={() => { setDisabled(true);finishWizard();  }}
                      disabled={disabled}
                      
                  >
                      <FormattedMessage id="next" defaultMessage="Next" />
                  </Button>
              )}
        {stepId === 4 && (
          <Button
            type="primary"
            onClick={() => finishWizard()}
            loading={isLoadingFinish}
          >
            <FormattedMessage id="done" defaultMessage="Done" />
                  </Button>

              )}
              {stepId === 3 && (   <Button
                  type="primary"
                  onClick={() => downloadContract()}
                  loading={isLoadingFinish}
              >
                  <FormattedMessage id="print" defaultMessage="Imprimir" />
              </Button>)}
      </div>
      {<CusomterEmptyFields />}
    </PageLayout>
  );
};

export default connect(
    (state: ApplicationState) => ({
        id: state.wizard?.id,
        contractIdentifier: state.wizard?.contractIdentifier,
    stepId: state.wizard?.stepId,
    isValid: state.wizard?.isValid,
    isLoadingFinish: state.wizard?.isLoadingFinish
  }),
  WizardStore.actionCreators
)(Wizard as any);
