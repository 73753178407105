import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Table, Button, Divider } from "antd";
import { FormattedMessage } from "react-intl";
import { RouteComponentProps } from "react-router";

import { ApplicationState } from "../store";
import { RateModal, ExcelButton } from "../components";
import * as RatesStore from "../store/Rates";
import { IRate } from "../models";
import { PageLayout } from "../containers";

type IProps = RouteComponentProps &
  RatesStore.RatesState &
  typeof RatesStore.actionCreators;

interface IState {
  showModal: boolean;
  rate?: IRate;
}

class Rates extends PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      showModal: false,
    };
  }

  public componentDidMount() {
    this.props.requestRates();
  }

  changeModalState = (rate?: IRate) => {
    this.setState({
      showModal: !this.state.showModal,
      rate,
    });
  };

  handleOk = (rate: IRate) => {
    if (!rate.id) this.props.addRate(rate);
    else this.props.editRate(rate);

    this.setState({
      showModal: false,
    });
  };

  render() {
    const { rate, showModal } = this.state;
    const { list, isLoading, deleteRate } = this.props;

    return (
      <PageLayout>
        <Button
          type="primary"
          onClick={() => this.changeModalState()}
          style={{ marginBottom: "10px" }}
        >
          <FormattedMessage id="rate.add" defaultMessage="Add Rate" />
        </Button>
        <ExcelButton endpoint="rate" isGenerator={false} />
        <RateModal
          visible={showModal}
          onOk={this.handleOk}
          onCancel={this.changeModalState}
          rate={rate}
        />
        <Table<IRate>
          rowKey={(record: IRate) => record.id}
          dataSource={list}
          loading={isLoading}
          pagination={false}
        >
          <Table.Column<IRate>
            key="name"
            title={<FormattedMessage id="name" defaultMessage="Name" />}
            dataIndex="name"
          />
          <Table.Column<IRate>
            key="description"
            title={
              <FormattedMessage id="description" defaultMessage="Description" />
            }
            dataIndex="description"
          />
          <Table.Column<IRate>
            key="p1"
            title={<FormattedMessage id="p1" defaultMessage="P1" />}
            dataIndex="p1"
          />
          <Table.Column<IRate>
            key="p2"
            title={<FormattedMessage id="p2" defaultMessage="P2" />}
            dataIndex="p2"
          />
          <Table.Column<IRate>
            key="p3"
            title={<FormattedMessage id="p3" defaultMessage="P3" />}
            dataIndex="p3"
          />
          <Table.Column<IRate>
            key="actions"
            title={<FormattedMessage id="actions" defaultMessage="Actions" />}
            render={(_, record) => (
              <span>
                <a onClick={() => this.changeModalState(record)}>
                  <FormattedMessage id="edit" defaultMessage="Edit" />
                </a>
                <Divider type="vertical" />
                <a onClick={() => deleteRate(record)}>
                  <FormattedMessage id="delete" defaultMessage="Delete" />
                </a>
              </span>
            )}
          />
        </Table>
      </PageLayout>
    );
  }
}

export default connect(
  (state: ApplicationState) => state.rates,
  RatesStore.actionCreators
)(Rates as any);
