import React, { useEffect } from "react";
import { Redirect, RouteComponentProps } from "react-router";
import { connect } from "react-redux";

import { ApplicationState } from "../store";
import { AuthState } from "../store/Auth";
import * as AuthStore from "../store/Auth";

type IProps = AuthState & typeof AuthStore.actionCreators & RouteComponentProps;

const IsAdmin = (WrappedComponent: any) => {
    const Admin = (props: IProps) => {
        const { checkAuth, isAdmin, isLoading, location, isCallCenterAdmin, isBackOffice } = props;

    useEffect(() => {
      checkAuth();
    })
        //TOO: remove this
        return (isAdmin || isBackOffice || isCallCenterAdmin) && !isLoading ? (
      <WrappedComponent {...props} />
    ) : (
      <Redirect
        to={{
          pathname: "/signin",
          state: { from: location }
        }}
      />
    );
  };

  return connect(
    (state: ApplicationState) => state.auth,
    AuthStore.actionCreators
  )(Admin as any);
};




export default IsAdmin;
