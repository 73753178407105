import { Reducer, Action } from "redux";

import { AppThunkAction } from "./";
import { RatesService } from "../services";
import { IPromotion, IRate } from "../models";

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface RatesState {
  isLoading: boolean;
    list: IRate[];
    promotions: IPromotion[];
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface RequestRatesAction {
  type: "REQUEST_RATES";
}

interface RatesLoadedAction {
  type: "RATES_LOADED_FINISED";
  rates?: IRate[];
}

interface RequestPromotionAction {
    type: "REQUEST_PROMOTIONS";
}

interface PromotionsLoadedAction {
    type: "PROMOTIONS_LOADED_FINISED";
    promotions  : IPromotion[];
    
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = RequestRatesAction | RatesLoadedAction | RequestPromotionAction | PromotionsLoadedAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    requestPromotions: (): AppThunkAction<KnownAction | Action> => (
        dispatch,
        getState
    ) => {
        RatesService.GetPromotions()
            .then(promotions => {
                dispatch({ type: "PROMOTIONS_LOADED_FINISED", promotions });
            })
            .catch(e => {
                dispatch({ type: "PROMOTIONS_LOADED_FINISED" });
            });

        dispatch({ type: "REQUEST_PROMOTIONS" });
    },

    requestRates: (): AppThunkAction<KnownAction | Action> => (
    dispatch,
    getState
  ) => {
    RatesService.GetAll()
      .then(rates => {
        dispatch({ type: "RATES_LOADED_FINISED", rates });
      })
      .catch(e => {
        dispatch({ type: "RATES_LOADED_FINISED" });
      });

    dispatch({ type: "REQUEST_RATES" });
  },
  addRate: (rate: IRate): AppThunkAction<KnownAction | Action> => (
    dispatch,
    getState
  ) => {
    RatesService.Add(rate)
      .then(rateAdded => {
        dispatch({
          type: "RATES_LOADED_FINISED",
          rates: (getState().rates?.list || []).concat(rateAdded)
        });
      })
      .catch(e => {
        dispatch({ type: "RATES_LOADED_FINISED" });
      });

    dispatch({ type: "REQUEST_RATES" });
  },
  editRate: (rate: IRate): AppThunkAction<KnownAction | Action> => (
    dispatch,
    getState
  ) => {
    RatesService.Edit(rate)
      .then(rateEdited => {
        const rates = getState().rates?.list || [];
        const ind = rates.findIndex(obj => obj.id === rateEdited.id);
        rates[ind] = rateEdited;

        dispatch({
          type: "RATES_LOADED_FINISED",
          rates
        });
      })
      .catch(e => {
        dispatch({ type: "RATES_LOADED_FINISED" });
      });

    dispatch({ type: "REQUEST_RATES" });
  },
  deleteRate: (rate: IRate): AppThunkAction<KnownAction | Action> => (
    dispatch,
    getState
  ) => {
    RatesService.Delete(rate)
      .then(() => {
        const rates = getState().rates?.list || [];
        const ind = rates.findIndex(obj => obj.id === rate.id);
        rates.splice(ind, 1);

        dispatch({
          type: "RATES_LOADED_FINISED",
          rates
        });
      })
      .catch(e => {
        dispatch({ type: "RATES_LOADED_FINISED" });
      });

    dispatch({ type: "REQUEST_RATES" });
  }
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: RatesState = { isLoading: false, list: [], promotions:[] };

export const reducer: Reducer<RatesState> = (
  state: RatesState | undefined,
  action: KnownAction
): RatesState => {
  if (state === undefined) {
    return unloadedState;
  }

  switch (action.type) {
    case "REQUEST_RATES":
      return {
        ...state,
        isLoading: true
      };
    case "RATES_LOADED_FINISED":
      return {
        ...state,
        isLoading: false,
        list: action.rates || []
          };
      case "PROMOTIONS_LOADED_FINISED":
          return {
              ...state,
              isLoading: false,
              promotions: action.promotions || []
          };
    default:
      return state;
  }
};
