import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { Select, Table, Button, Checkbox, Input, DatePicker, ConfigProvider, Drawer } from "antd";
import { FormattedMessage } from "react-intl";
import { RouteComponentProps } from "react-router";
import { Link } from "react-router-dom";
import Search from "antd/lib/input/Search";

import { ApplicationState } from "../store";
import { PageLayout } from "../containers";
import * as ContractsStore from "../store/Contracts";
import * as IncidentsStore from "../store/Incidents";
import { IContract, IContractFilter, IIncident } from "../models";
import { ExcelButton } from "../components";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import locale from 'antd/es/locale/es_ES';
import { WizardService } from "../services/WizardService";
import { EditOutlined, DownloadOutlined, DeleteOutlined } from '@ant-design/icons';
import CollapsePanel from "antd/lib/collapse/CollapsePanel";
import { Collapse } from 'antd';
import { debug } from "console";
import { get } from "http";

const { Option } = Select;
const { Panel } = Collapse;
type IProps = RouteComponentProps &
    //ContractsStore.ContractsState &
    IncidentsStore.IncidentState &
    typeof IncidentsStore.actionCreators;

const dotColor = ["red", "red", "green", "red", "green", "black"];

const Incidents = (props: IProps) => {
    const {
        list,
        isLoading,
        requestIncidents,
        deletetIncident
    } = props;
    const isAdmin = useSelector(
        (appState: ApplicationState) => appState.auth?.isAdmin
    );
    const isCallCenterAdmin = useSelector(
        (appState: ApplicationState) => appState.auth?.isCallCenterAdmin
    );
    //const deleteContract = (id: any) => console.log(id);
    const agents = useSelector((appsState: ApplicationState) => appsState.contracts?.agents);
    const callcenters = useSelector((appsState: ApplicationState) => appsState.contracts?.callcenters);
    const [showFilter, setShowFilter] = useState(false);
    useEffect(() => {
        props.requestIncidents();
    }, []);
    const RenderAgent = (i: number) => {
        switch (i) {
            case 1:
                return "Pendiente de contacto (por omision)"
            case 2:
                return "Es cambio de titula"
            case 3:
                return "No es cambio de titular, y he verificado que el CUPS es correcto"
            case 4:
                return "El cliente desea continuar con la contratacion"
            case 5:
                return "El cliente no desea continuar con la contratacion"
            case 6:
                return "Otros (Explicar)"
        }
    }
    const RenderAdmin = (i: number) => {
        switch (i) {
            case 1:
                return "Rechazo CIF/NIF no coincide con el del titular"
            case 2:
                return "Otros rechazos"
            case 3:
                return "Rechazo Resuelto"
            case 4:
                return "Desistimiento pendiente"
            case 5:
                return "Desistimiento recuperado"
            case 6:
                return "Desistimiento KO"
        }
    }
    return (
        <ConfigProvider locale={locale}>

            <PageLayout>

                <Drawer
                    title="Filtros"
                    placement={"right"}
                    closable={true}
                    onClose={() => setShowFilter(false)}
                    visible={showFilter}
                >
                    <Input placeholder="Cups" id="cupText" allowClear />
                    <Input id="nameText" placeholder="Nombre" allowClear />
                    <Input id="addressText" placeholder="Direccion" allowClear />
                    <Input id="nifText" placeholder="Nif" allowClear />
                    <DatePicker locale={locale} id="startDate" placeholder="Fecha Inicio" format="DD/MM/YYYY" />
                    <DatePicker locale={locale} id="endDate" placeholder="Fecha Fin" format="DD/MM/YYYY" />
                    <Select id="statusselect" defaultValue="Estado" style={{ width: 250 }}>
                        <Option key="select" value="Estado">Estado</Option>
                        <Option key="1" value="1">Pendiente</Option>
                        <Option key="2" value="2">Enviado</Option>

                    </Select>
                    {isAdmin && (
                        <div>
                            <Select defaultValue="Callcenter" id="callcenterselect" style={{ width: 250 }}>
                                <Option key="select" value="Callcenter">Callcenter</Option>
                                {callcenters?.map(center => <Option key={center.id} value={center.name}>{center.name}</Option>)}
                            </Select>
                            <Select defaultValue="Agente" id="agentselect" style={{ width: 250 }}>
                                <Option key="select" value="Agente">Agente</Option>

                                {agents?.map(agent => <Option key={agent.name} value={agent.name}>{agent.name}</Option>)}

                            </Select>



                        </div>
                    )}
                    <Button
                        onClick={() => {
                            var cups = (document.getElementById("cupText") as any).value;
                            var name = (document.getElementById("nameText") as any).value;
                            var address = (document.getElementById("addressText") as any).value;
                            var nif = (document.getElementById("nifText") as any).value;
                            var start = (document.querySelector("span#startDate input") as any).value;
                            var end = (document.querySelector("span#endDate input") as any).value;
                            var callcenter = "";
                            var agent = "";
                            var status = (document.querySelector("#statusselect .ant-select-selection-selected-value") as any)?.innerText;

                            if (isAdmin) {
                                callcenter = (document.querySelector("#callcenterselect .ant-select-selection-selected-value") as any)?.innerText;
                                agent = (document.querySelector("#agentselect .ant-select-selection-selected-value") as any)?.innerText;

                            }
                            //filterContracts(cups, name, address, nif, (callcenter == "Callcenter" || callcenter == "" ? "" : callcenter), start, end, (agent == "Agente" || agent == "" ? "" : agent), (status == "Estado" || status == "" ? 0 : (status == "Pendiente" ? 1 : 2)));
                            setShowFilter(false);
                        }}>
                        Buscar</Button>
                </Drawer>
                <Table<IIncident>
                    rowKey={(record: IIncident) => record.id.toString()}
                    dataSource={list}
                    loading={isLoading}
                >
                    {isAdmin && (
                        <Table.Column<IIncident>
                            key="callcenter"
                            title="CallCenter"
                            dataIndex="id"
                            render={(_, s) => (
                                <div>

                                    {s.callCenterName}
                                </div>
                            )}
                        />
                    )}
                    {(isAdmin || isCallCenterAdmin) && (
                        <Table.Column<IIncident>
                            key="agentName"
                            title="Agente"
                            dataIndex="id"
                            render={(_, s) => (
                                <div>

                                    {s.agentName}
                                </div>
                            )}
                        />
                    )}
                    <Table.Column<IIncident>
                        key="cups"
                        title={<FormattedMessage id="cups" defaultMessage="Cups" />}
                        dataIndex="cups"
                    />
                    <Table.Column<IIncident>
                        key="adminReason"
                        title={<FormattedMessage id="adminReason" defaultMessage="Razon Admin" />}
                        dataIndex="adminReason"
                        render={(_, s) => (
                            RenderAdmin(s.adminReason)
                        )}
                    />
                    <Table.Column<IIncident>
                        key="agentReason"
                        title={<FormattedMessage id="agentReason" defaultMessage="Razon Agente" />}
                        dataIndex="agentReason"
                        render={(_, s) => (
                            RenderAgent(s.agentReason)
                        )}
                    />
                    <Table.Column<IIncident>
                        key="status"
                        title={<FormattedMessage id="status" defaultMessage="Status" />}
                        dataIndex="status"
                        render={(_, s) => (
                            <div
                                className="dot"
                                style={{ backgroundColor: dotColor[s.adminReason-1] }}
                            ></div>
                        )}
                    />
                    <Table.Column<IIncident>
                        key="id"
                        title="Edit"
                        dataIndex="id"
                        render={(_, s) => (
                            <Button>
                                <Link to={`/incident-detail/${s.id}`}>
                                    <EditOutlined />
                                </Link>
                            </Button>
                        )}
                    />
                    {isAdmin && (<Table.Column<IIncident>
                        key="id"
                        title="Remove"
                        dataIndex="id"
                        render={(_, s) => (
                            <Button onClick={() => {
                                deletetIncident(s.id);
                                requestIncidents();
                            }}>
                                    <DeleteOutlined />
                            </Button>
                        )}
                    />)}
                </Table>

            </PageLayout>
        </ConfigProvider>
    );
};

export default connect(
    (state: ApplicationState) => state.incidents,
    IncidentsStore.actionCreators
)(Incidents as any);
