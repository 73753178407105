import React, { useEffect, useState } from "react";
import { Steps, Button, notification, Checkbox, Input, Select } from "antd";
import { RouteComponentProps } from "react-router";
import { connect, DispatchProp } from "react-redux";
import { FormattedMessage } from "react-intl";

import {
    PageLayout,
    First,
    Second,
    Third,
    Fourd,
    Fifth,
    CusomterEmptyFields

} from "../containers";
import * as WizardStore from "../store/Wizard";
import { ApplicationState } from "../store";
import FormItem from "antd/lib/form/FormItem";
import { debounce } from "ts-debounce";
import { valida_cups } from "../helpers";
import * as RatesStore from "../store/Rates";
import WizardItem from "../components/WizardItem";
const { Option } = Select;

const { Step } = Steps;
const steps = [
    {
        title: <FormattedMessage id="first5" defaultMessage="CUPS" />,
        content: First
    },
    {
        title: <FormattedMessage id="second4" defaultMessage="Tarifa" />,
        content: Second
    },
    {
        title: (
            <FormattedMessage id="sendContract2" defaultMessage="Datos Personales" />
        ),
        content: Third
    },
    {
        title: <FormattedMessage id="last11" defaultMessage="Previsualizacion" />,
        content: Fourd
    }
    ,
    {
        title: <FormattedMessage id="last33" defaultMessage="Estado" />,
        content: Fifth
    }
];

type IProps = typeof RatesStore.actionCreators &
    typeof WizardStore.actionCreators &
    RouteComponentProps &
    DispatchProp & {
        stepId: number;
        isValid: boolean[];
        isLoadingFinish: boolean;
        checkedRate: boolean;

    };
const { Search } = Input;

const Wizard2 = (props: IProps) => {
    
    return (
        <PageLayout>
            
        </PageLayout>
    );
};

export default connect(
    (state: ApplicationState) => ({
        ...state.rates,
        idRate: state.wizard?.rateId,
        id: state.wizard?.id,
        contractIdentifier: state.wizard?.contractIdentifier,
        stepId: state.wizard?.stepId,
        isValid: state.wizard?.isValid,
        isLoadingFinish: state.wizard?.isLoadingFinish,
        cups: state.wizard?.cups,
        promotiona: state.wizard?.customer?.promotiona,
        potencia1: state.wizard?.potencia1,
        potencia2: state.wizard?.potencia2,
    }),
    {
        ...RatesStore.actionCreators,
        ...WizardStore.actionCreators
    }
)(Wizard2 as any);

