import BaseService from "./BaseService";
import { ICallCenter, ICallCenterUser, IUser } from "../models";

export class RatesService extends BaseService {
  public async GetAll(): Promise<ICallCenter[]> {
    return await this.get(`api/callCenter/all`);
  }

  public async Add(callCenter: ICallCenter): Promise<ICallCenter> {
    return await this.post(`api/callCenter`, callCenter);
  }

    public async Enable(callCenter: ICallCenter): Promise<ICallCenter> {
    return await this.post(`api/callCenter/enable`, callCenter);
  }

    public async Disable(callCenter: ICallCenter): Promise<ICallCenter> {
    return await this.post(`api/callCenter/disable`, callCenter);
  }

    public async Edit(callCenter: ICallCenter): Promise<ICallCenter> {
    return await this.put(`api/callCenter`, callCenter);
    }
    public async AddUserToCallCenter(user: ICallCenterUser[], callCenter: string): Promise<ICallCenter> {
        return await this.post(`api/callCenter/${callCenter}/user`, user);
    }
    public async UpdateUsers(user: ICallCenterUser[], callCenter: string): Promise<ICallCenter> {
        return await this.post(`api/callCenter/${callCenter}/user/update`, user);
    }
    public async RemoveUserFromCallCenter(user: string, callCenter: string): Promise<ICallCenter> {
        return await this.delete(`api/callCenter/${callCenter}/user/${user}`);
    }
  public async Delete(callCenter: ICallCenter): Promise<boolean> {
    return await this.delete(`api/callCenter/${callCenter.id}`);
  }

  public async Excel(): Promise<{}> {
    return await this.get("api/callCenter/excel");
  }
}

export default new RatesService();
