import BaseService from "./BaseService";
import { IPromotion, IRate } from "../models";

export class RatesService extends BaseService {
  public async GetAll(): Promise<IRate[]> {
    return await this.get(`rate/all`);
    }
    public async GetPromotions(): Promise<IPromotion[]> {
        return await this.get(`rate/promotions`);
    }

  public async Add(rate: IRate): Promise<IRate> {
    return await this.post(`rate`, rate);
  }

  public async Edit(rate: IRate): Promise<IRate> {
    return await this.put(`rate`, rate);
  }

  public async Delete(rate: IRate): Promise<boolean> {
    return await this.delete(`rate/${rate.id}`);
  }

  public async Excel(): Promise<{}> {
    return await this.get("rate/excel");
  }
}

export default new RatesService();
