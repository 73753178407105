import moment, { Moment } from 'moment';

import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { Select, Table, Button, Checkbox, Input, DatePicker, ConfigProvider, Row, Col } from "antd";
import { FormattedMessage } from "react-intl";
import { RouteComponentProps } from "react-router";
import { Link } from "react-router-dom";
import Search from "antd/lib/input/Search";

import { ApplicationState } from "../store";
import { PageLayout } from "../containers";
import * as ContractsStore from "../store/Contracts";
import { IContract, IContractFilter } from "../models";
import { ExcelButton } from "../components";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import locale from 'antd/es/locale/es_ES';
import { WizardService } from "../services/WizardService";
import Chart from "react-google-charts";
import { isMobile } from 'react-device-detect';
import locale1 from 'antd/es/date-picker/locale/es_ES';

const { RangePicker } = DatePicker;



const { Option } = Select;

type IProps = RouteComponentProps &
    ContractsStore.ContractsState &
    typeof ContractsStore.actionCreators;

const dotColor = ["red", "orange", "green"];

const Stats = (props: IProps) => {
    const getToday = () => {
        var year = new Date().getFullYear();
        var month = new Date().getMonth() + 1;
        var day = new Date().getDate();

        return [moment(year + '-' + month + '-' + day), moment(year + '-' + month + '-' + day)];
    }
    const [dateFilter, setDateFilter] = useState(getToday())
    const [dateStartFilter, setStartFilter] = useState()
    const [dateEndFilter, setEndFilter] = useState()
    const [isSearchClicked, setIsSearchClicked] = useState(false)
    const {
        list,
        selectedContracts,
        isLoading,
        requestContracts,
        requestGroupedData,
        requestHome,
        filterContracts,
        filterGrouped,
        deleteContract,
        downloadContract, markContractAsSelected
    } = props;
    const isAdmin = useSelector(
        (appState: ApplicationState) => appState.auth?.isAdmin
    );
    const isCallCenterAdmin = useSelector(
        (appState: ApplicationState) => appState.auth?.isCallCenterAdmin
    );
    //const deleteContract = (id: any) => console.log(id);
    const agents = useSelector((appsState: ApplicationState) => appsState.contracts?.agents);
    const callcenters = useSelector((appsState: ApplicationState) => appsState.contracts?.callcenters);
    const groupedData = useSelector((appsState: ApplicationState) => appsState.contracts?.defaultGrouped);
    const multi = useSelector((appsState: ApplicationState) => appsState.contracts?.multi);
    const multiRate = useSelector((appsState: ApplicationState) => appsState.contracts?.multiRate);
    const agreggatedRate = useSelector((appsState: ApplicationState) => appsState.contracts?.aggregatedRate);

    useEffect(() => {
        //requestGroupedData(dateStartFilter.toString(), dateEndFilter.toString());//TODO
        requestHome();
       
    }, []);


    return (
        <ConfigProvider locale={locale}>
            {isMobile ? (
                <PageLayout>               <div className="actions">
                <div>
                    <Select defaultValue="Callcenter" id="callcenterselect" style={{ width: 250 }}>
                        <Option key="select" value="Callcenter">Callcenter</Option>
                        {callcenters?.map(center => <Option key={center.id} value={center.name}>{center.name}</Option>)}
                        </Select>
                        <DatePicker locale={locale} id="startDate" value={dateStartFilter} placeholder="Fecha Inicio" format="DD/MM/YYYY" onChange={(a: any, b: any) => {
                            setStartFilter(a); 
                        }} />
                        <DatePicker locale={locale} id="endDate" value={dateEndFilter } placeholder="Fecha Fin" format="DD/MM/YYYY" onChange={(a: any, b: any) => {
                            setEndFilter(a); 
                        }} />
                    <Button onClick={() => {
                        setTimeout(
                            () => {
                                var year = new Date().getFullYear();
                                var month = new Date().getMonth() + 1;
                                var day = new Date().getDate();
                                setDateFilter(getToday());
                                setStartFilter(moment() as any);
                                setEndFilter(moment() as any); 

                            },
                            100
                        )
                    }} id="hoybtn">Hoy</Button>
                    <Button onClick={() => {
                        var year = new Date().getFullYear();
                        var month = new Date().getMonth() + 1;
                        var days = new Date(year, month, 0).getDate();
                        var ary = getToday()[0];
                        var newAry = [moment(year + '-' + month + '-'
                            + 1), moment(year + '-' + month + '-'
                                + days)];
                            setDateFilter(newAry);
                            setStartFilter(moment().clone().startOf('month') as any);
                            setEndFilter(moment().clone().endOf('month') as any); 
                    }}>Mes Actual</Button>
                </div><div>
                    <Button
                        onClick={() => {
                            var year = "Todo";
                            var month = 1;
                            var callcenter = "";
                            var statusValue = 2;
                            callcenter = (document.querySelector("#callcenterselect .ant-select-selection-selected-value") as any)?.innerText;
                                var items = document.querySelectorAll('.ant-calendar-range-picker-input');
                                var start = (dateStartFilter as any).toDate().toString();
                                var end = (dateEndFilter as any).toDate().toString();
                                requestGroupedData(start,end);//TODO
                                
                            setIsSearchClicked(true)
                        }} id="buscarbtn">
                        Buscar</Button>
                </div>
            </div>
                <hr />
                    {
                        isSearchClicked ? (
                            <div>
                                <Row>
                                    <Col span={6}>
                                        <h2>Por Agente</h2>
                                    </Col>
                                    <Col span={6}>
                                        <h2>Por Tarifa</h2>
                                    </Col>
                                </Row>
                                {multiRate?.map(item => {
                                    return (
                                        <div>
                                            <Row>
                                                <Col span={12}>
                                                    <h2>{item.callCenter}</h2>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col span={6}>
                                                    <Chart
                                                        width={450}
                                                        chartType="BarChart"
                                                        loader={<div>Loading Chart</div>}
                                                        data={[[
                                                            'Usario',
                                                            'Enviados',
                                                        ], ...item?.agents?.map((d: any) => [`${d.userName} - (${d.value})`, d.value])]}

                                                        rootProps={{ 'data-testid': '0', 'data-testid1': '1' }}

                                                        options={{
                                                            title: 'Datos Totales',
                                                            chartArea: { width: '50%' },
                                                            hAxis: {
                                                                title: '',
                                                                minValue: 0,
                                                            },
                                                            legend: 'none',

                                                            vAxis: {
                                                                title: '',
                                                            },
                                                            colors: ['green', 'blue']
                                                        }}
                                                    />
                                                
                                                    <Chart
                                                        width={450}
                                                        chartType="BarChart"
                                                        loader={<div>Loading Chart</div>}
                                                        data={[[
                                                            'Tarifa',
                                                            'Enviados',
                                                        ], ...item?.rates?.map((d: any) => [`${d.rate} - (${d.value})`, d.value])]}

                                                        rootProps={{ 'data-testid': '0', 'data-testid1': '1' }}

                                                        options={{
                                                            title: 'Datos Totales',
                                                            chartArea: { width: '50%' },
                                                            hAxis: {
                                                                title: '',
                                                                minValue: 0,
                                                            },
                                                            legend: 'none',

                                                            vAxis: {
                                                                title: '',
                                                            },
                                                            colors: ['green', 'blue']
                                                        }}
                                                    />
                                                </Col>
                                            </Row>
                                        </div>)
                                })}
                            </div>
                        ) : (<div>Presiona el boton buscar con los filtros</div>)
                    }
            </PageLayout>) : (
            <PageLayout>
                <div className="actions">
                    <div>
                        <Select defaultValue="Callcenter" id="callcenterselect" style={{ width: 250 }}>
                            <Option key="select" value="Callcenter">Callcenter</Option>
                            {callcenters?.map(center => <Option key={center.id} value={center.name}>{center.name}</Option>)}
                        </Select>
                                <RangePicker locale={locale} defaultValue={dateFilter as any} value={dateFilter as any} onChange={(a: any, b: any) => {
                            setDateFilter(a as any[]);
                        }} />
                        <Button onClick={() => {
                            setTimeout(
                                () => {
                                    var year = new Date().getFullYear();
                                    var month = new Date().getMonth() + 1;
                                    var day = new Date().getDate();
                                    setDateFilter(getToday());
                                },
                                100
                            )
                        }} id="hoybtn">Hoy</Button>
                        <Button onClick={() => {
                            var year = new Date().getFullYear();
                            var month = new Date().getMonth() + 1;
                            var days = new Date(year, month, 0).getDate();
                            var ary = getToday()[0];
                            var newAry = [moment(year + '-' + month + '-'
                                + 1), moment(year + '-' + month + '-'
                                    + days)];
                            setDateFilter(newAry);
                        }}>Mes Actual</Button>
                    </div><div>
                        <Button
                            onClick={() => {


                                var items = document.querySelectorAll('.ant-calendar-range-picker-input');
                                var start = (items[0] as any).value;
                                        var end = (items[1] as any).value;


                                        requestGroupedData(start, end);//TODO
                                setIsSearchClicked(true)
                            }} id="buscarbtn">
                            Buscar</Button>
                    </div>
                </div>
                <hr />

                {
                            isSearchClicked ? (
                                <div>
                                    <Row>
                                        <Col span={6}>
                                            <h2>Por Agente</h2>
                                        </Col>
                                        <Col span={6}>
                                            <h2>Por Tarifa</h2>
                                        </Col>
                                    </Row>
                                    {multiRate?.map(item=>{
                                        return (
                                            <div>
                                                <Row>
                                                    <Col span={12}>
                                                        <h2>{item.callCenter}</h2>
                                                        </Col>
                                                </Row>
                                                <Row>
                                                    <Col span={6}>
                                                                                <Chart
                                                            width={900}
                                                            chartType="BarChart"
                                                            loader={<div>Loading Chart</div>}
                                                                data={[[
                                                                    'Usario',
                                                                    'Enviados',
                                                                ], ...item?.agents?.map((d: any) => [`${d.userName} - (${d.value})`, d.value])]}

                                                            rootProps={{ 'data-testid': '0', 'data-testid1': '1' }}

                                                            options={{
                                                                title: 'Datos Totales',
                                                                chartArea: { width: '50%' },
                                                                hAxis: {
                                                                    title: '',
                                                                    minValue: 0,
                                                                },
                                                                legend: 'none',

                                                                vAxis: {
                                                                    title: '',
                                                                },
                                                                colors: ['green', 'blue']
                                                            }}
                                                        />
                                                    </Col>
                                                    <Col span={6}>
                                                        <Chart
                                                            width={900}
                                                            chartType="BarChart"
                                                            loader={<div>Loading Chart</div>}
                                                            data={[[
                                                                'Tarifa',
                                                                'Enviados',
                                                            ], ...item?.rates?.map((d: any) => [`${d.rate} - (${d.value})`, d.value])]}

                                                            rootProps={{ 'data-testid': '0', 'data-testid1': '1' }}

                                                            options={{
                                                                title: 'Datos Totales',
                                                                chartArea: { width: '50%' },
                                                                hAxis: {
                                                                    title: '',
                                                                    minValue: 0,
                                                                },
                                                                legend: 'none',

                                                                vAxis: {
                                                                    title: '',
                                                                },
                                                                colors: ['green', 'blue']
                                                            }}
                                                        />
                                                    </Col>
                                                    </Row>
                                                </div>)
                                                })}
                                </div>
                               ) : (<div>Presiona el boton buscar con los filtros</div>)
                }

                </PageLayout>)}
        </ConfigProvider>
    );
};

export default connect(
    (state: ApplicationState) => state.contracts,
    ContractsStore.actionCreators
)(Stats as any);
