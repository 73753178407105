
import BaseService from "./BaseService";

export class ExcelService extends BaseService {
  public async Download(excelEndpoint: string) {
    return await this.download(`${excelEndpoint}/excel`);
  }
    public async DownloadEstimation(excelEndpoint: string, name: string) {
        return await this.downloadwithName(`${excelEndpoint}`,name);
    }
    public async DownloadEstimationWithError(excelEndpoint: string, name: string): Promise<boolean> {
        return await this.downloadwithNameWithError(`${excelEndpoint}`, name);
    }
    public async DownloadEstimationWithErrorAndTemplate(excelEndpoint: string, template: string, name: string): Promise<boolean> {
        return await this.downloadwithNameWithError(`${excelEndpoint}`, name);
    }
    public async GetByAddress(postalCode: string, address: string) {
        return await this.post(`excelgenerator/getByAddress`, { postalCode: postalCode, address: address });
    }
}

export default new ExcelService();
