import React, { PureComponent } from "react";
import { Modal, Form, Input } from "antd";
import { FormComponentProps } from "antd/lib/form";

import { IRate } from "../models";
import { FormattedMessage } from "react-intl";

interface IRateModalProps extends FormComponentProps {
  rate?: IRate;
  visible: boolean;
  onOk: (rate: IRate) => void;
  onCancel: () => void;
}

class RateModal extends PureComponent<IRateModalProps> {
  onModalOk = () => {
    const { form, onOk, rate } = this.props;

    form.validateFields((err, values) => {
      if (err) return;

      form.resetFields();
      onOk({ ...rate, ...values });
    });
  };

  render() {
    const { visible, onCancel, form, rate } = this.props;
    const { getFieldDecorator } = form;

    return (
      <Modal visible={visible} onCancel={onCancel} onOk={this.onModalOk}>
        <Form.Item
          label={<FormattedMessage id="name" defaultMessage="Name" />}
          hasFeedback
        >
          {getFieldDecorator("name", {
            initialValue: rate?.name,
            rules: [
              {
                required: true,
                message: (
                  <FormattedMessage
                    id="pleaseName"
                    defaultMessage="Please input the name!"
                  />
                )
              }
            ]
          })(<Input />)}
        </Form.Item>
        <Form.Item
          label={
            <FormattedMessage id="description" defaultMessage="Description" />
          }
          hasFeedback
        >
          {getFieldDecorator("description", {
            initialValue: rate?.description,
            rules: [
              {
                required: true,
                message: (
                  <FormattedMessage
                    id="pleaseDescription"
                    defaultMessage="Please input the description!"
                  />
                )
              }
            ]
          })(<Input />)}
        </Form.Item>
        <Form.Item
          label={<FormattedMessage id="p1" defaultMessage="P1" />}
          hasFeedback
        >
          {getFieldDecorator("p1", {
            initialValue: rate?.p1,
            rules: [
              {
                required: true,
                message: (
                  <FormattedMessage
                    id="p1"
                    defaultMessage="Please input the p1 price!"
                  />
                )
              },
              {
                pattern: new RegExp("^\\d+$"),
                message: (
                  <FormattedMessage
                    id="pleaseNumeric"
                    defaultMessage="Please input a numeric value!"
                  />
                )
              }
            ]
          })(<Input />)}
            </Form.Item>
            <Form.Item
                label={<FormattedMessage id="p2" defaultMessage="P2" />}
                hasFeedback
            >
                {getFieldDecorator("p2", {
                    initialValue: rate?.p2,
                    rules: [
                        {
                            required: true,
                            message: (
                                <FormattedMessage
                                    id="p2"
                                    defaultMessage="Please input the p2 price!"
                                />
                            )
                        },
                        {
                            pattern: new RegExp("^\\d+$"),
                            message: (
                                <FormattedMessage
                                    id="pleaseNumeric"
                                    defaultMessage="Please input a numeric value!"
                                />
                            )
                        }
                    ]
                })(<Input />)}
            </Form.Item>
            <Form.Item
                label={<FormattedMessage id="p3" defaultMessage="P3" />}
                hasFeedback
            >
                {getFieldDecorator("p3", {
                    initialValue: rate?.p3,
                    rules: [
                        {
                            required: true,
                            message: (
                                <FormattedMessage
                                    id="p3"
                                    defaultMessage="Please input the p3 price!"
                                />
                            )
                        },
                        {
                            pattern: new RegExp("^\\d+$"),
                            message: (
                                <FormattedMessage
                                    id="pleaseNumeric"
                                    defaultMessage="Please input a numeric value!"
                                />
                            )
                        }
                    ]
                })(<Input />)}
            </Form.Item>
      </Modal>
    );
  }
}

export default Form.create<IRateModalProps>({ name: "modal" })(RateModal);
