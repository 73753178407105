import React, { PureComponent } from "react";
import { Modal, Form, Input, Button, Icon, Row, Col, Checkbox, Tabs } from "antd";
import { FormComponentProps } from "antd/lib/form";
import { FormattedMessage } from "react-intl";
import { ICallCenter, ICallCenterUser } from "../models";
import Text from "antd/lib/typography/Text";
const { TabPane } = Tabs;

interface ICallCenterModalProps extends FormComponentProps {
    callcenter?: ICallCenter;
    visible: boolean;
    onOk: (callcenter: ICallCenter) => void;
    onRemoveUser: (user: string, callCenter: string) => void;
    onCancel: () => void;
}

interface ICallCenterModalState {
    users: ICallCenterUser[];
}

const formItemLayoutWithOutLabel = {
    wrapperCol: {
        xs: { span: 24, offset: 0 },
        sm: { span: 20, offset: 4 },
    },
};

class CallCenterModal extends PureComponent<
    ICallCenterModalProps,
    ICallCenterModalState
    > {
    constructor(props: ICallCenterModalProps) {
        super(props);

        this.state = {
            users: props.callcenter?.users || [],
        };
    }

    componentDidUpdate(
        prevProps: ICallCenterModalProps,
        prevState: ICallCenterModalState
    ) {
        if (this.props.callcenter?.id !== prevProps.callcenter?.id) {
            this.setState({ users: this.props.callcenter?.users || [] });
        }
    }

    onModalOk = () => {
        const { form, onOk, callcenter, onRemoveUser } = this.props;
        form.validateFields((err, values) => {
            if (err) return;
            form.resetFields();
            onOk({ ...callcenter, ...values, users: this.state.users });
        });
    };

    remove = (user: ICallCenterUser) => {
        this.setState({
            users: this.state.users.filter((u) => u.idRow !== user.idRow),
        });
        this.props.onRemoveUser(user.userId, user.callCenterId);
    };

    add = () => {
        this.setState({
            users: [
                ...this.state.users,
                {
                    name: "",
                    password: "",
                    isAdmin: false,
                    disabled: false,
                    callCenterId: "",
                    userId: "",
                    id: "",
                    idRow: Math.random(),
                },
            ],
        });
    };

    updateProperty = (
        user: ICallCenterUser,
        prop: keyof ICallCenterUser,
        value: string | boolean
    ) => {
        (user as any)[prop] = value;

        this.setState({
            users: this.state.users.map((stateUser) =>
                stateUser.idRow === user.idRow ? user : stateUser
            ),
        });
    };

    render() {
        const { users } = this.state;
        const { visible, onCancel, form, callcenter } = this.props;
        const { getFieldDecorator } = form;

        const formItems = users.map((k) => (
            <Row type="flex" align="middle" justify="space-around" key={k.idRow}>
                <Col span={users.length > 1 ? 7 : 8}>
                    <Input
                        placeholder="Name"
                        defaultValue={k.name}
                        onChange={(e) => this.updateProperty(k, "name", e.target.value)}
                    />
                </Col>
                <Col span={users.length > 1 ? 7 : 8}>
                    <Form.Item
                        validateStatus={
                            !k.password
                                ? ""
                                : /^(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).*$/gm.test(
                                    k.password
                                )
                                    ? "success"
                                    : "error"
                        }
                    >
                        <Input
                            placeholder="password"
                            onChange={(e) =>
                                this.updateProperty(k, "password", e.target.value)
                            }
                        />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Checkbox
                        checked={(k as any).isAdmin}
                        onChange={(e) =>
                            this.updateProperty(k, "isAdmin" as any, e.target.checked)
                        }
                    >
                        Is Admin
          </Checkbox>
                </Col>

                {users.length > 1 ? (
                    <Col span={1}>
                        <Icon
                            className="dynamic-delete-button"
                            type="minus-circle-o"
                            onClick={() => this.remove(k)}
                        />
                    </Col>
                ) : null}
            </Row>
        ));

        return (
            <Modal visible={visible} onCancel={onCancel} onOk={this.onModalOk}>
                <Form.Item
                    label={<FormattedMessage id="name" defaultMessage="Name" />}
                    hasFeedback
                >
                    {getFieldDecorator("name", {
                        initialValue: callcenter?.name,
                        rules: [
                            {
                                required: true,
                                message: (
                                    <FormattedMessage
                                        id="pleaseName"
                                        defaultMessage="Please input the name!"
                                    />
                                ),
                            },
                        ],
                    })(<Input />)}
                </Form.Item>
                <Form.Item
                    label={<FormattedMessage id="comparionEnabled" defaultMessage="Comparativa Habilitada" />}
                >
                    {getFieldDecorator("comparionEnabled", {
                        initialValue: callcenter?.comparionEnabled,

                    })(<Checkbox />)}
                </Form.Item>
                <Text>La contrase�a tiene que tener al menos 8 caracters (mayusculas, minusculas y caracteres especiales) ex: Ad!291lAmvC</Text>
                <hr />
                <Tabs defaultActiveKey="1">
                    <TabPane tab="Usuarios" key="1">
                        {formItems}
                        <Form.Item {...formItemLayoutWithOutLabel}>
                            <Button type="dashed" onClick={this.add} style={{ width: "60%" }}>
                                <Icon type="plus" /> Add User
          </Button>
                        </Form.Item>
                    </TabPane>
                    <TabPane tab="Tarifas" key="2">
                        Matrix
                        <table>
                            <tr>
                                <td></td>
                                <td className="rateHeader">Tarifa 1</td>
                                <td className="rateHeader">Tarifa 2</td>
                            </tr>
                            <tr>
                                <td>abc</td>
                                <td ><button onClick={() => alert('a')}>OK</button></td>
                                <td>KO</td>
                            </tr>
                            <tr>
                                <td>abc</td>
                                <td>OK</td>
                                <td>KO</td>
                            </tr>
                            <tr>
                                <td>abc</td>
                                <td>OK</td>
                                <td>KO</td>
                            </tr>
                            <tr>
                                <td>abc</td>
                                <td>OK</td>
                                <td>KO</td>
                            </tr>
                            <tr>
                                <td>abc</td>
                                <td>OK</td>
                                <td>KO</td>
                            </tr>
                            <tr>
                                <td>abc</td>
                                <td>OK</td>
                                <td>KO</td>
                            </tr>
                            <tr>
                                <td>abc</td>
                                <td>OK</td>
                                <td>KO</td>
                            </tr>
                        </table>
                    </TabPane>
                </Tabs>


            </Modal>
        );
    }
}

export default Form.create<ICallCenterModalProps>({ name: "modal" })(
    CallCenterModal
);
