import React, { PureComponent, useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Table, Button, Divider, Form, Input, Select } from "antd";
import { FormattedMessage } from "react-intl";
import { RouteComponentProps } from "react-router";
import * as IncidentsStore from "../store/Incidents";

import { ApplicationState } from "../store";
import { RateModal, ExcelButton } from "../components";
import * as RatesStore from "../store/Rates";
import { IIncident, IRate } from "../models";
import { PageLayout } from "../containers";
import { FormComponentProps } from "antd/lib/form";
import TextArea from "antd/lib/input/TextArea";
import { debug } from "console";
const { Option } = Select;

type IProps = RouteComponentProps &
    IncidentsStore.IncidentState &
    typeof IncidentsStore.actionCreators;

interface IState {
    showModal: boolean;
    agentReason: number;
    adminReason: number;
    agentComment: string;
    adminComment: string;
    rate?: IRate;
}
interface IIncidentProps extends FormComponentProps {
    incident?: IIncident;
    visible: boolean;
}
const IncidentDetail = (props: IProps) => {
    const selected = useSelector((appsState: ApplicationState) => appsState.incidents?.selected);
    const isAdmin = useSelector(
        (appState: ApplicationState) => appState.auth?.isAdmin
    );
    const [agentReason, setAgentReason] = useState(selected?.agentReason);
    const [adminReason, setAdminReason] = useState(selected?.adminReason);
    const [adminComment, setAdminComment] = useState(selected?.adminComment);
    const [agentComment, setAgentComment] = useState(selected?.agentComment);
    const isCallCenterAdmin = useSelector(
        (appState: ApplicationState) => appState.auth?.isCallCenterAdmin
    );
    const {
        requestIncident,
        edit
    } = props;
    useEffect(() => {
        const loadData = async () => {
            await requestIncident((props.match.params as any).id);
        };

        loadData();    
    }, [])
    const changeModalState = () => {

        let i = {
            id: (props.match.params as any).id,
            agentReason: agentReason ?? selected.agentReason,
            adminReason: adminReason ?? selected.adminReason,
            agentComment: agentComment ?? selected.agentComment,
            adminComment: adminComment ?? selected.adminComment,
        };
        //debugger;
        edit(i);
    };
    return (<PageLayout>
        {selected==null && <div>Cargando</div>}
        {!!selected  && <div>
            <Button
                type="primary"
                onClick={() => changeModalState()}
                style={{ marginBottom: "10px" }}
            >
                <FormattedMessage id="save" defaultMessage="Guardar" />
            </Button>
            <div className="incidentRow"><label>CallCenter</label><Input className="incidentInput" readOnly={true} defaultValue={selected?.callCenterName} /></div>
            <div className="incidentRow"><label>Agent</label><Input className="incidentInput" readOnly={true} defaultValue={selected?.agentName} /></div>
            <div className="incidentRow"><label>Cups</label><Input className="incidentInput" readOnly={true} defaultValue={selected?.cups} /></div>
            <div className="incidentRow"><label>Estado Administrador</label><Select disabled={!isAdmin} className="incidentInput" defaultValue={selected?.adminReason} onChange={(e) => setAdminReason(e)}>
                <Option key={"1"} value={1}>Rechazo CIF/NIF no coincide con el del titular</Option>
                <Option key={"2"} value={2}>Otros rechazos</Option>
                <Option key={"3"} value={3}>Rechazo Resuelto</Option>
                <Option key={"4"} value={4}>Desistimiento pendiente</Option>
                <Option key={"5"} value={5}>Desistimiento recuperado</Option>
                <Option key={"6"} value={6}>Desistimiento KO</Option>
            </Select>
            </div>
            <div className="incidentRow"><label>Estado Agente/Supervisor</label><Select disabled={isAdmin} className="incidentInput" defaultValue={selected?.agentReason} onChange={(e) => setAgentReason(e)}>
                <Option key={"1"} value={1}>Pendiente de contacto (por omisi&oacute;n)</Option>
                <Option key={"2"} value={2}>Es cambio de titular</Option>
                <Option key={"3"} value={3}>No es cambio de titular, y he verificado que el CUPS es correcto</Option>
                <Option key={"4"} value={4}>El cliente desea continuar con la contrataci&oacute;n</Option>
                <Option key={"5"} value={5}>El cliente no desea continuar con la contrataci&oacute;n</Option>
                <Option key={"6"} value={6}>Otros (Explicar)</Option>
            </Select></div>
            <div className="incidentRow"><label>Comentario Administrador</label><TextArea disabled={!isAdmin} rows={5} defaultValue={selected?.adminComment} className="incidentInput" onChange={(e) => {
                setAdminComment(e.target.value);
            }
            } /></div>
            <div className="incidentRow"><label>Comentario Agente/Supervisor</label><TextArea disabled={isAdmin} rows={5} defaultValue={selected?.agentComment} className="incidentInput" onChange={(e) => setAgentComment(e.target.value)} /></div>
        </div>
        }
        

    </PageLayout>)

}
//class IncidentDetail extends PureComponent<IProps, IState> {
//    constructor(props: IProps) {
//        super(props);

//        this.state = {
//            showModal: false,
//            agentReason:1,
//            adminReason: 1,
//            adminComment: "",
//            agentComment:""
//        };
//    }

//    public async componentDidMount() {
//        await this.props.requestIncident((this.props.match.params as any).id);
//        const { list, isLoading, selected } = this.props;

//        this.setState({ agentReason: selected?.agentReason, adminReason: selected?.adminReason, adminComment: selected?.adminComment, agentComment: selected?.agentComment });

//    }

//    changeModalState = () => {
//        let i =  {
//            id: (this.props.match.params as any).id,
//            agentReason: this.state.agentReason,
//            adminReason: this.state.adminReason,
//            agentComment: this.state.agentComment,
//            adminComment: this.state.adminComment,
//        };
//        debugger;
//        this.props.edit(i);
//    };

//    handleOk = (rate: IRate) => {
//        //if (!rate.id) this.props.addRate(rate);
//        //else this.props.editRate(rate);

//        //this.setState({
//        //  showModal: false,
//        //});
//    };

//    render() {
//        const { rate, showModal } = this.state;
//        const { list, isLoading, selected } = this.props;
//        return (
//            <PageLayout>
//                <Button
//                    type="primary"
//                    onClick={() => this.changeModalState()}
//                    style={{ marginBottom: "10px" }}
//                >
//                    <FormattedMessage id="save" defaultMessage="Guardar" />
//                </Button>
//                <div>
//                    <div className="incidentRow"><label>CallCenter</label><Input className="incidentInput" readOnly={true} defaultValue={selected?.callCenterName} /></div>
//                    <div className="incidentRow"><label>Agent</label><Input className="incidentInput" readOnly={true} defaultValue={selected?.agentName} /></div>
//                    <div className="incidentRow"><label>Cups</label><Input className="incidentInput" readOnly={true} defaultValue={selected?.cups} /></div>
//                    <div className="incidentRow"><label>Estado Administrador</label><Select className="incidentInput" defaultValue={selected?.adminReason} onChange={(e) => this.setState({ adminReason: e })}>
//                        <Option key={"1"} value={1}>Rechazo CIF/NIF no coincide con el del titular</Option>
//                        <Option key={"2"} value={2}>Otros rechazos</Option>
//                        <Option key={"3"} value={3}>Rechazo Resuelto</Option>
//                        <Option key={"4"} value={4}>Desistimiento pendiente</Option>
//                        <Option key={"5"} value={5}>Desistimiento recuperado</Option>
//                        <Option key={"6"} value={6}>Desistimiento KO</Option>
//                    </Select>
//                    </div>
//                    <div className="incidentRow"><label>Estado Agente/Supervisor</label><Select className="incidentInput" defaultValue={selected?.agentReason} onChange={(e) => this.setState({ agentReason: e })}>
//                        <Option key={"1"} value={1}>Pendiente de contacto (por omisi&oacute;n)</Option>
//                        <Option key={"2"} value={2}>Es cambio de titular</Option>
//                        <Option key={"3"} value={3}>No es cambio de titular, y he verificado que el CUPS es correcto</Option>
//                        <Option key={"4"} value={4}>El cliente desea continuar con la contrataci&oacute;n</Option>
//                        <Option key={"5"} value={5}>El cliente no desea continuar con la contrataci&oacute;n</Option>
//                        <Option key={"6"} value={6}>Otros (Explicar)</Option>
//                    </Select></div>
//                    <div className="incidentRow"><label>Comentario Administrador</label><TextArea rows={5} defaultValue={selected?.adminComment} className="incidentInput" onChange={(e) => {
//                        this.setState({ adminComment: e.target.value });
//                    }
//                    } /></div>
//                    <div className="incidentRow"><label>Comentario Agente/Supervisor</label><TextArea rows={5} defaultValue={selected?.agentComment} className="incidentInput" onChange={(e) => this.setState({ agentComment: e.target.value })}/></div>
//                </div>

//            </PageLayout>
//        );
//    }
//}

export default connect(
    (state: ApplicationState) => state.incidents,
    IncidentsStore.actionCreators
)(IncidentDetail as any);
