import React, { useState } from "react";
import { Button } from "antd";
import { FormattedMessage } from "react-intl";

import { ExcelService } from "../services";

type IProps = {
    endpoint: string;
    isGenerator: boolean;
};

const HandleDownload = async (
  endpoint: string,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  isGenerator:boolean
): Promise<void> => {
    setIsLoading(true);
    if (isGenerator) {
        var cups = endpoint.substr(endpoint.indexOf('/') + 1, endpoint.lastIndexOf('/') - (endpoint.indexOf('/') + 1));
        await ExcelService.DownloadEstimation(endpoint, cups);
    }
    else
        await ExcelService.Download(endpoint);

  setIsLoading(false);
};

const ExcelButton = (props: IProps) => {
    const [isLoading, setIsLoading] = useState(false);
    const { isGenerator } = props;

  return (
    <Button
      loading={isLoading}
          onClick={() => HandleDownload(props.endpoint, setIsLoading, isGenerator)}
      type="primary"
    >
          <FormattedMessage id="downloadExcel" defaultMessage="Descargar Excel" />
    </Button>
  );
};

export default ExcelButton;
