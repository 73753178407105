const localization: {
    [key: string]: { [key: string]: string };
} = {
    es: {
        isPromotional: "Hay Promocion?",
        promotion:"Codigo de Promocion:",
        name: "Nombre",
        callcenter: "CallCenters",
        description: "Descripcion",
        rate: "Tarifa",
        price: "Precio",
        edit: "Editar",
        delete: "Borrar",
        actions: "Opciones",
        userName: "UserName",
        "rate.add": "Añadir tarifa",
        "user.add": "Añadir Usuario",
        "call.add": "Añadir Callcenter",
        rates: "Tarifas",
        titularChange:"Cambio de Titular",
        wizard: "Asistente",
        users: "Usuarios",
        next: "Siguiente",
        done: "Finalizar",
        previous: "Anterior",
        download: "Descargar",
        downloadExcel: "Descargar Excel",
        lastName: "Apellido",
        lastName2: "Segundo Apellido",
        phone: "Telefono",
        mobilePhone: "Movil",
        city: "Ciudad",
        complement: "Complement",
        postalCode: "Codigo Postal",
        province: "Provincia",
        street: "Calle",
        number: "Numero",
        isSameBilling: "Misma direccion para facturacion?",
        contracts: "contratos",
        cups: "cups",
        first: "Primero",
        second: "Segundo",
        third: "Tercero",
        create: "Crear",
        status: "Estado",
        sendContract: "Enviar contrato",
        iban: "IBAN",
        email: "Email",
        password: "Contraseña",
        password2: "Contraseña",
        language: "Lenguaje",
        nif: "NIF",
        billingKind: "Tipo de pago",
        invoiceKind: "Tipo de factura",
        residenceKind: "Tipo de residencia",
        isPerson: "Persona o Empresa(marcado)",
        pleaseName: "Por favor introduce un nombre",
        pleaseDescription: "Por favor introduce una descripcion",
        pleasePrice: "Por favor introduce un precio",
        pleaseNumeric: "Por favor introduce un valor numerico",
        newKind: "Alta Nueva?",
        solveErrors: "Por favor resuelva los siguientes errores o campos vacios:",
        invalidIban: "Iban invalido",
        invalidNIF: "Nif Invalido",
        last: "Previsualizar",
        p1:"P1",
        p2:"P2",
        p3: "P3",
        role: "Rol",
        state: "Estado",
        disavbleuser: "Deshabilitar",
        enableUser: "Habilitar",
        disable: "Deshabilitado",
        enable: "Habilitado",
        socialReason:"Razón Social",
        representName: "Nombre Representante",
    },
    en: {

        name: "Name",
        description: "Description",
        rate: "Rate",
        price: "Price",
        actions: "Actions",
        edit: "Edit",
        delete: "Delete",
        userName: "UserName",
        "rate.add": "Add rate",
        rates: "Rates",
        wizard: "Wizard",
        users: "Users",
        next: "Next",
        done: "Done",
        previous: "Previous",
        download: "Download",
        lastName: "Last Name",
        lastName2: "Last Name2 en en",
        phone: "Phone",
        mobilePhone: "Mobile Phone",
        city: "City",
        complement: "Complement",
        postalCode: "Postal Code",
        province: "Province",
        street: "Street",
        isSameBilling: "Is same Address as billing?",
        contracts: "contracts",
        cups: "cups",
        first: "first",
        second: "second",
        third: "third",
        create: "create",
        status: "status",
        sendContract: "Send contract",
        iban: "iban",
        email: "email",
        password: "password",
        language: "language",
        nif: "nif",
        billingKind: "Billing kind",
        invoiceKind: "Invoice kind",
        residenceKind: "Residence kind",
        isPerson: "isPerson",
        pleaseName: "Please input the name!",
        pleaseDescription: "Please input the description!",
        pleasePrice: "Please input the price!",
        pleaseNumeric: "Please input a numeric value!",
        solveErrors: "Please solve the following empty inputs:",
        invalidIban: "Iban invalido"
    },
    cat: {
        callcenter: "CallCenters",
        titularChange: "Canvi de Titular",
        newKind: "Alta Nova?",

        name: "Nom",
        description: "Descripció",
        rate: "Tarifa",
        price: "Preu",
        actions: "Accions",
        edit: "Editar",
        delete: "Esborrar",
        userName: "Nom d'usuari",
        "rate.add": "Afegir tarifa",
        rates: "Tarifes",
        wizard: "Assistent",
        users: "Usuaris",
        next: "Seguent",
        done: "Fet",
        previous: "Anterior",
        download: "Descarregar",
        lastName: "Cognom",
        lastName2: "Segon Cognom",
        phone: "Telèfon",
        mobilePhone: "Telèfon mòbil",
        city: "Ciutat",
        complement: "Complement",
        postalCode: "Codi postal",
        province: "Província",
        street: "Carrer",
        isSameBilling: "És la mateixa adreça que la facturació?",
        contracts: "Contractes",
        cups: "CUPS",
        first: "Primer",
        second: "Segon",
        third: "Tercer",
        create: "Afegir",
        status: "Estat",
        sendContract: "Enviar Contracte",
        iban: "IBAN",
        email: "Email",
        password: "contrasenya",
        language: "llenguatge",
        nif: "NIF",
        billingKind: "Tipus de facturació",
        invoiceKind: "Tipus de factura",
        residenceKind: "Tipus de residencia",
        isPerson: "és personal",
        pleaseName: "Introduïu el nom",
        pleaseDescription: "Introduïu la descripció",
        pleasePrice: "Introduïu el preu",
        pleaseNumeric: "Introduïu un valor numèric",
        solveErrors: "Por favor resuelva los siguientes errores o campos vacios:",
        invalidIban: "Iban invalido"
    }
};

export default localization;
