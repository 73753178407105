import * as React from "react";
import { Route, Redirect, Switch } from "react-router";

import { IsLogged, IsAdmin } from "./containers";
import { SignIn, Wizard, Rates, Users, Contracts, Callcenters, Stats, Incidents, IncidentDetail, ContractDetail, Wizard2 } from "./pages";

import "./App.less";
import Excel from "./pages/Excel";
import Templates from "./pages/Templates";

export const routes = [
    {
        id: "contrato",
        path: "/contrato/:id?",
        component: ContractDetail,
        defaultMessage:"contrato"
    },
    {
        id: "signin",
        path: "/signin",
        component: SignIn,
        defaultMessage: "Sign in",
        icon: "Sign in"
    },
    {
        id: "contracts",
        path: "/contracts",
        component: IsLogged(Contracts),
        defaultMessage: "Contracts",
        shouldBeAdmin: true,
        icon: 'form',
        isMobile: false,
    },
    //{
    //    id: "incident",
    //    path: "/incident",
    //    component: IsAdmin(Incidents),
    //    defaultMessage: "Incidencias",
    //    shouldBeAdmin: true,
    //    icon: 'form', isMobile: false
    //},
    {
        id: "stats",
        path: "/stats",
        component: IsAdmin(Stats),
        icon: 'area-chart',
        defaultMessage: "Stats",
        shouldBeAdmin: true, isMobile: false
    },
    {
        id: "excel",
        path: "/excel",
        icon: 'file-excel',
        component: IsAdmin(Excel),
        defaultMessage: "Excel",
        shouldBeAdmin: true, isMobile: false,
    },
        {
            id: "rates",
            path: "/rates",
            icon: 'Form',
            component: IsAdmin(Rates),
            defaultMessage: "Rates",
            shouldBeAdmin: true, isMobile: false,
        },
        {
            id: "callcenter",
            path: "/callcenter",
            icon: 'user',
            component: IsAdmin(Callcenters),
            defaultMessage: "CallCenters",
            shouldBeAdmin: true,
            hide: false, isMobile: false,
        },
    {
        id: "users",
        path: "/users",
        component: IsAdmin(Users),
        icon: 'Form',
        defaultMessage: "Users",
        shouldBeAdmin: true,
        hide: true
    },
    {
        id: "templates",
        path: "/templates",
        component: IsAdmin(Templates),
        icon: 'file-add',

        defaultMessage: "Plantillas",
        shouldBeAdmin: true, isMobile: false,
    },
    {
        id: "wizard",
        path: "/wizards/:id?",
        component: IsLogged(Wizard),
        defaultMessage: "Wizard",
        shouldBeAdmin: false,
        hide: true, isMobile: false,
    }
    //,
    //{
    //    id: "incident-detail",
    //    path: "/incident-detail/:id?",
    //    component: IsLogged(IncidentDetail),
    //    defaultMessage: "IncidentDetail",
    //    shouldBeAdmin: false,
    //    hide: true, isMobile: false,
    //}
];

export default () => (
    <Switch>
        {routes.map(r => (
            <Route key={r.id} exact path={r.path} component={r.component as any} />
        ))}
        <Route path="*">
            <Redirect to="/contracts" />
        </Route>
    </Switch>
);
