import React, { useEffect, PureComponent } from "react";
import { connect } from "react-redux";
import { Table, Button } from "antd";
import { FormattedMessage } from "react-intl";
import { RouteComponentProps } from "react-router";
import { UserModel, } from "../components";

import { ApplicationState } from "../store";
import { PageLayout } from "../containers";
import * as WizardStore from "../store/Wizard";
import { IUser } from "../models";

type IProps = RouteComponentProps &
    WizardStore.WizardState &
    typeof WizardStore.actionCreators;

interface IState {
    showModal: boolean;
    user?: IUser;
}

const ContractDetail = (props: IProps) => {

    const {
        setLogaltyStatus
    } = props;
    useEffect(() => {
        const { id } = props.match.params as any;
        console.log(id);
        setLogaltyStatus(id);
        //TODO: here we should send the id to the Backend to persiste the information check as Status....
        
    }, []);


    return (
        <div>   
        <div className="contract-div">
            <span className="contract-div-header">Hola</span>
            <p className="contract-div-p">
                &iquest;C&oacute;mo est&aacute;s? Nosotros, muy contentos de que nos hayas elegido como <strong>tu compa&ntilde;&iacute;a de Luz</strong>.
                </p>
            <p className="contract-div-p">
                En <strong>Escandinava de Electricidad</strong> creemos que la energ&iacute;a debe ser <strong>transparente, sencilla, m&aacute;s limpia y accesible</strong> para todos. Y trabajamos cada d&iacute;a para conseguirlo, ofreciendo a nuestros clientes <strong>Energ&iacute;a 100% verde</strong> con las mejores tarifas y una atenci&oacute;n personalizada.
                </p>
            <p className="contract-div-p">
                Tu alta de luz con nosotros ya se est&aacute; tramitando y muy pronto ser&aacute;s nuestro cliente.
            </p>
            <p className="contract-div-p">
                Esto es lo que pasara los proximos d&iacute;as:
                <ul>
                    <li>En cuanto la distribuidora acepte la solicitud de cambio, tu alta quedar&aacute; activada. Esto ocurrir&aacute; en un plazo m&aacute;ximo de 15 d&iacute;as.</li>
                    <li>Tu compa&ntilde;ia anterior te enviar&aacute; una &uacute;ltima factura con tu consumo hasta la fecha de alta con nosotros.</li>
                    </ul>
            </p>
            <p className="contract-div-p">
                Si tienes alguna duda, ll&aacute;manos al tel&eacute;fono gratuito <strong>900 373 681</strong> o escr&iacute;benos a clientes@escandinavaelectricidad.es
                </p>
            </div>
            <div className="div-images">
                <img alt="" height="auto"
                    src="https://escandinavav2.azurewebsites.net/images/hoja.png"
                />
                <img alt="" height="auto"
                    src="https://escandinavav2.azurewebsites.net/images/gota.png"
                />
                <img alt="" height="auto"
                    src="https://escandinavav2.azurewebsites.net/images/dinero.png"
                    />
                </div>
        </div>);
};

export default connect(
    (state: ApplicationState) => state.users,
    WizardStore.actionCreators
)(ContractDetail as any);
