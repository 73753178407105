import { IContractFilter } from "../models";
import queryString from "query-string";

interface IHeaders {
  [key: string]: string;
}

export default abstract class BaseService {
  public async get<T = any>(segment: string): Promise<T> {
    return this.fetch(segment, "GET");
  }

  public async post<T = any>(segment: string, body: any): Promise<T> {
    return this.fetch(segment, "POST", body);
  }

  public async patch<T = any>(segment: string, body: any): Promise<T> {
    return this.fetch(segment, "PATCH", body);
  }

  public async delete<T = any>(segment: string): Promise<T> {
    return this.fetch(segment, "DELETE");
  }

  public async deleteWithBody<T = any>(segment: string, body: any): Promise<T> {
    return this.fetch(segment, "DELETE", body);
  }

  public async put<T = any>(segment: string, body: any): Promise<T> {
    return this.fetch(segment, "PUT", body);
  }
    //TODO: add filename
  public async download(segment: string): Promise<void> {
    const res = await fetch(segment, {
      method: "GET",
      headers: await this.headers()
    });

    if (res.ok) {
      const blob = await res.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", segment);
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(url);
      link.parentNode?.removeChild(link);
    } else if (res.status === 401) {
      localStorage.removeItem("jwt");
    }
  }

    public async downloadwithName(segment: string,name:string): Promise<void> {
        const res = await fetch(segment, {
            method: "GET",
            headers: await this.headers()
        });
        if (res.ok) {
            const blob = await res.blob();
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", name);
            document.body.appendChild(link);
            link.click();
            window.URL.revokeObjectURL(url);
            link.parentNode?.removeChild(link);
        } else if (res.status === 401) {
            localStorage.removeItem("jwt");
        } else if (res.status === 400) {
            alert('No se ha podido encontrar informaci\u00f3n del CUPS.');
        }
    }
    //TODO add parameters
    public async downloadwithParameters(segment: string, name: string, parameters: IContractFilter): Promise<void> {
        const res = await fetch(segment + "?" + queryString.stringify(parameters), {
            method: "GET",
            headers: await this.headers()
        });
        if (res.ok) {
            const blob = await res.blob();
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", name);
            document.body.appendChild(link);
            link.click();
            window.URL.revokeObjectURL(url);
            link.parentNode?.removeChild(link);
        } else if (res.status === 401) {
            localStorage.removeItem("jwt");
        } else if (res.status === 400) {
            alert('No se ha podido encontrar informaci\u00f3n del CUPS.');
        }
    }
    public async downloadwithNameWithError(segment: string, name: string): Promise<boolean> {
        const res = await fetch(segment, {
            method: "GET",
            headers: await this.headers()
        });
        if (res.ok) {
            const blob = await res.blob();
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", name);
            document.body.appendChild(link);
            link.click();
            window.URL.revokeObjectURL(url);
            link.parentNode?.removeChild(link);
            return true;
        } else if (res.status === 401) {
            localStorage.removeItem("jwt");
            return false;
        } else if (res.status === 400) {
            return false;
        }
        return true;
    }

  private async fetch<T = any>(
    segment: string,
    method: string,
    body?: any
  ): Promise<T> {
    const res = await fetch(segment, {
      method,
      headers: await this.headers(),
      body: !body ? undefined : JSON.stringify(body)
    });

    if (res.ok) {
      if (res.status === 200) return res.json();
      else return {} as T;
    } else if (res.status === 401) {
      localStorage.removeItem("jwt");
      throw new Error("401");
    } else if (res.status === 404) {
        throw new Error("404");


    }
    else if (res.status === 500) {
        alert('Ha ocurrido un error interno del servidor o de comunicaci�n con GNARUM.');
    throw new Error("500");
}else throw new Error("error");
  }

  private async headers(): Promise<Headers> {
    const headers: IHeaders = {
      Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8"
    };

    const token = localStorage.getItem("jwt");
    if (token) headers.Authorization = `Bearer ${token}`;
    return new Headers(headers);
  }
}
