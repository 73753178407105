import React, { useEffect } from "react";
import { Checkbox, Alert } from "antd";
import { connect } from "react-redux";
import { injectIntl, IntlShape, FormattedMessage } from "react-intl";
import FormItem from "antd/lib/form/FormItem";

import { ApplicationState } from "../../store";
import * as WizardStore from "../../store/Wizard";
import { ICustomer } from "../../models";
import WizardItem from "../../components/WizardItem";
import { filterObjects } from "../../helpers";

type IProps = typeof WizardStore.actionCreators & {
  customer: ICustomer;
  isSameBilling: boolean;
  intl: IntlShape;
};

const Third = (props: IProps) => {
  const {
    customer,
    isSameBilling,
    updateWizard,
    intl,
    setSameAddressBilling
  } = props;

    const { personal, billing } = customer;
    useEffect(() => {
       
        if (customer.isPerson) {
            (document.getElementById("name") as HTMLElement).classList.add("disable-element-wizard");
            (document.getElementById("lastName") as HTMLElement).classList.add("disable-element-wizard");
            (document.getElementById("lastName2") as HTMLElement).classList.add("disable-element-wizard");
            (document.getElementById("socialReason") as HTMLElement).classList.remove("disable-element-wizard");
            (document.getElementById("representName") as HTMLElement).classList.remove("disable-element-wizard");

        } else {
            (document.getElementById("name") as HTMLElement).classList.remove("disable-element-wizard");
            (document.getElementById("lastName") as HTMLElement).classList.remove("disable-element-wizard");
            (document.getElementById("lastName2") as HTMLElement).classList.remove("disable-element-wizard");
            (document.getElementById("socialReason") as HTMLElement).classList.add("disable-element-wizard");
            (document.getElementById("representName") as HTMLElement).classList.add("disable-element-wizard");

        }
    }, [customer.isPerson])
    //useEffect(() => {
    //    console.log('test');
    //    if (customer.isPromotional) {
    //        (document.getElementById("promotiona") as HTMLElement).classList.remove("disable-element-wizard");

    //    } else {
    //        (document.getElementById("promotiona") as HTMLElement).classList.add("disable-element-wizard");
    //    }
       
    //}, [ customer.isPromotional])
  //TODO: Review this
  const onBlur = (e: {
    target: { name: string; value: string | number | boolean };
  }) => {
    const { name, value } = e.target;
    updateWizard(name, value);
  };

  return (
    <>
      <div className="steps-third">
        {filterObjects(customer,true).map(([key, val]) => (
          <WizardItem
            key={key.toString()}
            label={intl.messages[key] as any}
            defaultValue={val}
            onBlur={onBlur}
                onKeyDown={e=>true}
            name={key}
          />
        ))}
        {filterObjects(personal,false).map(([key, val]) => (
          <WizardItem
            key={`personal.${key}`}
            label={intl.messages[key] as any}
            defaultValue={val}
                onKeyDown={e=>true}
                onBlur={onBlur}
            name={`personal.${key}`}
          />
        ))}
        <div style={{ marginBottom: 7 }}>
          <FormItem label={intl.messages["isSameBilling"]}>
            <Checkbox
              defaultChecked={isSameBilling}
              onChange={() => setSameAddressBilling()}
            />
          </FormItem>
        </div>
        {!isSameBilling && (
          <>
            {filterObjects(billing,true).map(([key, val]) => (
                <WizardItem
                    onKeyDown={e => true}

                key={`billing.${key}`}
                label={intl.messages[key] as any}
                defaultValue={val}
                onBlur={onBlur}
                name={`billing.${key}`}
              />
            ))}
          </>
        )}
      </div>
    </>
  );
};

export default connect(
  (state: ApplicationState) => ({
    customer: state.wizard?.customer,
    isSameBilling: state.wizard?.isSameBilling
  }),
  {
    ...WizardStore.actionCreators
  }
)(injectIntl(Third) as any);
