import React, { useEffect, PureComponent } from "react";
import { connect } from "react-redux";
import { Table, Button, Modal, Input, Divider } from "antd";
import { FormattedMessage } from "react-intl";
import { RouteComponentProps } from "react-router";

import { ApplicationState } from "../store";
import { PageLayout } from "../containers";
import * as CallCenterStore from "../store/CallCenter";
import { ICallCenter } from "../models";
import { CallCenterModal } from "../components";

type IProps = RouteComponentProps &
  CallCenterStore.CallCentersState &
  typeof CallCenterStore.actionCreators;

interface IState {
  showModal: boolean;
  callCenter?: ICallCenter;
}

class Callcenters extends PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      showModal: false,
    };
  }

  public componentDidMount() {
    this.props.requestCallCenters();
  }

  changeModalState = (callCenter?: ICallCenter) => {
    this.setState({
      showModal: !this.state.showModal,
      callCenter,
    });
  };

  handleOk = (callCenter: ICallCenter) => {
    if (!callCenter.id) this.props.addCallCenter(callCenter);
    else this.props.editCallCenter(callCenter);

    this.setState({
      showModal: false,
    });
  };

  render() {
    const { list, isLoading, deleteCallCenter, changeStateCallCenter } = this.props;
    const { callCenter, showModal } = this.state;

    return (
      <PageLayout>
        <Button
          type="primary"
          onClick={() => this.changeModalState()}
          style={{ marginBottom: "10px" }}
        >
          <FormattedMessage id="usecallr.add" defaultMessage="Crear" />
        </Button>
        <Table<ICallCenter>
          rowKey={(record: ICallCenter) => record.id}
          dataSource={list}
          loading={isLoading}
          pagination={false}
        >
          <Table.Column<ICallCenter>
            key="name"
            title={<FormattedMessage id="name" defaultMessage="name" />}
            dataIndex="name"
          />
          <Table.Column<ICallCenter>
            key="actions"
            title={<FormattedMessage id="actions" defaultMessage="Actions" />}
            render={(_, callCenterS) => (
              <span>
                <a onClick={() => this.changeModalState(callCenterS)}>
                  <FormattedMessage id="edit" defaultMessage="Edit" />
                </a>
                <Divider type="vertical" />
                <a onClick={() => changeStateCallCenter(callCenterS)}>
                        {callCenterS.active ? "Deshabilitar" : "Habilitar"}
                </a>
              
              </span>
            )}
          />
        </Table>
            <CallCenterModal
                visible={showModal}
                callcenter={callCenter}
                onOk={this.handleOk}
                onCancel={this.changeModalState}
                onRemoveUser={this.props.removeUser}
        />
      </PageLayout>
    );
  }
}

export default connect(
  (state: ApplicationState) => state.callcenters,
  CallCenterStore.actionCreators
)(Callcenters as any);
