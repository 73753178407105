import React, { PureComponent } from "react";
import { Modal, Form, Input } from "antd";
import { FormComponentProps } from "antd/lib/form";

import { IRate, ITemplate } from "../models";
import { FormattedMessage } from "react-intl";

interface ITemplateModalProps extends FormComponentProps {
    template?: ITemplate;
    visible: boolean;
    onOk: (template: ITemplate) => void;
  onCancel: () => void;
}

class TemplateModal extends PureComponent<ITemplateModalProps> {
  onModalOk = () => {
    const { form, onOk, template } = this.props;

    form.validateFields((err, values) => {
      if (err) return;

      form.resetFields();
      onOk({ ...template, ...values });
    });
  };

    render() {

    const { visible, onCancel, form, template } = this.props;
    const { getFieldDecorator } = form;
        const onChange = (a:any) => {

        }
    return (
      <Modal visible={visible} onCancel={onCancel} onOk={this.onModalOk}>
        <Form.Item
          label={<FormattedMessage id="name" defaultMessage="Name" />}
          hasFeedback
        >
          {getFieldDecorator("name", {
            initialValue: template?.name,
            rules: [
              {
                required: true,
                message: (
                  <FormattedMessage
                    id="pleaseName"
                    defaultMessage="Please input the name!"
                  />
                )
              }
            ]
          })(<Input />)}

            </Form.Item>
            <input type="file" onChange={onChange}/>
      </Modal>
    );
  }
}

export default Form.create<ITemplateModalProps>({ name: "modal" })(TemplateModal);
