import BaseService from './BaseService';
import { ICustomer } from '../models';

export class CustomersService extends BaseService {
    public async GetAll(): Promise<ICustomer[]> {
        return await this.get(`customer/all`);
    }

    public async Add(rate: ICustomer): Promise<ICustomer> {
        return await this.post(`customer`, rate);
    }

    public async Edit(rate: ICustomer): Promise<ICustomer> {
        return await this.put(`customer`, rate);
    }

    public async Delete(rate: ICustomer): Promise<boolean> {
        return await this.delete(`customer/${rate.id}`);
    }
}

export default new CustomersService();