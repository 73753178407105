import React, { PureComponent } from "react";
import { Modal, Form, Input } from "antd";
import { FormComponentProps } from "antd/lib/form";

import { IUser } from "../models";
import { FormattedMessage } from "react-intl";
import Password from "antd/lib/input/Password";

interface IUserModalProps extends FormComponentProps {
  user?: IUser;
  visible: boolean;
  onOk: (rate: IUser) => void;
  onCancel: () => void;
}

class UserModel extends PureComponent<IUserModalProps> {
  onModalOk = () => {
    const { form, onOk, user } = this.props;

    form.validateFields((err, values) => {
      if (err) return;

      form.resetFields();
      onOk({ ...user, ...values });
    });
  };
    checkpassword(pass: string) {
        return new RegExp(/^(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).*$/gm).test(pass);
    }
  render() {
    const { visible, onCancel, form, user } = this.props;
    const { getFieldDecorator } = form;
      const onChange = (e: {
          target: { name: string; value: string};
      }) => {
          const { name, value } = e.target;

          if (!this.checkpassword(value)) {
             
          }
      };
    return (
      <Modal visible={visible} onCancel={onCancel} onOk={this.onModalOk}>
        <Form.Item
          label={<FormattedMessage id="name" defaultMessage="Name" />}
          hasFeedback
        >
                {getFieldDecorator("name", {
                    initialValue: user?.userName,
            rules: [
              {
                required: true,
                message: (
                  <FormattedMessage
                    id="pleaseName"
                    defaultMessage="Please input the name!"
                  />
                )
              }
            ]
          })(<Input />)}
        </Form.Item>
            <Form.Item
                label={<FormattedMessage id="password" defaultMessage="Contrase�a" />}
                hasFeedback
            >
                {getFieldDecorator("password", {
                    initialValue: user?.password,
                    rules: [
                        {
                            required: true,
                            validator: (_, value) => this.checkpassword(value) ? Promise.resolve() : Promise.reject('La contrase�a tiene que tener al menos 8 caracteres, 1 mayuscula, 1 minuscula, 1 numero y 1 caracter especial.'),
                            message: (
                                <FormattedMessage
                                    id="pleasePass"
                                    defaultMessage="La contrase�a tiene que tener al menos 8 caracteres, 1 mayuscula, 1 minuscula, 1 numero y 1 caracter especial."
                                />
                            )
                        }
                    ]
                })(<Input type="password"  />)}
            </Form.Item>
            <Form.Item
                label={<FormattedMessage id="password2" defaultMessage="Contrase�a" />}
                hasFeedback
            >
                {getFieldDecorator("password2", {
                    initialValue: user?.password2,
                    rules: [
                        {
                            required: true,
                            validator: (_, value) => this.checkpassword(value)   ? Promise.resolve() : Promise.reject('La contrase�a tiene que tener al menos 8 caracteres, 1 mayuscula, 1 minuscula, 1 numero y 1 caracter especial.'),
                            message: (
                                <FormattedMessage
                                    id="pleasePass2"
                                    defaultMessage="La contrase�a tiene que tener al menos 8 caracteres, 1 mayuscula, 1 minuscula, 1 numero y 1 caracter especial."
                                />
                            )
                        }
                    ]
                })(<Input type="password" />)}
            </Form.Item>
            
      </Modal>
    );
  }
}

export default Form.create<IUserModalProps>({ name: "modal" })(UserModel);
