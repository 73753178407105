import BaseService from "./BaseService";
import { IContract, ICommonInterface, IGroupedContract, IResult } from "../models";
import { debug } from "console";

export class WizardService extends BaseService {
  public async Start(): Promise<IContract> {
    return await this.get(`wizard/create`);
  }

    public async GetHome(): Promise<ICommonInterface> {
    return await this.get(`wizard/common`);
  }
    public async Gets(): Promise<IContract[]> {
        return await this.get(`wizard`);
    }
    public async GetGrouped(start:string,end:string): Promise<IGroupedContract[]> {
        return await this.get(`wizard/grouped?start=${start}&end=${end}`);
    }
  public async Get(id: number): Promise<IContract> {
    return await this.get(`wizard/${id}`);
    }
    public async GetStatus(id: number): Promise<IResult> {
        return await this.get(`wizard/${id}/status`);//TODO
    }
    public async Clean(id: number): Promise<IResult> {
        return await this.get(`wizard/${id}/clean`);//TODO
    }
    public async Confirm(id: string): Promise<IContract> {
        return await this.get(`wizard/${id}/confirm`);//TODO
    }
    public async EditField(id: number, prop: string, value: string | number | boolean) {
        return await this.put(`wizard/${id}/field`, {propName:prop,val:value});

	}
    public async Edit(data: IContract): Promise<IContract> {
        
    return await this.put(`wizard/${data.id}`, data);
  }

  public async Delete(data: any): Promise<IContract> {
    return await this.delete(`wizard/${data}`);
  }
    public async Complete(data: IContract): Promise<IContract> {
        return await this.post(`wizard/submitContract/${data.id}`, data);
  }

  public async GetHtml(id: number): Promise<string> {
    return await this.get(`html/${id}`);
    }
    //TODO: Change 01 with CallCenterIdentifier
    public async GetPdf(id: number, contractIdentifier: string, callCenter: any): Promise<void> {
    return await this.downloadwithName(
      `html/${id}/pdf`,
        `${callCenter}.pdf`
    );
  }
  public pad(num: any, size: Number): string {
    var s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
  }
}

export default new WizardService();
