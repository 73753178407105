import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import thunk from "redux-thunk";
import { connectRouter, routerMiddleware } from "connected-react-router";
import { History } from "history";
import { intlReducer } from "react-intl-redux";

import { ApplicationState, reducers } from "./";
import localization from "../localization";

const defaultLocale = localStorage.getItem("locale") || "es";

export default function configureStore(
  history: History,
  initialState?: ApplicationState
) {
  const middleware = [thunk, routerMiddleware(history)];

  const rootReducer = combineReducers({
    ...reducers,
    intl: intlReducer,
    router: connectRouter(history)
  });

  const enhancers = [];
  const windowIfDefined =
    typeof window === "undefined" ? null : (window as any);
  if (windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__) {
    enhancers.push(windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__());
  }

  return createStore(
    rootReducer,
    {
      ...initialState,
      intl: {
        locale: defaultLocale,
        messages: localization[defaultLocale]
      }
    },
    compose(applyMiddleware(...middleware), ...enhancers)
  );
}
