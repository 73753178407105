interface IBase {
    id: string;
}

export interface IEstimation {
    rateInfo: IRate;
    rate: string;
    p1Kwh: number;
    average1: number;
    p2Kwh: number;
    average2: number;
    p3Kwh: number;
    average3: number;
    cost: number;
    totalLastYear: number;
    "Potencia P1": number;
    "Potencia P2_": number;
    "Potencia P2": number;
    "Dias Totales": number;
    "Energia Facturada": number;
    monthly: number;
    id: number;
    existsError: boolean;
}

export interface IRate extends IBase {
    name: string;
    description: string;
    price: number;
    p1: number;
    p2: number;
    p3: number;
    managementCost: number;
    powerCost: number;
    vas: number;
}
export interface IPromotion extends IBase {
    name: string;
}
export interface ITemplate extends IBase {
    name: string;
    path: string;
    file: any;
}
export interface IAddress extends IBase {
    postalCode: string;
    province: string;
    city: string;
    street: string;
    complement: string;
    number: string;
}

export enum language {
    Castellano,
    Catalan
}
export enum residenceKind {
    Primera,
    Segunda
}
export enum newKind {
    No,
    Si
}
export enum billingKind {
    Domiciliacion,
    Ventanilla
}
export enum titularChange {
    No,
    Si
}
export enum invoiceKind {
    Email,
    Papel
}

export interface ICustomer extends IBase {
    socialReason: string;
    representName: string;
    name: string;
    lastName: string;
    lastName2: string;
    phone: string;
    language: language;
    invoiceKind: invoiceKind;
    billingKind: billingKind;
    titularChange: titularChange;
    mobilePhone: string;
    personalId?: string;
    personal: IAddress;
    billingId?: string;
    billing: IAddress;
    loyaltyId: number;
    iban: string;
    email: string;
    nif: string;
    residenceKind: residenceKind;
    newKind: newKind;
    isPerson: number;
    isPromotional: boolean;
    promotiona: string;
}
export interface ICallCenter extends IBase {
    name: string;
    identifier: number;
    active: boolean;
    comparionEnabled: boolean;
    users: ICallCenterUser[];
    usersRates: ICallCenterUserRate[];
}
export interface ICallCenterUser extends IBase {
    callCenterId: string;
    userId: string;
    name: string;
    password: string;
    isAdmin: boolean;
    disabled: boolean;
    idRow: number;
}
export interface ICallCenterUserRate extends IBase {
    userId: string;
    rateId: string;
    assigned: string;
}
export interface IUser extends IBase {
    userName: string;
    name: string;
    password: string;
    password2: string;
    role: string;
    disabled: boolean;
    isAdmin: boolean;
}
export interface ICommonInterface {
    centers: ICallCenter[];
    users: ICallCenterUser[];

}
export interface IContractFilter {
    cups: string;
    startDate: string;
    endDate: string;
    callcenter: string;
    agent: string;
    name: string;
    address: string;
    nif: string;
    status: string;
}
export interface IGroupedContract {
    user: string;
    callCenter: string;
    year: Number;
    month: Number;
    day: Number;
    status: Number;
    counter: Number;
    date: string;
}
export interface IIncident {
    agentComment: string;
    adminComment: string;
    id: string;
    cups: string;
    callCenterIdentifier: Number;
    callCenterName: string;
    agentName: string;
    agentReason: number;
    adminReason: number;

}
export interface IResult {
    finished: boolean;
    status: string;
    reason: string;
    result: number;
    cancelCode: string;

}
export interface IContract {
    id: number;
    cups: string;
    callCenterIdentifier: Number;
    incidentId: string;
    potencia1: number;
    potencia2: number;
    callCenterName: string;
    agentName: string;
    sendCertified: boolean;

    showComparison: boolean;
    contractIdentifier: string;
    stepId: number;
    rateId: string;
    rate?: IRate;
    customerId: string;
    date: string;
    customer: ICustomer;
    language: 1 | 2 | 3;
    status: number;
    excelGenerated: boolean;
    //estimation: IEstimation;
    estimationId: number;
    selected: boolean;
    logaltyId: string;
}

export interface IToken {
    "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name": string;
    "http://schemas.microsoft.com/ws/2008/06/identity/claims/role": string;
    sub: string;
    exp: number;
}
