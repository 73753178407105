import React from "react";
import { connect } from "react-redux";

import { ApplicationState } from "../store";
import * as AuthStore from "../store/Auth";
import localization from "../localization";

type IProps = { locale: string } & typeof AuthStore.actionCreators;

const LenguageSelector = (props: IProps) => {
  const keys = Object.keys(localization);
  return (
    <div className="lenguageSelector">
      {keys.map((l, i) => (
        <div className="lenguageSelector-element" key={l}>
          <a onClick={() => props.changeLenguage(l)}>
            {l.toUpperCase()}
          </a>
          {i < keys.length - 1 && " |"}
        </div>
      ))}
    </div>
  );
};

export default connect(
  (state: ApplicationState) => ({ locale: state.intl?.locale }),
  AuthStore.actionCreators
)(LenguageSelector as any);
